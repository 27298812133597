import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PublicDashboard from 'components/DataLibrary/PublicDashboard';
import PageNotFound from 'components/ErrorPages/PageNotFound';
import NavigationTracker from 'components/Navigation/NavigationTracker';
import usePermissions from 'hooks/usePermissions';
import { ROLE_ADMINISTRATOR, ROLE_PROJECT_MANAGER, ROLE_BRAND_MANAGER } from 'hooks/usePermissions/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { selectUserPermissions } from 'reducers/UserReducers/helpers';
import InfluencerSignUpContainer from 'views/influencer/NewSignUp';

import AdminRoutes from './AdminRoutes';
import DataLibraryRoutes from './DataLibraryRoutes';
import InfluencerRoutes from './InfluencerRoutes';
import InviteRoutes from './InviteRoutes';
import LoginRoutes from './LoginRoutes';
import NoUserPermissionsRoutes from './NoUserPermissionsRoutes';
import RedirectRoute from './RedirectRoute';
import UserRoutes from './UserRoutes';
import { FallbackRoute } from './layout-routes/FallbackRoute';

/**
 * Router list
 * Keeps all routes in the app
 * @returns { Array<RouteObject>}
 */
const RouteList = () => {
	const { isGranted } = usePermissions();
	const permissions = useAppSelector(selectUserPermissions);

	const routes: Array<RouteObject> = [
		{ path: '/', element: <Navigate to='/login' replace /> },
		// Public routes
		{ path: '/login/*', children: LoginRoutes },
		{ path: '/invites/*', children: InviteRoutes },
		{ path: '/not-connected', children: NoUserPermissionsRoutes },
		{ path: '/signup/:inviteToken?', element: <InfluencerSignUpContainer /> },
		{ path: '/signup/agents', element: <InfluencerSignUpContainer /> },
		{ path: '/data-library/dashboard/:dashboardId/invite/:token', element: <PublicDashboard /> },
		{ path: '/not-found', element: <PageNotFound /> },
	];

	const isAdmin = isGranted(ROLE_ADMINISTRATOR) || permissions.isSuperAdmin;

	if (isAdmin) {
		routes.push({ path: '/admin/*', children: AdminRoutes });
	}

	if (isAdmin || isGranted(ROLE_PROJECT_MANAGER) || isGranted(ROLE_BRAND_MANAGER)) {
		routes.push({ path: '', children: UserRoutes });
	}

	if (isAdmin || isGranted(ROLE_PROJECT_MANAGER)) {
		routes.push({ path: '/data-library', children: DataLibraryRoutes });
	}

	if (permissions.isInfluencer) {
		routes.push({ path: '/dashboard', element: <RedirectRoute to='/influencer/dashboard' /> });
		routes.push({ path: '/influencer/*', children: InfluencerRoutes });
	}

	routes.push({ path: '*', element: <FallbackRoute /> });

	return [{ path: '/', element: <NavigationTracker />, children: routes }];
};

export default RouteList;
