import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-bottom: 1rem;
	padding-right: 1rem;
	position: absolute;
	right: 0;
	top: 1rem;
`;

const Styled = {
	Wrapper,
};

export default Styled;
