import { AxiosError } from 'axios';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CreateDashboardQuery } from 'api-queries';
import Styled from 'components/DataLibrary/ContentHeader/ContentHeader.style';
import convertDashboardModelToDashboardType from 'components/DataLibrary/ConvertDashboardModelToDashboardType';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import toast from 'services/Toast';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setDashboards } from 'views/DataLibrary/reducers/DashboardsSlice';
import { setModalIsOpen } from 'views/DataLibrary/reducers/ModalSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const CreateDashboard = (props: { copyDashboard?: boolean }): JSX.Element => {
	const { copyDashboard } = props;

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [newDashboardName, setNewDashboardName] = useState<string>('');
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	// This modal state can be triggered from search dashboards component.
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboards: DashboardType[] = useAppSelector((state) => state.dashboards);
	const modal = useAppSelector((state) => state.modal);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
		!copyDashboard && dispatch(setModalIsOpen(!isModalOpen));
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewDashboardName(e.target.value);
	};

	const createDashboardRequest = (data: {
		name: string;
		metaData: Record<string, never>;
		items: {
			id?: string;
			kind?: string;
			size: string;
			metaData: Record<string, never>;
		}[];
	}) => {
		const qb = RequestQueryBuilder.create<CreateDashboardQuery>(['createPaidMediaPresignUrl', 'createDashboardUser', 'createPaidMedia']).withInclude('users');
		manager
			.create(data, qb)
			.then((result) => {
				const { dashboard } = convertDashboardModelToDashboardType(manager.getRepository(), result);
				dispatch(setDashboard(dashboard));
				const updateDashboards = [...dashboards, dashboard];
				dispatch(setDashboards(updateDashboards));
				navigate(`/data-library/dashboard/${result.id}`);
			})
			.catch((err: AxiosError) => {
				toast.error(getErrorMessageOnPost('creating the dashboard'));
				console.error(err.message);
			})
			.finally(() => {
				setIsFetching(false);
				setIsModalOpen(false);
				dispatch(setModalIsOpen(false));
				setNewDashboardName('');
				if (copyDashboard) {
					toast.success(`A new copy of the dashboard created`);
				} else {
					toast.success(`A new dashboard created`);
				}
			});
	};

	const createDashboardHandler = () => {
		setIsFetching(true);
		let dashboardName = `New Dashboard ${moment().format('YYYY-MM-DD')}`;
		if (newDashboardName) {
			dashboardName = newDashboardName;
		}
		if (copyDashboard) {
			createDashboardRequest({
				name: dashboardName,
				items: dashboard.items,
				metaData: dashboard.metaData,
			});
		} else {
			createDashboardRequest({
				name: dashboardName,
				items: [],
				metaData: {},
			});
		}
	};

	useEffect(() => {
		return () => {
			setIsModalOpen(false);
			setNewDashboardName('');
		};
	}, []);

	useEffect(() => {
		if (modal.isOpen && !copyDashboard) {
			setIsModalOpen(true);
		}
	}, [modal]);

	return (
		<>
			<Styled.CustomDropdownItem onClick={() => toggleModal()} data-testid='create-dashboard'>
				<Icon name={copyDashboard ? 'copy' : 'expand'} size={'20'} />
				{copyDashboard ? 'Create from copy' : 'Create new dashboard'}
			</Styled.CustomDropdownItem>
			<ConfirmModal
				title={copyDashboard ? 'Create dashboard from copy' : 'Create new dashboard'}
				isModalOpen={isModalOpen}
				toggleModal={toggleModal}
				isFetching={isFetching}
				action={createDashboardHandler}
				disabled={newDashboardName.length === 0}
				buttonText={copyDashboard ? 'Copy' : 'Create'}
			>
				<Field label='Name your dashboard'>
					<Input name='dasboard-name' type='text' value={newDashboardName} onChange={(e) => handleChange(e)} />
				</Field>
			</ConfirmModal>
		</>
	);
};
export default CreateDashboard;
