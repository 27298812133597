import classNames from 'classnames';
import { Model } from 'json-api-models';

import Assignment from 'components/ContentManagement/Components/Views/Assignment';
import EmptyState from 'components/ContentManagement/Components/Views/EmptyState';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { getLatestReview } from 'shared/utils/getLatestReview';

import Styled from './ContentSection.style';
import { ContentSectionProps } from './types';

/**
 * ContentSection
 * @param {ContentReviewProps} props
 * @returns {JSX.Element}
 */
const ContentSection = ({
	CIOAssignment,
	selectedCampaign,
	selectedCIO,
	CIOAS,
	CIOArefresh,
	refresh,
	brandManagerUsers,
	repository,
}: ContentSectionProps): JSX.Element => {
	const contentManagement = useAppSelector((state) => state.contentManagement);
	const reviews = CIOAssignment && CIOAssignment.reviews;
	const latestReview = reviews ? getLatestReview(reviews) : null;

	return (
		<Styled.Wrapper className={classNames({ 'is-uploading': contentManagement.isProgressVisible })} data-testid='content-section'>
			{selectedCampaign && CIOAssignment ? (
				<Assignment
					id={CIOAssignment.id}
					type={CIOAssignment.assignment.kind}
					heading={CIOAssignment.assignment.name}
					campaignCode={CIOAssignment.assignment.campaignCode}
					selectedCampaign={selectedCampaign}
					selectedCIO={selectedCIO}
					CIOAssignment={CIOAssignment}
					CIOAS={CIOAS}
					review={latestReview as Model}
					CIOArefresh={CIOArefresh}
					refresh={refresh}
					brandManagerUsers={brandManagerUsers}
					repository={repository}
				/>
			) : (
				<EmptyState campaignSelected={selectedCampaign} />
			)}
		</Styled.Wrapper>
	);
};
export default ContentSection;
