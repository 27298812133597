import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton, SecondaryButton } from 'components/Button';
import { HoverButton } from 'components/Button/Button';
import Icon from 'components/Icon';
import { useAppSelector, useAppDispatch } from 'views/DataLibrary/hooks';
import { setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setModalIsOpen } from 'views/DataLibrary/reducers/ModalSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import SearchDashboard from './SearchDashboard';
import Styled from './SearchDashboard.style';

const SearchContainer = () => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const navigate = useNavigate();

	const [displaySearch, setDisplaySearch] = useState<boolean>(false);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboards: DashboardType[] = useAppSelector((state) => state.dashboards);

	const dispatch = useAppDispatch();

	const selectDashboard = (dashboard: DashboardType) => {
		setDisplaySearch(false);
		dispatch(setDashboard(dashboard));
		navigate(`/data-library/${dashboard.id}`);
	};

	const handleClickOutside = (e: MouseEvent): void => {
		if (inputRef && inputRef.current && !inputRef.current.contains(e.target as Node)) {
			setDisplaySearch(false);
		}
	};
	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (inputRef && inputRef.current && e.key === 'Escape') {
			setDisplaySearch(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keyup', handleEscapeKey, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			document.removeEventListener('keyup', handleEscapeKey, true);
			setDisplaySearch(false);
		};
	}, []);

	return (
		<Styled.NameContainer ref={inputRef}>
			<Styled.TitleWrapper>
				{dashboard && dashboard.id ? (
					<HoverButton
						icon='arrow-down'
						iconRight
						data-testid='dl-dashboard-title'
						onClick={() => setDisplaySearch(!displaySearch)}
						className={classNames({ displaySearch: displaySearch })}
					>
						<span>{dashboard.id ? dashboard.name : 'Dashboards'}</span>
						{dashboard.dashboardUsers && dashboard.dashboardUsers?.length > 0 && <Icon name='shared' size='24' />}
					</HoverButton>
				) : dashboards?.length > 0 ? (
					<SecondaryButton onMouseEnter={() => setDisplaySearch(true)}>Select dashboard</SecondaryButton>
				) : (
					<PrimaryButton onClick={() => dispatch(setModalIsOpen(true))}>Create dashboard</PrimaryButton>
				)}
			</Styled.TitleWrapper>
			{displaySearch && <SearchDashboard arrayToFilter={dashboards} onSelect={selectDashboard} />}
		</Styled.NameContainer>
	);
};

export default SearchContainer;
