import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import InputText from 'components/Form/FormikElements/Text';
import { createClient } from 'shared/ApiClient/ApiClient';
import errorHandler from 'utils/formik_error_handler';
import Styled from 'views/login/components/Form.style';

const VALIDATION_SCHEMA = Yup.object({
	email: Yup.string().email().required(),
});

/**
 * ForgotPasswordForm
 * @todo get rid of graphQL
 * @returns {JSX.Element}
 */
const ForgotPasswordForm = (): JSX.Element => {
	const emailRef = useRef<HTMLInputElement>(null);
	const [emailSent, setEmailSent] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const ForgotForm = () => (
		<Formik
			initialValues={{ email: '' }}
			validateOnBlur={false}
			onSubmit={async (values, { setErrors }) => {
				try {
					await createClient().post('/public/reset-password-tokens', values);
					setEmailSent(true);
				} catch (e) {
					setErrorMessage('Something went wrong, please try again later.');
					errorHandler(e, setErrors);
				}
			}}
			validationSchema={VALIDATION_SCHEMA}
		>
			{({ isSubmitting, isValid, errors, touched }) => (
				<Form id='password-form'>
					<InputText
						label='Your email'
						className={errors.email && touched.email ? 'error' : ''}
						innerRef={emailRef}
						id='email'
						name='email'
						type='email'
						placeholder='Enter email'
						required
					/>
					<Styled.SubmitButtonWrapper>
						<PrimaryButton type='submit' disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
							Email me a recovery link
						</PrimaryButton>
						{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
					</Styled.SubmitButtonWrapper>
				</Form>
			)}
		</Formik>
	);

	const EmailSent = () => (
		<div>
			Did you not receive the email? Check your spam filter or <br />
			<Styled.ButtonLink onClick={() => setEmailSent(false)}>try another address</Styled.ButtonLink>.
		</div>
	);

	useEffect(() => {
		emailRef && emailRef.current && emailRef.current.focus();
	}, [emailRef]);

	return (
		<Styled.FormCard>
			<Styled.FormWrapper>
				<div>
					<Styled.Heading>Recover password</Styled.Heading>
					{!emailSent ? (
						<p>No worries, it happens to the best of us.</p>
					) : (
						<p>If account exists, an email will be sent to the address with further instructions.</p>
					)}
				</div>
				{!emailSent ? <ForgotForm /> : <EmailSent />}
			</Styled.FormWrapper>
			<Styled.BottomLinks className='text-center mb-0'>
				<Link to={'/login'}>Take me back to login</Link>
			</Styled.BottomLinks>
		</Styled.FormCard>
	);
};

export default ForgotPasswordForm;
