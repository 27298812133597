import InfluencerDashboardContainer from 'components/InfluencerDashboard';
import InfluencerDashboardHeader from 'components/InfluencerDashboard/Components/InfluencerDashboardHeader';

import Styled from './Dashboard.style';

const InfluencerDashboard = () => {
	return (
		<Styled.Wrapper>
			<InfluencerDashboardHeader />
			<InfluencerDashboardContainer />
		</Styled.Wrapper>
	);
};
export default InfluencerDashboard;
