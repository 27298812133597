import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import { RichText } from 'styles/utils/RichText';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';
import { PALETTE } from 'styles/variables/original-colors';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	margin: ${spacing[4].px} 0;
	background-color: ${colors.mainWhite};
	scroll-margin: 320px;
`;

const HeaderSubText = styled.div`
	margin-bottom: ${guttersWithRem.m};
`;

const HeaderText = styled.h4`
	font-family: ${typography.BaseFontFamiliy};
	font-weight: 700;
	font-size: 2rem;
	margin-bottom: ${spacing[3].px};
`;

const ContentWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
`;

const Summary = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${guttersWithRem.xl};

	& > span {
		font-weight: 600;
	}
`;

const AssignmentCards = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.xs};
`;

const AssignmentCard = styled.div`
	width: 100%;
	background-color: ${PALETTE.aquaHaze};
	padding: ${guttersWithRem.xl};
	text-align: left;
	border-radius: ${borderRadius.m};
	margin-bottom: ${spacing[4].px};
	& > div {
		&.header {
			display: flex;
			font-size: 17px;
			font-weight: 600;
			justify-content: space-between;
			margin-bottom: ${spacing[3].px};

			& > span {
				display: flex;
				align-items: center;
				column-gap: ${guttersWithRem.xs};
				.icon {
					margin-top: 5px;
				}
			}
			div {
				&.assignment-type {
					margin-top: ${spacing[0].px};
				}
			}
		}

		&.title {
			font-size: 1.5rem;
			font-weight: 500;
			margin-bottom: ${guttersWithRem.s};
		}

		&.description {
			padding-bottom: ${guttersWithRem.s};
			border-bottom: 1px solid ${colors.gray2};

			& > p {
				overflow-wrap: break-word;
			}
		}

		&.details {
			padding: 0.625rem 0 0;
		}
	}
`;

const Dates = styled.div`
	padding: 0.25rem ${guttersWithRem.m};
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};
	background-color: ${colors.briefPage.campaignAssignments.dateInfo};
	border-radius: ${borderRadius.s};
`;

const CustomIcon = styled(Icon)`
	& i {
		line-height: unset;
	}
`;

const CustomAccordion = styled(Accordion)`
	.accordion__body {
		margin-top: 16px;
		max-height: unset;
	}
	.accordion__title {
		background-color: ${colors.influencerBlue};
		color: ${colors.mainWhite};
		padding: ${spacing[2].rem};
		border-radius: ${borderRadius.s};
		font-family: ${typography.BaseFontFamiliy};
		font-size: 0.938rem;
		width: fit-content;
	}
`;

const AccordionContent = styled.div`
	& > div {
		&.detail {
			margin-bottom: 40px;

			& > div {
				margin-bottom: ${guttersWithRem.xs};
				&.title {
					font-size: 1rem;
					font-weight: 700;
				}

				&.content {
					font-size: 1rem;
					ul {
						padding-left: 1rem;
					}
					div {
						&.copy {
							display: flex;
							align-items: center;
						}
						button {
							margin-left: ${spacing[1].px};
						}
					}
				}
			}
		}

		&.deadline {
			display: flex;
			column-gap: ${guttersWithRem.xs};
			row-gap: ${guttersWithRem.xs};
			flex-wrap: wrap;
		}
	}
`;

const ProductInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${spacing[3].px};
	@media screen and (${devices.md}) {
		flex-direction: row;
	}
`;

const Image = styled.img`
	width: auto;
	height: 210px;
	border-radius: ${borderRadius.m};
	margin-right: ${spacing[2].px};
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: 20px;
	bottom: 20px;
	mix-blend-mode: multiply;
`;

const StyledLink = styled(Link)`
	width: fit-content;
	&:hover {
		color: ${colors.influencerBlue} !important;
		border-bottom: 1px solid ${colors.influencerBlue} !important;
		.icon path {
			fill: ${colors.influencerBlue};
		}
		.icon {
			margin-left: 5px;
		}
	}
`;

const Value = styled.div`
	font-size: 1rem;
	font-weight: 500;
	@media screen and (${devices.md}) {
		font-size: 1.2rem;
	}
`;

const Description = styled.div`
	${RichText};
	margin-bottom: ${spacing[4].px};
`;

const Title = styled.h4`
	display: flex;
	justify-content: space-between;
	div &.name {
		font-size: 1.2rem;
	}
	.icon {
		margin-right: ${spacing[1].px};
	}
`;

const ProductLink = styled.div<{ placedOnImage?: boolean }>`
	position: relative;
	backdrop-filter: blur(10px);
	width: auto;
	height: 250px;
	min-width: 250px;
	border-radius: ${borderRadius.m};
	background-color: ${(props) => (props.placedOnImage ? colors.mainWhite : colors.gray7)};
	display: flex;
	flex-direction: column;
	padding: ${spacing[3].rem};
	text-align: center;
	margin-bottom: 10px;
	@media screen and (${devices.md}) {
		margin-right: 10px;
		width: 250px;
	}
	p {
		color: ${colors.mainWhite};
	}
	.icon {
		margin-top: ${spacing[2].px};
	}
	.icon path {
		fill: ${colors.mainWhite};
	}
	button {
		margin-top: 10px;
		font-size: 1.2rem;
		color: ${colors.mainWhite};
		border: 2px solid ${colors.mainWhite}!important;
	}
`;

const IconContainer = styled.div`
	display: flex;
	flex-direction: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	cursor: pointer;
	&:hover {
		color: ${colors.influencerBlue};
		.icon path {
			fill: ${colors.influencerBlue};
		}
	}
`;

const Icons = styled.div`
	display: flex;
	align-items: baseline;
	gap: 4px;
`;

const EmptyStateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${colors.buttonGray};
	border-radius: ${borderRadius.m};
	padding: ${spacing[3].rem};
`;

const EmptyTitle = styled.h3`
	font-weight: 500;
	font-size: 1.2rem;
`;

const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	color: ${colors.gray7};
	background-color: ${colors.gray9};
	padding: ${spacing[2].rem};
	border: 2px dotted ${colors.gray7};
	border-radius: ${borderRadius.s};
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
`;

const Styled = {
	EmptyStateWrapper,
	EmptyTitle,
	Icons,
	Title,
	ProductLink,
	StyledLink,
	Wrapper,
	HeaderSubText,
	HeaderText,
	ContentWrapper,
	Summary,
	AssignmentCard,
	AssignmentCards: AssignmentCards,
	CustomIcon,
	Dates,
	CustomAccordion,
	AccordionContent,
	ProductInfo,
	Image,
	Value,
	Description,
	IconContainer,
	Button,
};

export default Styled;
