import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { TertiaryButton } from 'components/Button';
import Pill from 'components/Pill';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setShowFilter } from 'views/DataLibrary/reducers/FilterSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './PageHeaderButtons.style';

/**
 * @returns {JSX.Element}
 */
const PageHeaderButtons = (): JSX.Element => {
	const filter = useAppSelector((state) => state.filter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);

	const dispatch = useAppDispatch();
	const [numberOfSelectedFilters, setNumberOfSelectedFilters] = useState<number>(1);

	const updateTotal = () => {
		let total = 1; // For the date that always is pre selected

		const FilterCategories = ['assignments', 'assignmentTypes', 'brands', 'clients', 'campaigns', 'countries', 'influencers'];
		for (const key in dashboardFilter) {
			// @ts-ignore
			if (FilterCategories.includes(key) && dashboardFilter[key]?.length > 0) {
				total++;
			}
		}
		return setNumberOfSelectedFilters(total);
	};

	useEffect(() => {
		updateTotal();
	}, [dashboardFilter]);

	return (
		<Styled.Wrapper>
			{dashboard.id && (
				<TertiaryButton
					icon='filters'
					onClick={() => {
						dispatch(setShowFilter(!filter.isOpen));
					}}
					className={classNames({ showFilter: filter.isOpen })}
				>
					Filter <Pill className='dl-filter' title={`${numberOfSelectedFilters}`} />
				</TertiaryButton>
			)}
		</Styled.Wrapper>
	);
};
export default PageHeaderButtons;
