import { ErrorMessage, Form, Formik } from 'formik';
import { JsonApiDocument, Model, Store } from 'json-api-models';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import InputText from 'components/Form/FormikElements/Text';
import LoadingSpinner from 'components/LoadingSpinner';
import useInjection from 'hooks/useInjection';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import { ICollabsResponse } from 'services/Response.types';
import ToolsService from 'services/Tools';
import { createClient } from 'shared/ApiClient/ApiClient';
import { silentRefreshUser } from 'shared/User/User.helpers';
import errorHandler from 'utils/formik_error_handler';
import Styled from 'views/sign-up/publisher-with-token/InfluencerSignUpContainer.style';

type Props = {
	email?: string;
	inviteToken: string | undefined;
	onCreate: (userId: number | string) => void;
	onCheckToken?: (isNewOrganization: boolean) => void;
};

/**
 */
const AccountCreation = ({ inviteToken, email, onCreate, onCheckToken }: Props) => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const [isAlreadyUsedVisible, setIsAlreadyUsedVisible] = useState<boolean>(false);
	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
	const [isInvalid, setIsInvalid] = useState<boolean>(false);
	const [isFromVisible, setIsFromVisible] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [user, setUser] = useState<Model>();
	const [invite, setInvite] = useState<Model[]>([]);
	const { pathname } = useLocation();

	const checkToken = () => {
		const baseUrl = pathname.includes('influencer') ? '/public/campaigns/' : '/public/invites/';

		setIsLoading(true);
		createClient()
			.get(baseUrl + inviteToken)
			.then((response: ICollabsResponse) => {
				const model = new Store();
				model.sync(response.data);

				const invite = model.findAll('invite');

				setIsAlreadyUsedVisible(false);
				setIsFromVisible(true);
				setInvite(invite);
				onCheckToken && onCheckToken(invite.length > 0 && invite[0].attributes.isNewOrganization);
			})
			.finally(() => setIsLoading(false))
			.catch((e) => {
				try {
					const statusCode = e.response.status;
					setIsAlreadyUsedVisible(statusCode === 410);
					setIsInvalid(statusCode === 500 || statusCode === 404);
					setIsFromVisible(false);
				} catch (error) {
					setIsInvalid(true);
					setIsFromVisible(false);

					ToolsService.log('Account Creation error', {
						error: `${error}`,
					});
				}
			});
	};

	useEffect(() => {
		if (user && authService.getCollabsToken()) {
			silentRefreshUser();
			onCreate(user.id);
		}
	}, [user]);

	useEffect(() => {
		inviteToken && checkToken();
	}, []);

	return (
		<>
			{!isLoading && (
				<>
					{isAlreadyUsedVisible && (
						<Styled.InvalidLinkMessageContainer>
							<p>
								The invitation link has already been used. <br />
								You need to ask for a new one if you think this is wrong.
							</p>
							<p>
								Already created an account? <Link to='/login'>Login</Link> instead.
							</p>
						</Styled.InvalidLinkMessageContainer>
					)}

					{isInvalid && (
						<Styled.InvalidLinkMessageContainer>
							<p>It seems there is something wrong with the link. Please make sure you wrote it correctly, or ask for a new one.</p>
						</Styled.InvalidLinkMessageContainer>
					)}
					{isFromVisible && (
						<>
							<Formik
								onSubmit={async (values, { setErrors }) => {
									try {
										const { data } = await createClient().post<JsonApiDocument>('/public/users', values);
										const user = new Store().sync(data) as Model;
										authService.setCollabsToken(user.userToken.token);

										setUser(user);
									} catch (e) {
										errorHandler(e, setErrors);
									}
								}}
								initialValues={{ email: email || '', password: '', inviteToken }}
								validationSchema={Yup.object({
									email: Yup.string().email('Please enter a valid email address').required('Email is required'),
									password: Yup.string().required('Password is required'),
								})}
								validateOnBlur={validateAfterSubmit}
								validateOnChange={validateAfterSubmit}
							>
								{({ isSubmitting, isValid }) => (
									<Form>
										<p className='muted'>Step 1 of {invite && invite.length > 0 && invite[0].attributes.isNewOrganization ? '3' : '2'} - account creation</p>
										<InputText label='Email' name='email' type='email' id='user-email' required />
										<ErrorMessage name='campaignInviteToken' className='validation-error' component='p' />
										<ErrorMessage name='inviteToken' className='validation-error' component='p' />
										<InputText label='Password' name='password' type='password' id='user-pwd' required />
										<PrimaryButton
											className='m-32-32'
											type='submit'
											disabled={isSubmitting || !isValid}
											onClick={() => setValidateAfterSubmit(!validateAfterSubmit)}
										>
											{isSubmitting ? <LoadingSpinner size='sm' position='center' /> : 'Next step'}
										</PrimaryButton>
									</Form>
								)}
							</Formik>
							<p className='text-center my-l mb-0'>
								Have an account? <Link to='/login'>Log in</Link>
							</p>
						</>
					)}
				</>
			)}

			{isLoading && <LoadingSpinner position='center' size='lg' />}
		</>
	);
};

export default AccountCreation;
