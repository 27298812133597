import styled from 'styled-components';

import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import fontSize from 'styles/variables/font-size';
import { guttersWithRem } from 'styles/variables/gutter';
import { PALETTE } from 'styles/variables/original-colors';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	scroll-margin: 200px;
`;

const HeaderSubText = styled.div`
	margin-bottom: ${guttersWithRem.m};
`;

const HeaderText = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-weight: 700;
	font-size: 2rem;
	margin-bottom: ${spacing[3].px};
`;

const ContentWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
`;

const Summary = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${guttersWithRem.xl};

	& > span {
		font-weight: 600;
	}
`;

const CommissionCards = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.xs};
	margin-bottom: ${guttersWithRem.m};
	border-radius: ${borderRadius.m};
	background-color: ${PALETTE.concret};
	padding-top: 1rem;
`;

const CommissionCard = styled.div`
	padding: ${spacing[3].rem} ${spacing[3].rem} 0 ${spacing[3].rem};
	text-align: left;
	hr {
		border-top: 1px solid ${colors.gray11};
		margin: ${spacing[3].px} 0;
	}
	& > div {
		&.label {
			width: fit-content;
			display: flex;
			column-gap: ${guttersWithRem.xs};

			padding: ${guttersWithRem.xs};
			background-color: ${colors.briefPage.campaignCommissions.label};

			margin-bottom: ${guttersWithRem.m};
		}

		&.value {
			margin-bottom: ${guttersWithRem.m};
			font-weight: 600;
			font-size: 19px;
			line-height: 1.3;
			& > div {
				&.tax-info {
					font-weight: 400;
					font-size: 16px;
				}
			}
			h5 {
				font-weight: 500;
				font-size: 1rem;
				margin-bottom: ${spacing[1].px};
			}
		}

		&.invoice-info {
			& > div {
				&.extra-info {
					border-radius: ${borderRadius.s};
					padding: ${spacing[2].rem};
					font-size: 14px;
					background-color: ${colors.briefPage.campaignCommissions.info};
				}
			}

			& > ul {
				padding-left: ${guttersWithRem.m};
				font-size: 0.8rem;
			}
		}

		&.product-image {
			margin: 0 auto;
			margin-bottom: ${guttersWithRem.l};
			img {
				max-width: 10rem;
				max-height: 10rem;
			}
		}

		&.product-info {
			line-height: 1.5;
			& > div {
				&.name {
					font-weight: 600;
					margin-bottom: ${guttersWithRem.l};
				}
				&.description {
					margin-bottom: ${guttersWithRem.l};
				}
				&.detail {
				}
			}
		}
	}
`;

const ProductCards = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: ${guttersWithRem.xs};
`;

const ProductCard = styled.div`
	padding: ${guttersWithRem.xl};
	flex: 1;

	& > div {
		&.label {
			margin: 0 auto;
			width: fit-content;
			display: flex;
			column-gap: ${guttersWithRem.xs};
			border-radius: 4px;

			padding: ${guttersWithRem.xs};
			background-color: ${colors.briefPage.campaignCommissions.label};

			margin-bottom: ${guttersWithRem.l};
		}
	}
`;

const CustomAccordion = styled(Accordion)``;

const CustomIcon = styled(Icon)`
	line-height: 0;
`;

const AffiliateCommissionMessage = styled.div`
	margin-top: ${guttersWithRem.m};
	margin-bottom: ${guttersWithRem.xs};
	font-size: ${fontSize.l};
	font-weight: 600;
`;

const AssignmentsWrapper = styled.div`
	padding: ${spacing[3].rem};
	padding-top: 0;
	display: flex;
	flex-direction: column;
	gap: 4px;
	h5 {
		font-weight: 500;
		font-size: 1rem;
		margin-bottom: ${spacing[1].px};
	}
`;

const Assignment = styled.div`
	background-color: ${colors.mainWhite};
	padding: ${spacing[1].rem} ${spacing[2].rem};
	border-radius: ${borderRadius.s};
	border: 1px solid ${colors.gray11};
	cursor: pointer;
	transition: background-color 200ms ease-in-out;
	&:hover {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
	div {
		&.header {
			margin-top: 5px;
			display: flex;
			align-items: center;
			.icon {
				margin-right: 8px;
			}
		}
	}
`;

const Hr = styled.hr`
	border-top: 1px solid ${colors.gray11};
	margin: 0;
	margin-bottom: ${spacing[3].px};
`;

const Styled = {
	Hr,
	Wrapper,
	HeaderSubText,
	HeaderText,
	ContentWrapper,
	Summary,
	CommissionCards,
	CommissionCard,
	CustomIcon,
	ProductCards,
	ProductCard,
	CustomAccordion,
	AffiliateCommissionMessage,
	Assignment,
	AssignmentsWrapper,
};

export default Styled;
