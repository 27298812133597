import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import InputText from 'components/Form/FormikElements/Text';
import { createClient } from 'shared/ApiClient/ApiClient';
import errorHandler from 'utils/formik_error_handler';
import Styled from 'views/login/components/Form.style';

export interface IResetPasswordForm {
	token: string;
}

const VALIDATION_SCHEMA = Yup.object({
	password: Yup.string().required('Password is required'),
	confirmPassword: Yup.string()
		.required('Confirm password is required')
		.oneOf([Yup.ref('password'), ''], 'The passwords don’t match. Please try again.'),
});

/**
 * ResetPasswordForm
 * @returns {JSX.Element}
 */
const ResetPasswordForm = ({ token }: IResetPasswordForm): JSX.Element => {
	const passwordRef = useRef<HTMLInputElement>(null);
	const [passwordIsReset, setPasswordIsReset] = useState(false);
	const navigate = useNavigate();

	const ResetForm = () => (
		<Formik
			initialValues={{ password: '', confirmPassword: '', token }}
			onSubmit={async ({ password, token }, { setErrors }) => {
				try {
					await createClient().post('/public/passwords', { password, token });
					setPasswordIsReset(true);
				} catch (e) {
					if (errorHandler(e, setErrors).token) {
						navigate('/login/reset/expired');
					}
				}
			}}
			validationSchema={VALIDATION_SCHEMA}
			validateOnBlur={false}
			validateOnChange={false}
		>
			{({ isSubmitting, isValid, errors, touched, setErrors }) => (
				<Form>
					<InputText
						label={'Password'}
						className={errors.password && touched.password ? 'error' : ''}
						innerRef={passwordRef}
						id='password'
						name='password'
						type='password'
						placeholder='New password'
						required
						onInput={() => setErrors({})}
					/>
					<InputText
						label={'Confirm password'}
						className={errors.confirmPassword && touched.confirmPassword ? 'error' : ''}
						id='confirmPassword'
						name='confirmPassword'
						type='password'
						placeholder='Repeat password'
						required
						onInput={() => setErrors({})}
					/>
					<PrimaryButton isLoading={isSubmitting} type='submit' disabled={isSubmitting || !isValid} data-testid='submit-btn'>
						Reset password
					</PrimaryButton>
				</Form>
			)}
		</Formik>
	);

	useEffect(() => {
		passwordRef && passwordRef.current && passwordRef.current.focus();
	}, [passwordRef]);

	return (
		<Styled.FormCard>
			<Styled.FormWrapper>
				<div>
					<Styled.Heading>Reset password</Styled.Heading>
					{!passwordIsReset ? <p>Please type a new password.</p> : <p>Your password has been updated.</p>}
				</div>
				{!passwordIsReset && (
					<Styled.FormWrapper>
						<ResetForm />
					</Styled.FormWrapper>
				)}
			</Styled.FormWrapper>
			<Styled.BottomLinks className='text-center mb-0'>
				<Link to='/login'>Take me back to login</Link>
			</Styled.BottomLinks>
		</Styled.FormCard>
	);
};

export default ResetPasswordForm;
