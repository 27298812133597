import { Model } from 'json-api-models';
import { uniq, isNil, isEmpty } from 'lodash';

import { LinkButton } from 'components/Button';
import CampaignInfoBlock from 'components/IntegratedInbox/Components/CampaignInfoBlock';
import { CampaignInfluencerDetail } from 'components/IntegratedInbox/Components/InfluencerDetailSection/CampaignInfluencerDetail';
import { CampaignDetail } from 'components/IntegratedInbox/influencer/CampaignDetailSection/types';
import LoadingSpinner from 'components/LoadingSpinner';

import Styled from './InfluencerCampaignInfo.style';

/**
 * @todo test
 */
const InfluencerCampaignInfo = (props: {
	isAffiliate: boolean;
	campaignDetail: CampaignInfluencerDetail | CampaignDetail | null;
	isLoading: boolean;
	campaignBriefUrl: string | null;
	CIO?: Model;
}) => {
	const commission = props.campaignDetail?.commission;
	const campaignCodes = props.campaignDetail?.assignments?.map((assignment: Model) => {
		return assignment.campaignCode;
	});
	const campaignCodesWithoutNull = campaignCodes && campaignCodes?.filter((code: string) => code?.length > 0);
	const uniqCodes = uniq(campaignCodesWithoutNull);

	const hasCommission = () => {
		return !isNil(commission) && (parseInt(commission) > 0 || props.isAffiliate);
	};

	const hasAssignments = () => {
		return !isNil(props.campaignDetail?.assignments) && !isEmpty(props.campaignDetail?.assignments);
	};

	const hasProducts = () => {
		return !isNil(props.campaignDetail?.products) && !isEmpty(props.campaignDetail?.products);
	};

	const hasCampaignCode = () => {
		return !isNil(uniqCodes) && !isEmpty(uniqCodes);
	};

	return (
		<Styled.Wrapper>
			{props.isLoading ? (
				<Styled.SpinnerWrapper>
					<LoadingSpinner />
				</Styled.SpinnerWrapper>
			) : (
				!isNil(props.campaignDetail) && (
					<>
						{props.campaignBriefUrl && (
							<LinkButton
								className='mt-24 mb-24'
								icon='circle-arrow-up'
								iconRight
								onClick={() => {
									window.open(props.campaignBriefUrl as string, '_blank');
								}}
							>
								View brief
							</LinkButton>
						)}
						{hasCommission() && <CampaignInfoBlock label='commission' commission={commission as string} isAffiliate={props.isAffiliate} />}
						{hasAssignments() && <CampaignInfoBlock CIO={props.CIO} label='Manage content' assignments={props.campaignDetail.assignments} />}
						{hasProducts() && <CampaignInfoBlock label='products' products={props.campaignDetail.products} />}
						{hasCampaignCode() && <CampaignInfoBlock label='Campaign codes' promoCodes={uniqCodes} />}
					</>
				)
			)}
		</Styled.Wrapper>
	);
};

export default InfluencerCampaignInfo;
