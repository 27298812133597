import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { UserModel } from 'api-models';
import useContentManagementData from 'components/ContentManagement/hooks';
import IconButton from 'components/Discovery/Components/IconButton';
import ConfirmModal from 'components/Modals/ConfirmModal';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import Tooltip from 'components/Tooltip';
import { ENABLE_FOR_CLIENT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { AssignmentReviewType } from 'shared/Types/Assignment';
import colors from 'styles/theme/colors';

import Styled from './ShareContent.style';

type ShareContentProps = {
	review?: AssignmentReviewType;
	refresh: () => void;
	CIOArefresh: () => Promise<void>;
	brandManagerUsers?: Array<UserModel>;
};

const ShareContent = ({ review, refresh, CIOArefresh, brandManagerUsers }: ShareContentProps) => {
	const { userCan } = useFeaturePermissions(review?.links || {});
	const isBrandManager = usePermissions().isBrandManager();

	const CAN_ENABLE_FOR_CLIENT = userCan(ENABLE_FOR_CLIENT);
	const user = useAppSelector((state) => state.user);
	const { campaignId } = useParams();

	useEffect(() => {
		if (!review) return;
		setShareDisabled(Boolean(review?.enabledForClientAt) || Boolean(review?.approvedByClientAt));
	}, [review]);

	const { postEnableToClient } = useContentManagementData();

	const [shareConfirm, setShareConfirm] = useState<boolean>(false);
	const [shareDisabled, setShareDisabled] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleClose = () => setShareConfirm(!shareConfirm);
	const onClickShare = () => {
		if (!review?.links[ENABLE_FOR_CLIENT]) {
			return;
		}

		setIsLoading(true);
		postEnableToClient(review?.links[ENABLE_FOR_CLIENT])
			.then(() => {
				return Promise.all([refresh(), CIOArefresh()]);
			})
			.then(() => {
				toast.success('Content is shared');
			})
			.catch((e) => {
				toast.error(getSomethingWentWrongMessage());
				console.error(e);
				setShareDisabled(true);
			})
			.finally(() => {
				setShareConfirm(!shareConfirm);
				setIsLoading(false);
			});
	};

	return (
		<>
			<Styled.ShareAssignment>
				<Styled.ShareInner>
					{CAN_ENABLE_FOR_CLIENT && !shareDisabled ? <IconButton helpText='Share content' onClick={handleClose} testId='share' iconName='share' /> : null}
				</Styled.ShareInner>
			</Styled.ShareAssignment>
			{!user.permissions.isInfluencer && (
				<Styled.ShareTimestamps data-testid='cm-share-timestamp'>
					{review?.enabledForClientAt && review?.approvedByClientAt === null && (
						<Tooltip content={`Content is shared with: ${brandManagerUsers?.map((user) => user.attributes.name).join(', ')}`}>
							<Styled.ShareTime>
								Shared: <span className='timestamp'>{moment(review.enabledForClientAt).format('D MMM')}</span>
							</Styled.ShareTime>
						</Tooltip>
					)}
					{review?.approvedByClientAt && (
						<Styled.ShareTime>
							{isBrandManager ? 'Content approved' : 'Client approved'} <span className='timestamp'>{moment(review?.approvedByClientAt).format('D MMM')}</span>
						</Styled.ShareTime>
					)}
				</Styled.ShareTimestamps>
			)}
			<ConfirmModal
				icon='share'
				IconBackgroundColor={colors.iceBlue}
				isModalOpen={shareConfirm}
				toggleModal={() => handleClose()}
				action={() => onClickShare()}
				title='Share content'
				isFetching={isLoading}
				buttonText='Share'
			>
				<>
					{brandManagerUsers && brandManagerUsers?.length > 0 ? (
						<>
							<p>Share content with</p>
							<Styled.BrandManagerList>
								{brandManagerUsers.map((user) => {
									return (
										<li key={user.id}>
											<UserAvatar small fullName={user.attributes.name} />
										</li>
									);
								})}
							</Styled.BrandManagerList>

							<hr />
							<p>
								Content will be shared with the above recipients. Manage collaborators <Link to={`/campaigns/${campaignId}/edit`}>here</Link>.
							</p>
						</>
					) : (
						<>
							<hr />
							<p>
								Manage collaborators <Link to={`/campaigns/${campaignId}/edit`}>here</Link>
							</p>
						</>
					)}
				</>
			</ConfirmModal>
		</>
	);
};

export default ShareContent;
