import Skeleton from 'react-loading-skeleton';

import Styled from 'views/Campaign/Dashboard/ContentManagement/ContentManagementSidebar.style';

const SkeletonLoader = ({ variant }: { variant: 'content' | 'influencer' | 'manager' }) => (
	<Styled.SkeletonLoaderList>
		{variant === 'content' && (
			<>
				<Skeleton width='100%' height='60px' />
				<Skeleton width='100%' height='300px' borderRadius='10px' />
			</>
		)}
		{variant === 'influencer' && (
			<>
				<Skeleton width='100%' height='60px' />
				<Skeleton width='50%' height='40px' />
				<Skeleton width='60%' height='20px' />
				<Skeleton width='100%' height='50px' />
				<Skeleton width='100%' height='50px' />
			</>
		)}
		{variant === 'manager' && (
			<>
				<Skeleton width='100%' height='60px' />
				<Skeleton width='60%' height='20px' />
				{Array.from({ length: 5 }).map((_, index) => (
					<Skeleton key={index} width='100%' height='50px' />
				))}
			</>
		)}
	</Styled.SkeletonLoaderList>
);

export default SkeletonLoader;
