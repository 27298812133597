import { Form, Formik } from 'formik';
import { JsonApiDocument, Model, Store } from 'json-api-models';
import { first, isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BlueButton } from 'components/Button/Button';
import Checkbox from 'components/Checkbox';
import InputText from 'components/Form/FormikElements/Text';
import Icon from 'components/Icon';
import SocialProfileConnector from 'components/SocialProfileConnector';
import { FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setUser } from 'reducers/UserReducers/UserSlice';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import ReferralUrlService from 'services/ReferralUrlService';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import errorHandler from 'utils/formik_error_handler';
import { gtagEvent } from 'utils/ga';
import Styled from 'views/influencer/NewSignUp/InfluencerSignUpContainer.style';

import { getReferralUrlByName } from './ReferralMapping';

type AccountCreationProps = {
	steps: number;
	setSteps: (step: number) => void;
	getAcceptedTerms: (termsAccepted: boolean) => void;
	email?: string;
	inviteToken?: string;
	onCheckToken?: (isNewOrganization: boolean) => void;
};

/**
 */
const AccountCreation = ({ steps, setSteps, getAcceptedTerms, inviteToken, email }: AccountCreationProps) => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [displayPassword, setDisplayPassword] = useState(false);

	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);
	const [isEnabled] = useFeatureToggle();
	const [shouldValidate, setShouldValidate] = useState(false);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const INITIAL_VALUES = {
		firstName: '',
		lastName: '',
		email: email || '',
		password: '',
		inviteToken: inviteToken === 'agent' ? undefined : inviteToken,
		reCaptchaToken: undefined,
	};

	useEffect(() => {
		getAcceptedTerms(termsAccepted);
	}, [termsAccepted]);

	const firstNameRef = useRef<HTMLInputElement>(null);
	const lastnameRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const phoneNumberRef = useRef<HTMLInputElement>(null);
	const addressRef = useRef<HTMLInputElement>(null);
	const postalCodeRef = useRef<HTMLInputElement>(null);
	const cityRef = useRef<HTMLInputElement>(null);
	const countryCodeRef = useRef<HTMLSelectElement>(null);
	const reCaptchaRef = useRef<ReCAPTCHA>(null);

	const Steps = [
		{
			name: 'firstName',
			nextField: lastnameRef,
		},
		{
			name: 'lastName',
			nextField: emailRef,
		},
		{
			name: 'email',
			nextField: passwordRef,
		},
		{
			name: 'password',
			nextField: phoneNumberRef,
		},
		{
			name: 'mobilePhoneNumber',
			nextField: addressRef,
		},
		{
			name: 'address',
			nextField: postalCodeRef,
		},
		{
			name: 'postalCode',
			nextField: cityRef,
		},
		{
			name: 'city',
			nextField: countryCodeRef,
		},
	];

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const keyDownHandler = (event: any) => {
		if (event.key === 'Enter') {
			Steps.map(({ name, nextField }) => {
				if (name === event?.target?.name) {
					nextField?.current?.focus();
				}
			});
		}
	};

	useEffect(() => {
		const referralParam = searchParams.get('referral');
		firstNameRef?.current?.focus();
		document.addEventListener('keydown', keyDownHandler);

		if (referralParam) {
			ReferralUrlService.set(getReferralUrlByName(referralParam)!);
		}

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, []);

	return (
		<Formik
			onSubmit={async ({ ...values }, { setErrors }) => {
				const formData = { influencerTermsOfServiceAccepted: termsAccepted, ...values };
				try {
					const models = new Store();
					const { data } = await createClient().post<JsonApiDocument>('/public/users', formData);
					models.sync(data) as Model;
					const userToken = first(models.findAll('userToken'))!;
					authService.setCollabsToken(userToken.attributes.token);
					const token = authService.getCollabsToken();
					if (token) {
						try {
							const res = await authService.me(authService.getGlobalUserIncludes());
							const user = models.sync(res) as Model;
							dispatch(setUser(user));
							setSteps(2);
							gtagEvent('sign_up_step_completed', { step: 'account_creation' });
						} catch (e) {
							toast.error(getSomethingWentWrongMessage());
						}
					}
				} catch (e) {
					reCaptchaRef.current?.reset();
					errorHandler(e, setErrors);
				}
			}}
			validateOnBlur={shouldValidate}
			validateOnChange={shouldValidate}
			initialValues={INITIAL_VALUES}
		>
			{({ isSubmitting, isValid, setFieldValue, values }) => {
				return (
					<>
						{steps === 1 && (
							<Form>
								<InputText
									label='First name'
									name='firstName'
									placeholder='Add your first name'
									required
									forwardRef={firstNameRef}
									onInput={(e: React.FormEvent<HTMLInputElement>) => {
										keyDownHandler(e);
									}}
								/>
								<InputText
									label='Last name'
									name='lastName'
									placeholder='Add your last name'
									required
									forwardRef={lastnameRef}
									onInput={(e: React.FormEvent<HTMLInputElement>) => {
										keyDownHandler(e);
									}}
								/>
								<InputText
									label='Email'
									name='email'
									type='email'
									placeholder='example@mail.com'
									forwardRef={emailRef}
									required
									onInput={(e: React.FormEvent<HTMLInputElement>) => {
										keyDownHandler(e);
									}}
								/>
								{values.email.length > 0 && (
									<Styled.Info>
										<Icon name='info-circle' size='16' />
										<p>
											Invites and campaign updates will be sent to <strong>{values.email}</strong> so make sure its correct.
										</p>
									</Styled.Info>
								)}
								<InputText
									action={
										<Styled.IconWrapper onClick={() => setDisplayPassword(!displayPassword)}>
											<Icon name={!displayPassword ? 'hide' : 'unhide'} />
										</Styled.IconWrapper>
									}
									size='lg'
									label='Password'
									placeholder='Set a strong password'
									name='password'
									type={displayPassword ? 'text' : 'password'}
									id='user-pwd'
									required
									forwardRef={passwordRef}
									onInput={(e: React.FormEvent<HTMLInputElement>) => {
										keyDownHandler(e);
									}}
								/>
								<hr />
								{(isNil(inviteToken) || inviteToken === 'agent') && !isNil(process.env.VITE_APP_RECAPTCHA_SITE_ID) && (
									<ReCAPTCHA
										ref={reCaptchaRef}
										sitekey={process.env.VITE_APP_RECAPTCHA_SITE_ID}
										onChange={(token) => {
											setFieldValue('reCaptchaToken', token);
										}}
										onExpired={() => {
											setFieldValue('reCaptchaToken', undefined);
										}}
									/>
								)}
								<Styled.CheckboxContainer>
									<Checkbox
										name='influencerTermsOfServiceAccepted'
										checked={termsAccepted}
										onChange={() => setTermsAccepted(!termsAccepted)}
										data-testid='accept-tac'
									/>
									<span>
										I agree to the&nbsp;
										<a href='https://www.collabs.se/terms-of-service' target='_blank' rel='noreferrer'>
											Collabs Terms
										</a>
									</span>
								</Styled.CheckboxContainer>
								<BlueButton
									isLoading={isSubmitting}
									type='submit'
									disabled={isSubmitting || !isValid || !termsAccepted}
									onClick={() => setShouldValidate(true)}
								>
									{isNil(inviteToken) ? 'Continue' : 'Create account'}
								</BlueButton>
							</Form>
						)}
						{(steps === 2 || steps === 3) && (
							<SocialProfileConnector
								setSteps={setSteps}
								hasInviteToken={!isNil(inviteToken)}
								disableTikTokConnector={!isNil(inviteToken) && !isEnabled(FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS)}
								isSignUp={true}
								onClickCreateAccount={() => {
									gtagEvent('sign_up_step_completed', { step: 'connect_social' });
									ReferralUrlService.redirect(navigate, user.permissions?.isInfluencer ? '/influencer/dashboard' : '/dashboard');
								}}
							/>
						)}
					</>
				);
			}}
		</Formik>
	);
};

export default AccountCreation;
