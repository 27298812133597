import classNames from 'classnames';
import { memo } from 'react';

import Icon from 'components/Icon';

import Styled from './Tabs.style';
import { TabItem, TabsProps } from './types';

const Tabs = memo(({ tabs, selectedTab, setSelectedTab, id, className }: TabsProps) => {
	return (
		<Styled.TabContainer id={id} className={className}>
			{tabs.map((tab: TabItem) => {
				if (tab.omit) return null;

				const isSelected = selectedTab === tab.title;
				const isDisabled = tab.disabled;

				const handleClick = () => {
					if (!isDisabled) {
						setSelectedTab(tab.title);
					}
				};

				return (
					<Styled.Tab
						data-testid={tab.testId}
						key={tab.title || tab.key}
						className={classNames({ selected: isSelected, disabled: isDisabled })}
						onClick={handleClick}
					>
						{tab.icon && <Icon name={tab.icon} size='18' />} {tab.title} {tab.amount !== undefined && <span>({tab.amount})</span>}
					</Styled.Tab>
				);
			})}
		</Styled.TabContainer>
	);
});

export default Tabs;
