import { Model } from 'json-api-models';
import moment from 'moment';

import { useAppSelector } from 'hooks/useUserAppSelector';
import { UserType } from 'reducers/UserReducers/types';

export const formatDateToDDMMM = (enteredDate: string) => {
	if (enteredDate) {
		return moment(enteredDate).format('DD MMM');
	} else {
		return '-';
	}
};

export const getCampaignInvitesUri = () => {
	const user = useAppSelector((state) => state.user);
	const includes = ['commission'];

	const query = new URLSearchParams({
		includes: includes.join(','),
		used: 'false',
		influencer: user.influencer.id,
	});

	return `/campaign-invites?${query}`;
};

export const getSelectedProfile = (campaign: Model, user?: UserType) => {
	return campaign.campaignInstagramOwners.find((CIO: Model) => CIO.influencer?.id === user?.influencer?.id);
};

export class CampaignCompensation {
	commissions: Model[];
	products: Model[];
	totalCommission: number;
	currency: string;

	constructor(campaignInstagramOwner: Model) {
		if (campaignInstagramOwner && campaignInstagramOwner.campaignInstagramOwnerCommissions.length > 0) {
			this.commissions = campaignInstagramOwner.campaignInstagramOwnerCommissions;
			this.totalCommission = campaignInstagramOwner.campaignInstagramOwnerCommissions?.reduce(
				(prev: number, current: Model) => prev + current.commission.fixedAmount,
				0,
			);
			this.currency = campaignInstagramOwner.campaignInstagramOwnerCommissions[0]?.commission?.fixedAmountCurrency;
			this.products = campaignInstagramOwner.campaignInstagramOwnerProducts ? campaignInstagramOwner.campaignInstagramOwnerProducts : [];
		} else {
			this.commissions = [];
			this.totalCommission = 0;
			this.currency = '';
			this.products = [];
		}
	}
}
