import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { CampaignModel } from 'api-models';
import CMStartImage from 'assets/img/app/CM-start.svg';
import { CampaignItemType } from 'components/ContentManagement/types';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './EmptyState.style';

type EmptyStateItem = {
	headline: JSX.Element;
	steps?: { headline: string; text: string }[];
	text?: JSX.Element;
	image: string;
};

/**
 * EmptyState
 * @param {EmptyStateProps} props
 * @returns {JSX.Element}
 */
const EmptyState = (props: { campaignSelected?: CampaignItemType | CampaignModel }): JSX.Element => {
	const [displaySkeletonLoader, setDisplaySkeletonLoader] = useState<boolean>(false);
	const user = useAppSelector((state) => state.user);

	const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>(user.influencer.id);

	useEffect(() => {
		if (user.influencer.id !== selectedInfluencerId && user.permissions.isInfluencer) {
			setDisplaySkeletonLoader(true);
			setTimeout(() => {
				setDisplaySkeletonLoader(false);
			}, 1000);
			setSelectedInfluencerId(user.influencer.id);
		}
	}, [user.influencer.id]);

	const content: EmptyStateItem = !user.permissions.isInfluencer
		? {
				headline: <h2>All your influencers content in one place</h2>,
				text: <p className='text-center'>{props.campaignSelected ? 'Select assignment to review' : 'Select campaign'}</p>,
				image: CMStartImage,
			}
		: {
				headline: <h2>All your content in one place</h2>,
				steps: [{ headline: 'Select your assignment', text: '' }],
				image: CMStartImage,
			};

	return (
		<Styled.Wrapper>
			{displaySkeletonLoader ? (
				<>
					<Styled.SkeletonWrapper>
						<Skeleton width='200px' height='100px' />
					</Styled.SkeletonWrapper>
					<Styled.SkeletonWrapper>
						<Skeleton width='300px' />
					</Styled.SkeletonWrapper>
				</>
			) : (
				<>
					<img src={CMStartImage} alt='Add your content in one place' />
					<Styled.Headline>{content.headline}</Styled.Headline>
				</>
			)}
			{content.steps && (
				<Styled.Steps>
					{content.steps.map((step, index) => (
						<Styled.Step key={index}>
							<Styled.Number>
								<span>{(index + 1).toString().padStart(2, '0')}</span>
							</Styled.Number>
							<Styled.StepBox>
								<Styled.StepHeadline>
									<span>{displaySkeletonLoader ? <Skeleton width='100px' /> : step.headline}</span>
								</Styled.StepHeadline>
								<Styled.StepText>
									<p>{displaySkeletonLoader ? <Skeleton width='300px' height='10px' /> : step.text}</p>
								</Styled.StepText>
							</Styled.StepBox>
						</Styled.Step>
					))}
				</Styled.Steps>
			)}
			{content.text !== undefined && content.text}
		</Styled.Wrapper>
	);
};

export default EmptyState;
