import { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import CampaignService from 'services/Statistics/Campaign';
import BriefPage from 'views/BriefPage';

import { getProperDataFromResponse } from './helper';

/**
 * @returns {JSX.Element}
 */
const InvitationBriefPage = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState(true);
	const [campaign, setCampaign] = useState<Campaign | undefined>(undefined);
	const [campaignInstagramOwner, setCampaignInstagramOwner] = useState<CampaignInstagramOwner | undefined>(undefined);
	const user = useAppSelector((state) => state.user);

	const { campaignId } = useParams();
	const influencerId = user.influencer?.id;

	useEffect(() => {
		if (influencerId && campaignId) {
			try {
				setIsLoading(true);
				CampaignService.fetchBriefData(campaignId, influencerId).then((res) => {
					const data = getProperDataFromResponse(res);
					setCampaign(data.campaign);
					setCampaignInstagramOwner(data.campaignInstagramOwner);
					setIsLoading(false);
				});
			} catch (error) {
				console.error(error);
				setIsLoading(false);
			}
		}

		return () => {
			setCampaign(undefined);
			setCampaignInstagramOwner(undefined);
			setIsLoading(false);
		};
	}, [campaignId]);
	return (
		<div>
			{isLoading ? (
				<div className='loading-center'>
					<LoadingSpinner position='center' />
				</div>
			) : !campaign ? (
				<Navigate to='/not-found' />
			) : (
				campaignInstagramOwner && <BriefPage campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} />
			)}
		</div>
	);
};

export default InvitationBriefPage;
