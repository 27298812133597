import Styled from './LinkAsGhostButton.style';

/**
 * Link that looks like ghost button
 * @returns {JSX.Element}
 */
const LinkAsSecondaryButton = (props: { to: string; children: string | JSX.Element; onClick?: () => void; state?: object }): JSX.Element => {
	return (
		<Styled.StyledLink role='button' state={props.state} to={props.to} onClick={props.onClick && props.onClick}>
			<span>{props.children}</span>
		</Styled.StyledLink>
	);
};

export default LinkAsSecondaryButton;
