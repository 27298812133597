import { AxiosResponse } from 'axios';
import { JsonApiDocument } from 'json-api-models';
import styled from 'styled-components';

import Comments from 'components/ContentManagement/Components/Comments';
import { selectedTabType } from 'components/ContentManagement/Components/Comments/types';
import { AssignmentReviewCommentType, AssignmentReviewInternalCommentType } from 'components/ContentManagement/types';
import { Heading } from 'components/Heading';
import { UserType } from 'reducers/UserReducers/types';
import colors from 'styles/theme/colors';

type CommentsSectionProps = {
	user: UserType;
	canUseTabs: boolean;
	comments: (AssignmentReviewCommentType | AssignmentReviewInternalCommentType)[];
	internalComments: (AssignmentReviewCommentType | AssignmentReviewInternalCommentType)[];
	activeCommentTab: selectedTabType;
	onClickTab: (selectedTab: selectedTabType) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSend: (text: string, activeTab: selectedTabType) => Promise<void | AxiosResponse<JsonApiDocument<string>, any>>;
	reviewStatus: string;
	isReviewActive: boolean;
	isNotSharedWithClient: boolean;
};

const Container = styled.div`
	padding-bottom: 4rem;
	h5 {
		margin-bottom: 8px;
	}
	p {
		font-size: 0.875rem;
		color: ${colors.slate};
	}
`;

const CommentsSection = ({
	user,
	canUseTabs,
	comments,
	internalComments,
	activeCommentTab,
	onClickTab,
	onSend,
	reviewStatus,
	isReviewActive,
	// isNotSharedWithClient Use this info
}: CommentsSectionProps) => {
	return (
		<Container>
			<div className='comments-title-wrapper'>
				<Heading as='h5'>{user?.permissions?.isInfluencer ? 'Comments' : 'Add a comment'}</Heading>
				<p>{user?.permissions?.isInfluencer ? 'Requested changes or updates for this assignment' : 'Request changes or updates for this assignment.'}</p>
			</div>
			<Comments
				user={user}
				canUseTabs={canUseTabs}
				comments={activeCommentTab === selectedTabType.Influencer ? comments : internalComments}
				disabled={!isReviewActive || reviewStatus === 'disapproved'}
				activeTab={activeCommentTab}
				onClickTab={onClickTab}
				onSend={(text) => onSend(text, activeCommentTab)}
			/>
		</Container>
	);
};

export default CommentsSection;
