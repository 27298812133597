import { useRef } from 'react';

import Styled from 'components/ContentManagement/Components/Views/Post/Post.style';
import Icon from 'components/Icon';

const AddItem = (props: { onChangeFiles: (e: React.FormEvent<HTMLInputElement>) => void }) => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	return (
		<Styled.AddMediaItemWrapper>
			<Styled.AddMediaItem
				aria-label='Add new media'
				data-testid='add-item'
				onClick={() => {
					if (inputRef.current) {
						inputRef.current.click();
					}
				}}
			>
				<Styled.AddMediaItemText>
					<Icon name='add-circle' size='18' /> Add item
				</Styled.AddMediaItemText>
				<Styled.InvisibleInput ref={inputRef} type='file' accept='image/*, video/*' multiple onChange={props.onChangeFiles} />
			</Styled.AddMediaItem>
		</Styled.AddMediaItemWrapper>
	);
};

export default AddItem;
