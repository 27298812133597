import ConfirmModal from 'components/Modals/ConfirmModal';
import colors from 'styles/variables/colors';

type SaveStatisticsProps = {
	isManagedByApi?: boolean;
	isManualEditMode?: boolean;
	statsEntity: boolean;
	isOpen: boolean;
	setIsModalOpen: (isOpen: boolean) => void;
	isLoading: boolean;
	onClickSave: () => void;
};
/**
 * Save statistics
 * @param {DeleteFileModalProps}
 * @returns {JSX.Element}
 */
const SaveStatisticsModal = ({
	isOpen,
	setIsModalOpen,
	statsEntity,
	onClickSave,
	isLoading,
	isManualEditMode = false,
	isManagedByApi = false,
}: SaveStatisticsProps): JSX.Element => {
	return (
		<ConfirmModal
			isModalOpen={isOpen}
			toggleModal={() => setIsModalOpen(!isOpen)}
			action={() => onClickSave()}
			title={isManualEditMode && isManagedByApi ? 'Switch to Manual Statistics?' : statsEntity ? 'Update statistics' : 'Add statistics'}
			isFetching={isLoading}
			buttonText={isManualEditMode && isManagedByApi ? 'Yes, switch to manual' : statsEntity ? 'Yes, update' : 'Yes, add statistics'}
			icon='activations'
			IconBackgroundColor={colors.contentManagement.iconBackground}
		>
			{isManualEditMode && isManagedByApi ? (
				<p>Automatic fetching will be stopped, and you&apos;ll need to update the statistics manually when you switch. This action can&apos;t be undone.</p>
			) : (
				<>
					{!statsEntity && <p>You are about to add statistics for this assignment.</p>}

					{statsEntity && <p>You are about to update the statistics for this assignment.</p>}
				</>
			)}
		</ConfirmModal>
	);
};

export default SaveStatisticsModal;
