import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import Icon from 'components/Icon';
import CampaignList from 'components/IntegratedInbox/Components/CampaignList';
import { CAMPAIGN_STATUS } from 'components/IntegratedInbox/Components/CampaignList/types';
import DirectConversationList from 'components/IntegratedInbox/Components/DirectConversationList';
import { DirectConversationData } from 'components/IntegratedInbox/Components/DirectConversationList/DirectConversationData';
import InfluencerList from 'components/IntegratedInbox/Components/InfluencerList';
import { InfluencerConversation } from 'components/IntegratedInbox/Components/InfluencerList/InfluencerList';
import ConversationList from 'components/IntegratedInbox/influencer/ConversationList';
import { CampaignConversation } from 'components/IntegratedInbox/influencer/ConversationList/ConversationList';
import { IntegratedInboxCampaign, TabKey } from 'components/IntegratedInbox/types';
import Tooltip from 'components/Tooltip';
import Grid from 'styles/grid/grid';

import Styled from './CampaignSection.style';

enum Tabs {
	CAMPAIGNS = 'Campaigns',
	DIRECT = 'Direct',
}

/**
 * It renders CampaignList and InfluencerList for campaign manager and ConversationList for influencer on the left side of integrated inbox view
 *
 */
const CampaignSection = (props: {
	displaySkeletonLoader?: boolean;
	directConversations: Model[];
	selectedDirectConversation: DirectConversationData | null;
	onSelectDirectConversation: (conversation: DirectConversationData) => void;
	campaigns: IntegratedInboxCampaign[];
	onSelectCampaign?: (campaign: IntegratedInboxCampaign) => void;
	selectedCampaign: IntegratedInboxCampaign | null;
	onReset: () => void;
	onClickTabs: (selected: TabKey, campaignId: string) => void;
	selectedCampaignInfluencers?: Model[];
	selectedCampaignConversations?: Model[];
	isFetchingCampaigns: boolean;
	isFetchingInfluencers?: boolean;
	isFetchingConversations: boolean;
	onOpenBlast?: () => void;
	onSelectInfluencer?: (influencer: InfluencerConversation) => void;
	selectedInfluencer?: Model | null;
	isInfluencer?: boolean;
	onSelectConversation?: (campaignConversation: CampaignConversation) => void;
	campaignConversations?: Array<{ campaign: IntegratedInboxCampaign; conversations: Model[] }>;
	selectedConversation?: Model | null;
	selectedCampaignStatus?: (campaignStatus: CAMPAIGN_STATUS) => void;
	next: (selected: TabKey, query: string, campaignId: string) => void;
	isLoadingNext: boolean;
	onSearch: (campaginId: string, query: string, joined: boolean) => void;
}) => {
	const [isLeftColVisible, setIsLeftColVisible] = useState<boolean>(true);
	const [isInfluencerListVisible, setIsInfluencerListVisible] = useState<boolean>(false);
	const [isCampaignListOpen, setIsCampaignListOpen] = useState<boolean>(true);
	const [isDirectConversationListOpen, setIsDirectConversationListOpen] = useState<boolean>(true);
	const [selectedTab, setSelectedTab] = useState<string>(Tabs.CAMPAIGNS);

	const params = useParams();
	const publisherHasSelectedCampaign = !props.isInfluencer && props.selectedCampaign;

	useEffect(() => {
		if (props.selectedCampaign) {
			setIsInfluencerListVisible(true);
		} else {
			setIsInfluencerListVisible(false);
		}
	}, [props.selectedCampaign]);

	useEffect(() => {
		if (props.isInfluencer) {
			if (params.campaignId) {
				setIsLeftColVisible(false);
			} else {
				setIsLeftColVisible(true);
			}
		} else {
			if (params.conversationId || props.selectedInfluencer) {
				setIsLeftColVisible(false);
			} else {
				setIsLeftColVisible(true);
			}
		}
	}, [params.campaignId, params.conversationId, props.selectedInfluencer]);
	return (
		<Grid.Column sm={3} className={classNames('three-col', { 'active-col': isLeftColVisible })}>
			<Styled.SideNavContainer>
				{publisherHasSelectedCampaign || props.selectedCampaign?.name ? (
					<Styled.SideNavHeading className={classNames({ back: !props.isInfluencer && isInfluencerListVisible })}>
						{!props.isInfluencer && (
							<Styled.BackButton onClick={props.onReset} className={classNames({ visible: isInfluencerListVisible })}>
								<Icon name='arrow-left' size='18' />
							</Styled.BackButton>
						)}
						{props.displaySkeletonLoader ? (
							<Skeleton width='100px' borderRadius='4px' height='20px' />
						) : publisherHasSelectedCampaign ? (
							<h1 onClick={props.onReset}>{props.selectedCampaign?.name}</h1>
						) : null}
						{publisherHasSelectedCampaign ? (
							<Tooltip content='Send campaign message'>
								<Styled.BlastIconWrapper onClick={props.onOpenBlast}>
									<Icon name='new-message' size='20' />
								</Styled.BlastIconWrapper>
							</Tooltip>
						) : null}
					</Styled.SideNavHeading>
				) : null}
				<Styled.Content>
					<Styled.TabsWrapper>
						<div className={classNames({ selected: selectedTab === Tabs.CAMPAIGNS })} onClick={() => setSelectedTab(Tabs.CAMPAIGNS)}>
							<span>Campaigns</span>
						</div>
						<div className={classNames({ selected: selectedTab === Tabs.DIRECT })} onClick={() => setSelectedTab(Tabs.DIRECT)} data-testid='direct-messages'>
							<span>Private</span>
						</div>
					</Styled.TabsWrapper>
					{!props.isInfluencer ? (
						<>
							{selectedTab === Tabs.CAMPAIGNS ? (
								<CampaignList
									isFetching={props.isFetchingCampaigns}
									campaigns={props.campaigns}
									selectedCampaign={props.selectedCampaign}
									onSelect={props.onSelectCampaign!}
								/>
							) : (
								<DirectConversationList
									isFetching={props.isFetchingConversations}
									directConversations={props.directConversations}
									selectedDirectConversation={props.selectedDirectConversation}
									onClick={() => {
										setIsDirectConversationListOpen((prev) => !prev);
									}}
									isOpen={isDirectConversationListOpen}
									isCampaignListOpen={isCampaignListOpen}
									onSelect={props.onSelectDirectConversation}
									isInfluencer={props.isInfluencer}
									campaignItemsLength={props.campaigns.length}
								/>
							)}
						</>
					) : (
						<>
							{selectedTab === Tabs.CAMPAIGNS ? (
								<ConversationList
									campaigns={props.campaigns}
									displaySkeletonLoader={props.displaySkeletonLoader}
									isFetching={props.isFetchingCampaigns}
									campaignConversations={props.campaignConversations!}
									selectedCampaignStatus={props.selectedCampaignStatus}
									selectedCampaign={props.selectedCampaign}
									onSelect={props.onSelectCampaign!}
									onClick={() => {
										setIsCampaignListOpen((prev) => !prev);
									}}
									isOpen={isCampaignListOpen}
									isDirectConversationListOpen={isDirectConversationListOpen}
									directConversationLength={props.directConversations.length}
								/>
							) : (
								<DirectConversationList
									isFetching={props.isFetchingConversations}
									directConversations={props.directConversations}
									selectedDirectConversation={props.selectedDirectConversation}
									onClick={() => {
										setIsDirectConversationListOpen((prev) => !prev);
									}}
									isOpen={isDirectConversationListOpen}
									isCampaignListOpen={isCampaignListOpen}
									onSelect={props.onSelectDirectConversation}
									isInfluencer={props.isInfluencer}
									campaignItemsLength={props.campaignConversations!.length}
								/>
							)}
						</>
					)}
					{props.selectedCampaignInfluencers && (
						<InfluencerList
							campaignInstagramOwners={props.selectedCampaignInfluencers}
							conversations={props.selectedCampaignConversations!}
							isVisible={isInfluencerListVisible}
							selectedCampaign={props.selectedCampaign}
							isLoading={props.isFetchingInfluencers!}
							onSelect={props.onSelectInfluencer!}
							selectedInfluencer={props.selectedInfluencer}
							onClickTab={props.onClickTabs}
							next={props.next}
							isLoadingNext={props.isLoadingNext}
							onSearch={props.onSearch}
						/>
					)}
				</Styled.Content>
			</Styled.SideNavContainer>
		</Grid.Column>
	);
};

export default CampaignSection;
