import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DeclineButton from 'components/NewBriefPage/Components/DeclineButton';
import JoinButton from 'components/NewBriefPage/Components/JoinButton/JoinButton';
import LoginModal from 'components/NewBriefPage/Components/LoginModal';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import toast from 'services/Toast';

import Styled from './CampaignSummary.style';

type CampaignSummaryProps = {
	campaign: Campaign;
	campaignInstagramOwner: CampaignInstagramOwner;
	onPreview: boolean;
};
/**
 */
const CampaignSummary = ({ campaign, campaignInstagramOwner, onPreview }: CampaignSummaryProps) => {
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

	const navigate = useNavigate();
	const user = useAppSelector((state) => state.user);

	const isInfluencerSignedIn = user.id && user.permissions.isInfluencer;

	if (campaignInstagramOwner.joined) return null;

	return (
		<Styled.SummaryWrapper data-testid='campaign-summary'>
			<Styled.CampaginName>{campaign.name}</Styled.CampaginName>
			<Styled.Wrapper
				onClick={() => {
					onPreview
						? toast.error('This is only preview')
						: isInfluencerSignedIn
							? navigate(`/influencer/inbox/${campaign.shortId}`)
							: setIsLoginModalOpen(true);
				}}
			>
				{campaignInstagramOwner?.links?.decline && <DeclineButton link={campaignInstagramOwner?.links?.decline} />}
				{campaignInstagramOwner && <JoinButton campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} />}
				<LoginModal
					isOpen={isLoginModalOpen}
					onClose={() => {
						setIsLoginModalOpen(false);
					}}
				/>
			</Styled.Wrapper>
		</Styled.SummaryWrapper>
	);
};

export default CampaignSummary;
