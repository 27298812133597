import {
	AssignmentReviewComment,
	AssignmentReviewInternalComment,
	AssignmentReviewMediaPresignUrlType,
	AssignmentReviewMediaType,
	AssignmentReviewType,
	AssignmentType,
	CampaignInstagramOwnerAssingnmentType,
	UserUploadStatus,
} from 'shared/Types/Assignment';
import { CampaignType } from 'shared/Types/Campaign/Campaign.type';
import { CampaignInstagramOwnerType } from 'shared/Types/Campaign/CampaignInstagramOwner.type';
import { InviteType } from 'shared/Types/Campaign/Invite.type';
import { Commission } from 'shared/Types/Commission';
import { InstagramStory, InstagramStoryFile } from 'shared/Types/InstagramStories';
import { User } from 'shared/Types/User';

export enum MediaStatus {
	UPLOADED = 'uploaded',
	PENDING = 'pending',
	DRAFT = 'draft',
}

export type CampaignItemType = CampaignType & {
	shortId: string;
	links: { [key: string]: string };
	invites: Array<InviteType>;
	campaignInstagramOwners: Array<
		CampaignInstagramOwnerType & {
			assignments: AssignmentType;
			campaignInstagramOwnerAssignments: Array<CampaignInstagramOwnerAssingnmentType>;
			campaignInstagramOwnerCommissions: Array<{ commission: Commission }>;
		}
	>;
};

export type AssignmentReviewInternalCommentType = AssignmentReviewInternalComment & { user: Pick<User, 'id' | 'uuid' | 'name' | 'initials'> };
export type AssignmentReviewCommentType = AssignmentReviewComment & { user: Pick<User, 'id' | 'uuid' | 'name' | 'initials'> };

export type CIOAType = Pick<CampaignInstagramOwnerAssingnmentType, 'id' | 'links' | 'status' | 'newFancyStatus' | 'campaignCode'> & {
	assignment: Pick<AssignmentType, 'name' | 'type' | 'hashtags' | 'mentions'>;
};

export type ReviewType = AssignmentReviewType & {
	pendingUploads: Array<AssignmentReviewMediaPresignUrlType>;
	internalComments: Array<AssignmentReviewInternalCommentType>;
	comments: Array<AssignmentReviewCommentType>;
	medias: Array<AssignmentReviewMediaType>;
};

export type InstagramUserStory = InstagramStory & {
	campaignInstagramOwnerAssignment: Pick<CampaignInstagramOwnerAssingnmentType, 'id'>;
	files: Array<InstagramStoryFile>;
	type?: string;
};

export type PatchProps = {
	id: string;
	text: string;
	url: string;
	sort: number;
	screenshot: string;
	patchUrl: string;
};

export type PostMedia = {
	id: string;
	uuid?: string;
	sort: number;
	file?: File;
	originalFilename: string;
	fileUrl?: string;
	vimeoId?: string;
	vimeoPlaybackUrl?: string;
	trackingUrl: string;
	status: MediaStatus;
	type: 'image' | 'video' | 'unknown' | string;
	text: string;
	url: string;
	uploadStatus: UserUploadStatus;
	links: { [key: string]: string };
};
