import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useEffect, useState } from 'react';

import { SecondaryButton } from 'components/Button';
import { EditableImage } from 'components/EditableImage';
import { KIND } from 'components/EditableImage/types/types';
import { CROPPER_OPTIONS } from 'components/EditableImage/utils/utils';
import BroughtToYouBy from 'components/NewBriefPage/Components/BroughtToYouBy/BroughtToYouBy';
import CampaignAssignments from 'components/NewBriefPage/Components/CampaignAssignments';
import CampaignCompensation from 'components/NewBriefPage/Components/CampaignCompensation';
import CampaignInfo from 'components/NewBriefPage/Components/CampaignInfo';
import CampaignProducts from 'components/NewBriefPage/Components/CampaignProducts';
import CampaignProductsEmptyState from 'components/NewBriefPage/Components/CampaignProducts/CampaignProductsEmptyState';
import CampaignSummary from 'components/NewBriefPage/Components/CampaignSummary';
import Intro from 'components/NewBriefPage/Components/Intro';
import MoodBoardImages from 'components/NewBriefPage/Components/MoodBoardImages';
import NavBar from 'components/NewBriefPage/Components/NavBar';
import TermsAndConditionsText from 'components/NewBriefPage/Components/TermsAndConditions/TermsAndConditions';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import usePermissions from 'hooks/usePermissions';
import { ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './NewBriefPage.style';

type BriefPageProps = {
	campaignInstagramOwner: CampaignInstagramOwner;
	campaign: Campaign;
	getBriefData?: () => void;
	isLoading?: boolean;
	onInvite?: boolean;
};
/**
 * @returns JSX.Element
 */
const BriefPage = ({ campaign, campaignInstagramOwner, getBriefData, isLoading, onInvite }: BriefPageProps): JSX.Element => {
	const [moodBoardImages, setMoodBoardImages] = useState<Model[] | undefined>(undefined);
	const [isOnPreview, setIsOnPreview] = useState<boolean>(false);
	const [hideCoverImage, setHideCoverImage] = useState<boolean>(false);

	const user = useAppSelector((state) => state.user);
	const userIsInfluencer = user.permissions.isInfluencer || !user.id;

	const { isGranted } = usePermissions();
	const canEdit = isGranted(ROLE_PROJECT_MANAGER);
	const croppedImages = campaignInstagramOwner?.campaign?.campaignImages?.croppedImages;

	useEffect(() => {
		if (campaignInstagramOwner) {
			if (campaignInstagramOwner.campaign.campaignImages?.moodBoardImages) {
				if (Array.isArray(campaignInstagramOwner.campaign.campaignImages.moodBoardImages)) {
					setMoodBoardImages(campaignInstagramOwner.campaign.campaignImages.moodBoardImages);
				}
			}
		} else if (campaign) {
			if (campaign.moodBoardImages) {
				setMoodBoardImages(campaign.moodBoardImages);
			}
		} else {
			setMoodBoardImages(undefined);
		}
	}, [campaign]);

	const navigateHandler = (value: string) => {
		const el = document.getElementById(value);
		if (el) {
			el.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (location.pathname.includes('preview')) {
			setIsOnPreview(true);
		} else {
			setIsOnPreview(false);
		}
	}, [location]);

	return (
		<Styled.Wrapper>
			<NavBar
				onInvite={onInvite}
				spotsLeft={campaignInstagramOwner?.campaign.spotsLeftOnSegment}
				hasJoined={campaignInstagramOwner.joined!}
				onPreview={isOnPreview}
				campaign={campaign}
				onClick={navigateHandler}
				campaignInstagramOwner={campaignInstagramOwner}
			/>
			<Styled.ImageWrapper
				data-testid='campaign-brief-image-wrapper'
				className={classNames({ isInfluencer: userIsInfluencer, isPublisher: !userIsInfluencer })}
			>
				<EditableImage
					loadingBriefData={isLoading}
					getBriefData={getBriefData}
					className='campaign-brief-image'
					kind={KIND.CAMPAIGN_COVER_IMAGE}
					isFeatureEnabled={true}
					coverImages={croppedImages!}
					coverImage={campaignInstagramOwner?.campaign?.campaignImages?.coverImage}
					grantedForEditing={canEdit}
					isVisible={!hideCoverImage}
					onHide={() => setHideCoverImage(true)}
					cropperOptions={{ ...CROPPER_OPTIONS, aspectRatio: 16 / 9 }}
					modalTestId='brief-image-modal'
				/>
				<Styled.LogoWrapper>
					{campaignInstagramOwner.brand?.links.logo && (
						<Styled.BrandLogo src={campaignInstagramOwner.brand?.links.logo} alt={campaignInstagramOwner.brand?.name} />
					)}
					{campaignInstagramOwner?.publisher?.links.logo && (
						<Styled.PublisherWrapper>
							<Styled.PublisherLogotype>
								<img src={campaignInstagramOwner?.publisher?.links.logo} alt={campaignInstagramOwner.publisher?.name} />
							</Styled.PublisherLogotype>
							<Styled.OfficialPartner>Official partner</Styled.OfficialPartner>
						</Styled.PublisherWrapper>
					)}
				</Styled.LogoWrapper>
			</Styled.ImageWrapper>
			<Styled.Container>
				{hideCoverImage && (
					<Styled.ToggleCoverImageButtonContainer>
						<SecondaryButton onClick={() => setHideCoverImage(false)} icon='unhide'>
							Show cover image
						</SecondaryButton>
					</Styled.ToggleCoverImageButtonContainer>
				)}
				<Intro id='intro' isAffiliate={campaign.affiliate} hasJoined={campaignInstagramOwner.joined ?? false} />
				<CampaignCompensation id='compensation' campaignInstagramOwner={campaignInstagramOwner} campaign={campaign} />
				<CampaignInfo id='brief' campaign={campaign} />
				{campaign.termsAndConditions && campaign.includeTocInBrief && <TermsAndConditionsText campaign={campaign} />}
				<CampaignAssignments
					id='assignments'
					campaignId={campaign.shortId}
					assignments={campaignInstagramOwner?.campaign?.assignments}
					onPreview={isOnPreview}
					canEdit={canEdit}
				/>
				{campaignInstagramOwner?.campaign?.products.length > 0 ? (
					<CampaignProducts id='products' campaignId={campaign.shortId} products={campaignInstagramOwner?.campaign?.products} canEdit={canEdit} />
				) : (
					isOnPreview && canEdit && <CampaignProductsEmptyState campaignId={campaign.shortId} />
				)}
			</Styled.Container>
			{(isOnPreview && canEdit) ||
			(isOnPreview && moodBoardImages && moodBoardImages?.length > 0) ||
			(!isOnPreview && moodBoardImages && moodBoardImages?.length > 0) ? (
				<MoodBoardImages
					campaignId={campaign.id}
					getBriefData={getBriefData}
					isLoadingBrief={isLoading}
					moodBoardImages={moodBoardImages}
					onPreview={isOnPreview}
					canEdit={canEdit}
				/>
			) : null}
			<BroughtToYouBy name={campaignInstagramOwner?.publisher?.legalName} logo={campaignInstagramOwner?.publisher?.links.logo} />
			<CampaignSummary onPreview={isOnPreview} campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} />
		</Styled.Wrapper>
	);
};

export default BriefPage;
