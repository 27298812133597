import React from 'react';

import ConfirmModal from 'components/Modals/ConfirmModal';
import DeleteModal from 'components/Modals/DeleteModal';
import { PALETTE } from 'styles/variables/original-colors';

type ModalHandlers = {
	onApprove: () => void;
	onRequestChanges: () => void;
	onSendForReview: () => void;
	onDeleteMedia: (mediaId: string) => void;
	setIsApproveModalOpen: (isOpen: boolean) => void;
	setIsRequestChangeModalOpen: (isOpen: boolean) => void;
	setIsSendForReviewModalOpen: (isOpen: boolean) => void;
	setIsDeleteModalOpen: (isOpen: boolean) => void;
};

type ModalState = {
	isSendForReviewModalOpen: boolean;
	isApproveModalOpen: boolean;
	isRequestChangeModalOpen: boolean;
	isDeleteModalOpen: boolean;
	selectedMediaId: string;
	isPosting: boolean;
	isUploadingContent: boolean;
};

const Modals = ({ state, handlers }: { state: ModalState; handlers: ModalHandlers }) => {
	const { isSendForReviewModalOpen, isApproveModalOpen, isRequestChangeModalOpen, isDeleteModalOpen, selectedMediaId, isPosting, isUploadingContent } = state;

	const { onApprove, onRequestChanges, onSendForReview, onDeleteMedia } = handlers;

	return (
		<>
			<ConfirmModal
				icon='upload'
				IconBackgroundColor={PALETTE.aquaHaze}
				isModalOpen={isSendForReviewModalOpen}
				toggleModal={() => handlers.setIsSendForReviewModalOpen(!isSendForReviewModalOpen)}
				title='Submit for review'
				action={onSendForReview}
				buttonText='Yes, submit'
				isFetching={isPosting || isUploadingContent}
			>
				<p>Once submitted, your content will be reviewed, and you'll be notified.</p>
			</ConfirmModal>

			<ConfirmModal
				IconBackgroundColor={PALETTE.aquaHaze}
				isModalOpen={isApproveModalOpen}
				toggleModal={() => handlers.setIsApproveModalOpen(!isApproveModalOpen)}
				action={onApprove}
				title='Approve content for posting'
				isFetching={isPosting}
				buttonText='Approve'
				icon='check-circle'
			>
				<p>Please make sure you have checked that all required information is added.</p>
			</ConfirmModal>

			<ConfirmModal
				IconBackgroundColor={PALETTE.aquaHaze}
				isModalOpen={isRequestChangeModalOpen}
				toggleModal={() => handlers.setIsRequestChangeModalOpen(!isRequestChangeModalOpen)}
				action={onRequestChanges}
				title='Request changes'
				isFetching={isPosting}
				buttonText='Request changes'
				icon='changes'
			>
				<p>Add your requested changes in the comments before notifying the influencer.</p>
			</ConfirmModal>

			<DeleteModal
				isModalOpen={isDeleteModalOpen}
				toggleModal={() => handlers.setIsDeleteModalOpen(!isDeleteModalOpen)}
				action={() => onDeleteMedia(selectedMediaId)}
				title='Are you sure?'
				isFetching={isPosting}
			>
				<p>Are you sure you want to delete this media?</p>
			</DeleteModal>
		</>
	);
};

export default Modals;
