import moment from 'moment';

import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';

/**
 * @returns {JSX.Element}
 */
const Heading = (props: { links: { [key: string]: string }; postedAt?: string }): JSX.Element => {
	return (
		<Styled.HeadingWrapper>
			{props.postedAt && (
				<Styled.Heading>
					<p>{`Post date ${moment(props.postedAt).format('YYYY-MM-DD')}`}</p>
				</Styled.Heading>
			)}
		</Styled.HeadingWrapper>
	);
};

export default Heading;
