import styled from 'styled-components';

import { ModalContent } from 'components/Modals/Modal/Modal.style';
import Modal from 'components/Modals/Modal/V2';
import colors from 'styles/theme/colors';
import { devices } from 'styles/variables/media-queries';

const CustomModal = styled(Modal)`
	padding-right: 0;
	text-align: start;
	${ModalContent} {
		width: 100%;
		height: auto;
		max-height: 85vh;
		@media screen and (${devices.md}) {
			width: 510px;
		}

		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
		border: 1px solid ${colors.ash};
		border-radius: ${({ theme }) => theme.radius.default};
		overflow: hidden;
	}
`;

const ModalContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1.5rem 1rem;

	p {
		margin: 0;
	}

	@media screen and (${devices.sm}) {
		text-align: start;
		padding: 2.5rem;

		h3 {
			font-size: 1.4375rem;
			line-height: 1.39;
		}
	}
`;

interface IconContainerColor {
	backgroundColor?: string;
}

const ModalIconContainer = styled.div<IconContainerColor>`
	height: 32px;
	width: 32px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	.icon {
		margin-top: 3px;
	}
	background: ${(props) => props.backgroundColor};
`;

const ModalButtonsContainer = styled.div`
	width: 100%;
	margin-top: 24px;

	display: flex;
	flex-direction: column-reverse;
	row-gap: 16px;
	justify-content: space-between;

	&.center {
		justify-content: center;
	}

	@media screen and (${devices.sm}) {
		flex-direction: row;
	}
`;

const ModalChildren = styled.div`
	max-height: 300px;
	overflow-y: auto;
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 1rem;
	color: ${colors.slate};
	hr {
		border-top: ${colors.ash} 1px solid;
	}
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	padding-bottom: 1rem;
	h5 {
		margin-bottom: 0;
	}
`;

const Styled = {
	ModalContent,
	CustomModal,
	ModalContainer,
	ModalIconContainer,
	ModalButtonsContainer,
	ModalChildren,
	Title,
};

export default Styled;
