import { useState } from 'react';

import { BrandInviteModel, ClientInviteModel, PublisherInviteModel } from 'api-models';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import { isMobile } from 'shared/utils/navigator';

import Styled from './InvitationList.style';

type InvitationListProps = {
	invites: Array<BrandInviteModel | ClientInviteModel | PublisherInviteModel>;
	deleteInvite: (invitedId: string) => void;
	canEdit: boolean;
};

const InvitationList = ({ invites, deleteInvite, canEdit }: InvitationListProps) => {
	const [deleteThis, setDeleteThis] = useState<number | undefined>(undefined);

	return (
		<Styled.UserList>
			{invites
				?.filter((invite) => !invite.attributes.used)
				.map((invite, key) => {
					return (
						<li key={key}>
							<UserAvatar fullName={invite.attributes.email} displayRole displayThisRole='Pending' className='dark' medium />
							{canEdit &&
								(isMobile() ? (
									<Dropdown icon='options' size='16'>
										<DropdownMenu>
											<DropdownItem onClick={() => deleteInvite(invite.id)}>Delete</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								) : (
									<Styled.DeleteWrapper onMouseEnter={() => setDeleteThis(key)} onMouseLeave={() => setDeleteThis(undefined)}>
										{deleteThis === key && <p>Delete</p>}
										<Styled.DeleteContainer data-testid='delete-invite' onClick={() => deleteInvite(invite.id)}>
											<Icon name='cross' size='12' />
										</Styled.DeleteContainer>
									</Styled.DeleteWrapper>
								))}
						</li>
					);
				})}
		</Styled.UserList>
	);
};

export default InvitationList;
