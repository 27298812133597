import classNames from 'classnames';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ClientModel, PublisherModel } from 'api-models';
import { ListPublishersQuery } from 'api-queries';
import noResult from 'assets/icons/empty-state-no-result.svg';
import { Icon } from 'components';
import EmptyState from 'components/EmptyState/V2';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import CenteredSpinner from 'components/Settings/Components/CenteredSpinner/CenteredSpinner';
import NotItems from 'components/Settings/Components/NoItems/NoItems';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import Grid from 'styles/grid/grid';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import HandlePublisher from './Components/HandlePublisher/HandlePublisher';
import CreatePublisher from './Components/InvitePublisher/InvitePublisher';
import PublisherItemCard from './Components/PublisherItemCard/PublisherItemCard';
import Styled from './PublisherManagement.style';

const PublisherManagement = () => {
	const [searchValue, setSearchValue] = useState('');

	const manager = useInjection<PublisherManager>(PublisherManager);
	const { repository, isLoading, mutate } = manager.listPublishers(
		RequestQueryBuilder.create<ListPublishersQuery>(['logo', 'edit', 'logo', 'createPublisher', 'deleteLogo']).withInclude('clients', ['logo']),
	);

	const publishers = repository.findAll('publisher') as PublisherModel[];

	const getClientsByPublisher = (publisher: PublisherModel): ClientModel[] => {
		return publisher && repository.findByRelation<ClientModel, PublisherModel>(publisher, 'clients');
	};

	const matchesSearch = (search: string, publisher: PublisherModel): boolean => {
		const toLower = search.toLowerCase().trim();
		const name = publisher.attributes?.name.toLowerCase().trim();

		return name.includes(toLower);
	};

	const { publisherId } = useParams();

	return (
		<Grid.Container gap='24'>
			<Grid.Column xl={12}>
				<h4>Publisher management</h4>
				<p>Invite or manage your publishers below</p>
				<Styled.FlexDiv>
					<CreatePublisher mutateFn={mutate} />
					{publisherId && <HandlePublisher mutatePublishers={mutate} />}
					<Styled.SearchInputWrapper>
						<Field>
							<Input
								placeholder='Search for publishers'
								value={searchValue}
								onChange={({ target }) => setSearchValue(target.value)}
								contentBefore={<Icon name='search' size='16' />}
							/>
						</Field>
					</Styled.SearchInputWrapper>
				</Styled.FlexDiv>
			</Grid.Column>
			<Grid.Column xl={12}>
				{isLoading ? (
					<CenteredSpinner />
				) : (
					<>
						{publishers.length > 0 ? (
							<Styled.PublisherContainer
								id='publishers'
								className={classNames({ searching: searchValue.length > 0 && publishers.filter((client) => matchesSearch(searchValue, client)).length > 0 })}
							>
								{publishers
									.filter((publisher) => matchesSearch(searchValue, publisher))
									.map((publisher, key) => {
										return <PublisherItemCard publisher={publisher} clients={getClientsByPublisher(publisher)} key={key} />;
									})}
								{publishers.filter((client) => matchesSearch(searchValue, client)).length === 0 && (
									<Styled.EmptyResultImgWrapper>
										<EmptyState
											image={noResult}
											altText='No result found'
											testId='no-result-found'
											text={
												<>
													<p>
														Unfortunately, <strong>'{searchValue}'</strong> did not return any results.
													</p>
												</>
											}
										/>
									</Styled.EmptyResultImgWrapper>
								)}
							</Styled.PublisherContainer>
						) : (
							<NotItems icon='influencer' title='Add client' text='Add a client to organize and manage their brands.' />
						)}
					</>
				)}
			</Grid.Column>
		</Grid.Container>
	);
};
export default PublisherManagement;
