import classNames from 'classnames';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import * as Yup from 'yup';

import { BrandModel } from 'api-models';
import { SecondaryButton } from 'components/Button';
import { InputText } from 'components/Form/FormikElements';
import { capitalizeFirstLetter } from 'components/Form/FormikElements/Text/Utils';
import Icon from 'components/Icon';
import Label from 'components/Label';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';

import * as Styled from './EditUrl.style';

// ISSUE 6184: Add other models here when they can edit their url
type EditUrlProps = {
	item: BrandModel;
	updateUrl: (url: string) => Promise<BrandModel | undefined>;
	canEdit: boolean;
};

const EditUrl = ({ item, updateUrl, canEdit }: EditUrlProps) => {
	const handleUpdateUrl = (url: string) => {
		return updateUrl(url)
			.then(() => {
				!isEqual(item.attributes.url, url) && toast.success(`${capitalizeFirstLetter(item.type)} URL updated`);
			})
			.catch(() => {
				toast.error(getErrorMessageOnPost(`updating the ${item.type}`));
			});
	};

	const validation = Yup.object().shape({
		url: Yup.string().url('Please add a valid URL'),
	});

	return (
		<Formik
			initialValues={{ url: item.attributes?.url ?? '' }}
			validationSchema={validation}
			onSubmit={({ url }, { setSubmitting }) => {
				setSubmitting(true);
				handleUpdateUrl(url);
				setSubmitting(false);
			}}
		>
			{({ values, isValid, isSubmitting, handleSubmit }) => (
				<Styled.Wrapper>
					<Styled.Title>Enter link to company website</Styled.Title>
					<Label htmlFor='url'>Enter link</Label>
					<Styled.InputWrapper>
						<InputText
							id='url'
							name='url'
							disabled={!canEdit}
							placeholder='https://www.collabs.se/'
							contentAfter={
								isValid && values.url ? (
									<Styled.IconLink href={values.url} target='_blank' rel='noreferrer'>
										<Icon name='external-link' size='20' />
									</Styled.IconLink>
								) : null
							}
							contentBefore={
								<Icon
									className={classNames({
										disabled: !canEdit,
									})}
									name='url-link'
									size='20'
								/>
							}
						/>
						{canEdit && (
							<SecondaryButton onClick={() => handleSubmit()} disabled={!isValid || isSubmitting} isLoading={isSubmitting}>
								Save
							</SecondaryButton>
						)}
					</Styled.InputWrapper>
				</Styled.Wrapper>
			)}
		</Formik>
	);
};
export default EditUrl;
