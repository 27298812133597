import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';

import { CampaignInstagramOwnerModel, CampaignModel, InfluencerModel } from 'api-models';
import ForbiddenPage from 'components/ErrorPages/Forbidden';
import { isMobile } from 'shared/utils/navigator';
import IRepository from 'utils/Repository/IRepository';
import CMSidebar from 'views/Campaign/Dashboard/ContentManagement/ContentManagementSidebar';
import { RouteParams } from 'views/Campaign/Dashboard/types';

import CampaignItem from './CampaignItem/CampaignItem';
import Styled from './Campaigns.style';

type CampaignProps = {
	campaigns: CampaignModel[];
	displaySkeletonLoader: boolean;
	repository: IRepository;
};
/**
 * @returns {JSX.Element}
 */
const Campaigns = ({ campaigns, displaySkeletonLoader, repository }: CampaignProps): JSX.Element => {
	const navigate = useNavigate();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const { campaignId, assignmentId, ownerId } = useParams<RouteParams>();

	useEffect(() => {
		if (assignmentId && ownerId && campaignId) {
			setModalIsOpen(true);
		} else {
			setModalIsOpen(false);
		}
	}, [assignmentId, ownerId, campaignId]);

	const campaign = useMemo(() => campaigns.find((c) => c.attributes.shortId === campaignId || c.id === campaignId), [campaigns, campaignId]);

	if (!campaign && campaignId) {
		return <ForbiddenPage />;
	}

	const foundCampaign = campaigns.find((c) => c.id === campaignId);
	const selectedCIO = foundCampaign && repository.findByRelation<CampaignInstagramOwnerModel, CampaignModel>(foundCampaign, 'campaignInstagramOwners')[0];

	const influencerId = useMemo(() => {
		if (!foundCampaign) return '';
		const owner = repository.findByRelation<CampaignInstagramOwnerModel, CampaignModel>(foundCampaign, 'campaignInstagramOwners')[0];
		return owner ? repository.findOneByRelation<InfluencerModel, CampaignInstagramOwnerModel>(owner, 'influencer')?.id || '' : '';
	}, [campaigns, campaignId, repository]);

	const renderColumn = (filterFn: (index: number) => boolean) =>
		campaigns
			.filter((_, index) => filterFn(index))
			.map((campaign) =>
				displaySkeletonLoader ? (
					<Skeleton height='500px' width='600px' key={campaign.id} />
				) : (
					<CampaignItem key={campaign.id} campaign={campaign} repository={repository} setModalIsOpen={() => setModalIsOpen(true)} />
				),
			);

	return (
		<Styled.Wrapper>
			{isMobile() ? (
				<Styled.Column>
					{campaigns.map((campaign) =>
						displaySkeletonLoader ? (
							<Skeleton height='500px' width='600px' key={campaign.id} />
						) : (
							<CampaignItem key={campaign.id} campaign={campaign} repository={repository} setModalIsOpen={() => setModalIsOpen(true)} />
						),
					)}
				</Styled.Column>
			) : (
				<>
					<Styled.Column>{renderColumn((index) => index % 2 === 0)}</Styled.Column>
					<Styled.Column>{renderColumn((index) => index % 2 !== 0)}</Styled.Column>
				</>
			)}
			{modalIsOpen && campaign && (
				<CMSidebar
					selectedCIO={selectedCIO}
					campaign={campaign}
					influencerId={influencerId}
					campaigns={campaigns}
					onClose={() => {
						navigate(-1);
						setModalIsOpen(false);
					}}
					AssignmentsListRef={undefined}
					repository={repository}
				/>
			)}
		</Styled.Wrapper>
	);
};

export default Campaigns;
