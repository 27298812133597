import { AxiosResponse } from 'axios';
import { JsonApiDocument } from 'json-api-models';

import { AssignmentReviewCommentType, AssignmentReviewInternalCommentType } from 'components/ContentManagement/types';
import { UserType } from 'reducers/UserReducers/types';

export enum selectedTabType {
	Influencer = 'To profile',
	BrandManager = 'To client',
}

export type CommentType = {
	id: string;
	attributes: {
		createdAt: string;
		text: string;
		readAt: string | null;
	};
	links?: { [key: string]: string };
	user: UserType;
};

export type CommentsProps = {
	user: UserType;
	canUseTabs: boolean;
	comments?: (AssignmentReviewCommentType | AssignmentReviewInternalCommentType)[];
	disabled?: boolean;
	activeTab?: selectedTabType;
	onClickTab?: (tab: selectedTabType) => void;
	onSend: (message: string) => Promise<void | AxiosResponse<JsonApiDocument>>;
};
