import { first } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import LoadingSpinner from 'components/LoadingSpinner';
import useModels from 'hooks/useModels';
import ToolsService from 'services/Tools';
import { createClient } from 'shared/ApiClient/ApiClient';
import { gtagEvent } from 'utils/ga';
import LinkAlreadyUsed from 'views/BriefPage/LinkAlreadyUsed';

import Signup from './Components/SignUp';

type RouteParams = {
	inviteToken: string;
};

/**
 */
const InfluencerSignUpContainer = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { inviteToken: token } = useParams<RouteParams>();
	const isAgent = token === 'agent';
	const { models, loading } = useModels(!isAgent ? `/public/campaigns/${token}` : null);
	const invites = models.findAll('invite');
	const firstInvite = invites.length > 0 ? first(invites) : null;
	const [searchParams] = useSearchParams();
	const location = useLocation();

	const checkToken = () => {
		const baseUrl = '/public/campaigns/';

		setIsLoading(true);
		createClient()
			.get(baseUrl + token)
			.catch((error) => {
				ToolsService.log('Account Creation error', {
					error: error,
					errorInfo: error,
				});
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (token && !isAgent && !firstInvite && !loading) {
			checkToken();
		}
	}, [firstInvite]);

	useEffect(() => {
		const origin = token ? 'invitation' : searchParams.get('origin') ?? 'unknown';
		gtagEvent('sign_up_page_shown', { origin });

		return () => {
			setIsLoading(false);
		};
	}, []);

	return isLoading || loading ? (
		<LoadingSpinner position='center' />
	) : location.pathname === '/signup' ? (
		<PageNotFound />
	) : !token && !firstInvite && location.pathname !== '/signup/agents' ? (
		<LinkAlreadyUsed />
	) : (
		<Signup inviteToken={token!} email={firstInvite?.email} />
	);
};

export default InfluencerSignUpContainer;
