import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from 'components/Button';
import { BlueButton } from 'components/Button/Button';
import { FacebookPage } from 'components/Settings/Profiles/types';
import { ApiStatus } from 'constants/socialMedia';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { InfluencerType, UserType } from 'reducers/UserReducers/types';
import FacebookAuthService from 'services/FacebookApi/Facebook-auth.service';
import toast from 'services/Toast';
import { updateUser } from 'shared/User/User.helpers';
import { gtagEvent } from 'utils/ga';

import FacebookConnector from './Components/Facebook';
import ConnectedFacebookProfiles from './Components/Facebook/ConnectedFacebookProfiles';
import Styled from './SocialProfileConnector.style';
import { SocialProfileConnectorProps } from './types';

/**
 * SocialProfileConnector
 * @param {SocialProfileConnectorProps} props
 * @returns {JSX.Element}
 */
const SocialProfileConnector = ({ hasInviteToken, isSignUp = false, onClickCreateAccount, setSteps }: SocialProfileConnectorProps): JSX.Element => {
	const [connectedToFacebook, setConnectedToFacebook] = useState<boolean>(false);

	const [FbPagesIsLoading, setFbPagesIsLoading] = useState<boolean>(false);
	const [FbPages, setFbPages] = useState<Array<FacebookPage>>([]);
	const [FbPagesWithInstagramAccount, setFbPagesWithInstagramAccount] = useState<Array<FacebookPage>>([]);
	const [FbPagesWithoutInstagramAccount, setFbPagesWithoutInstagramAccount] = useState<Array<FacebookPage>>([]);

	const user: UserType = useAppSelector((state) => state.user);

	const POLL_INTERVAL = 1000;
	let getFacebookIPagesInterval: ReturnType<typeof setTimeout>;

	const navigate = useNavigate();

	const metaConnectionSucceeded = (getPages: boolean) => {
		setConnectedToFacebook(true);
		setFbPagesIsLoading(true);
		updateUser()
			.then(() => {
				if (getPages) {
					// Poll pages because until backend completes the connection
					getFacebookIPagesInterval = setInterval(() => {
						getConnectedFacebookPages();
					}, POLL_INTERVAL);
				}
				updateUser();
				toast.success('Connection to Instagram succeeded');
				setSteps && setSteps(3);
			})
			.catch((error) => {
				console.error(error);
				toast.error(getSomethingWentWrongMessage());
				setFbPagesIsLoading(false);
			});
	};

	const metaDisconnectSucceeded = () => {
		setFbPages([]);
		setFbPagesWithInstagramAccount([]);
		setFbPagesWithoutInstagramAccount([]);
		setConnectedToFacebook(false);
	};

	const getConnectedFacebookPages = () => {
		setFbPagesIsLoading(true);
		FacebookAuthService.getBusinessAccounts()
			.then((res) => {
				if (res) {
					// Check for instagram accounts with collabs Id.
					const fbPageWithAccounts = res.filter((page: FacebookPage) =>
						!isNil(page.attributes.instagramAccounts) ? page.attributes.instagramAccounts.filter((account) => account.collabsId !== null).length > 0 : false,
					);
					const fbPageWithOutAccounts = res.filter(
						(page: FacebookPage) =>
							page.attributes.instagramAccounts?.length === 0 || page.attributes.instagramAccounts?.filter((account) => account.collabsId === null).length > 0,
					);

					setFbPagesWithInstagramAccount(fbPageWithAccounts);
					setFbPagesWithoutInstagramAccount(fbPageWithOutAccounts);

					setConnectedToFacebook(true);
					setFbPages(res);
					setFbPagesIsLoading(false);
					clearInterval(getFacebookIPagesInterval);
				}
			})
			.catch(() => {
				gtagEvent('FB-connect-failed-error');
				const connectedAccounts = user.influencers?.filter((influencer: InfluencerType) => influencer.apiStatus === ApiStatus.CONNECTED);
				if (connectedAccounts.length > 0) {
					toast.error('Connected pages could not be fetched. Try again later');
				}
				setFbPagesIsLoading(false);
			});
	};

	useEffect(() => {
		if (user.connectedToFacebook) {
			getConnectedFacebookPages();
		}
		return () => clearInterval(getFacebookIPagesInterval); // Clean up
	}, [user.connectedToFacebook]);

	useEffect(() => {
		return () => clearInterval(getFacebookIPagesInterval);
	}, []);

	return (
		<Styled.Wrapper data-testid='select-platform'>
			<Styled.ConnectorWrapper>{!user.connectedToFacebook && <FacebookConnector connectionSucceeded={metaConnectionSucceeded} />}</Styled.ConnectorWrapper>

			<Styled.ConnectedProfiles>
				{user.connectedToFacebook && (
					<ConnectedFacebookProfiles
						connectedPages={FbPages}
						FbPagesWithInstagramAccount={FbPagesWithInstagramAccount}
						FbPagesWithoutInstagramAccount={FbPagesWithoutInstagramAccount}
						connectedToFacebook={connectedToFacebook}
						FbPagesIsLoading={FbPagesIsLoading}
						disconnectSucceeded={metaDisconnectSucceeded}
						isSignUp={isSignUp}
					/>
				)}
				{isSignUp &&
					!user.connectedToFacebook &&
					!FbPagesIsLoading &&
					(hasInviteToken ? (
						<Styled.Skip role='button' onClick={() => onClickCreateAccount && onClickCreateAccount()}>
							Skip for now
						</Styled.Skip>
					) : (
						<Styled.SignUpButtonWrapper>
							<PrimaryButton onClick={() => onClickCreateAccount && onClickCreateAccount()}>Create account</PrimaryButton>
						</Styled.SignUpButtonWrapper>
					))}
				{user.connectedToFacebook && isSignUp && (
					<Styled.SignUpButtonWrapper>
						<BlueButton onClick={() => navigate('/influencer/dashboard')} disabled={!connectedToFacebook}>
							Proceed to dashboard
						</BlueButton>
					</Styled.SignUpButtonWrapper>
				)}
			</Styled.ConnectedProfiles>
		</Styled.Wrapper>
	);
};

export default SocialProfileConnector;
