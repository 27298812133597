import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import CountrySelector from 'components/Form/Elements/CountrySelector/CountrySelector';
import { InputText } from 'components/Form/FormikElements';
import LoadingSpinner from 'components/LoadingSpinner';
import { createClient } from 'shared/ApiClient/ApiClient';
import { isVatRequired } from 'utils/countries_utils';
import errorHandler from 'utils/formik_error_handler';
import Styled from 'views/login/Login.style';

import { StepProps } from './types';

const INITIAL_VALUES = {
	invoiceEmail: '',
	legalName: '',
	invoiceAddress: '',
	invoicePostalCode: '',
	invoiceCountryCode: 'SWE',
	invoiceCity: '',
	organizationNumber: '',
};

/**
 */
const BillingInformation = ({ publisherEdit, onSubmit, incomplete }: StepProps) => {
	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

	return (
		<Formik
			onSubmit={async (values, { setErrors }) => {
				try {
					if (publisherEdit) {
						await createClient()
							.patch(publisherEdit, values)
							.then(() => {
								onSubmit();
							});
					}
				} catch (e) {
					errorHandler(e, setErrors);
				}
			}}
			validationSchema={Yup.object().shape({
				legalName: Yup.string().required('Required'),
				invoiceEmail: Yup.string().email('Billing email must be a valid email address').required('Required'),
				invoiceAddress: Yup.string().required('Required'),
				invoicePostalCode: Yup.string().required('Required'),
				invoiceCity: Yup.string().required('Required'),
				invoiceCountryCode: Yup.string().required('Required'),
				vatNumber: Yup.string().when('invoiceCountryCode', {
					// Require VAT only for EU countries
					is: (countryCode: string) => isVatRequired(countryCode),
					then: () => Yup.string().required('Required'),
				}),
			})}
			validateOnBlur={validateAfterSubmit}
			validateOnChange={validateAfterSubmit}
			initialValues={INITIAL_VALUES}
		>
			{({ isSubmitting, isValid, dirty }) => (
				<Form>
					<p className='muted'>{!incomplete && 'Step 3 of 3 - '} Billing information</p>

					<fieldset>
						<InputText label='Billing email' name='invoiceEmail' type='email' required placeholder='Address will be used for all billings' />
						<Styled.SubTitle>Company information</Styled.SubTitle>
						<InputText label='Company name' name='legalName' required placeholder={'Ex. Acme'} />
						<InputText label='Company address' name='invoiceAddress' required placeholder='Ex. Streetaddress 12' />
						<div className='d-flex justify-between gutter-l'>
							<InputText label='ZIP/Postal code' name='invoicePostalCode' required placeholder='Ex: 111 11' />
							<InputText label='City' name='invoiceCity' required placeholder='Ex: Stockholm' />
						</div>
						<CountrySelector name='invoiceCountryCode' />
						<InputText label='VAT number' name='vatNumber' required placeholder='Ex. SE01122334455' />
					</fieldset>
					<PrimaryButton type='submit' disabled={isSubmitting || !isValid || !dirty} onClick={() => setValidateAfterSubmit(!validateAfterSubmit)}>
						{isSubmitting ? <LoadingSpinner size='sm' position='center' /> : !incomplete ? 'Create account' : 'Save'}
					</PrimaryButton>
				</Form>
			)}
		</Formik>
	);
};

export default BillingInformation;
