import axios, { AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { Model, Store } from 'json-api-models';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import Checkbox from 'components/Checkbox';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import InputText from 'components/Form/FormikElements/Text';
import Icon from 'components/Icon';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { ApiStatus } from 'constants/socialMedia';
import useInjection from 'hooks/useInjection';
import { useAppDispatch } from 'hooks/useUserAppSelector';
import { setPermissions } from 'reducers/UserReducers/UserPermissionsSlice';
import { setUser } from 'reducers/UserReducers/UserSlice';
import { PinebucketStatus, UserPaymentType } from 'reducers/UserReducers/types';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';
import ReferralUrlService from 'services/ReferralUrlService';
import { ErrorResponse } from 'services/Response.types';
import { updateUser } from 'shared/User/User.helpers';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';
import Styled from 'views/login/components/Form.style';

const LoginForm = () => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const [errorMessage, setErrorMessage] = useState('');
	const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
	const [isTermsAndConditionsAgreed, setIsTermsAndConditionsAgreed] = useState(false);
	const [userType, setUserType] = useState('');
	const [displayPassword, setDisplayPassword] = useState(false);

	const navigate = useNavigate();
	const emailRef = useRef<HTMLInputElement>(null);
	const models = new Store();
	const dispatch = useAppDispatch();
	const storage = new BrowserStorage(StorageType.SESSION);

	const VALIDATION_SCHEMA = Yup.object().shape({
		email: Yup.string().email().required('This email looks wrong'),
		password: Yup.string().required(),
	});

	const logoutService = useInjection<LogoutService>(LogoutService);

	const setWrongUsernamePasswordMessage = () => {
		setErrorMessage('We are unable to match the email and password you entered. Please check and try again.');
	};

	const clearWrongUsernamePasswordMessage = () => {
		setErrorMessage('');
	};

	const hasRoles = (user: Model): boolean => {
		return (
			Object.keys(user.attributes.permissions.entities).length > 0 ||
			user.attributes.permissions.is_influencer ||
			user.attributes.permissions.is_agent ||
			user.attributes.permissions.is_super_admin
		);
	};

	const closeTermsAndConditionModalHandler = () => {
		logoutService.panicLogout();
	};

	const agreeTermsAndConditionHandler = async () => {
		try {
			await authService.agreeTermsAndCondition(userType === 'publisher' ? 'publisher' : 'influencer');
			setIsTermsAndConditionsAgreed(false);
			setShowTermsAndConditionsModal(false);
			await updateUser(() => ReferralUrlService.redirect(navigate));
		} catch (e) {
			logoutService.panicLogout();
		}
	};

	useEffect(() => {
		emailRef && emailRef.current && emailRef.current.focus();
	}, [emailRef]);

	return (
		<>
			<Styled.FormCard>
				<Styled.FormWrapper>
					<div>
						<Styled.Heading>
							<div data-testid='login-title'>Log in</div>
						</Styled.Heading>
						{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
					</div>
					<Formik
						initialValues={{
							email: '',
							password: '',
						}}
						onSubmit={async ({ email, password }) => {
							clearWrongUsernamePasswordMessage();
							document.dispatchEvent(new Event('userLoggedInAction'));

							try {
								await authService.requestCollabsToken({ username: email, password });
								const result = await authService.me(authService.getGlobalUserIncludes());
								const user = models.sync(result) as Model;
								const isInfluencer = user.attributes.permissions.is_influencer;
								const userType = isInfluencer ? 'influencer' : 'publisher';

								if (isInfluencer) {
									const isConnected = user.influencers.filter((influencer: Model) => influencer.apiStatus === ApiStatus.CONNECTED).length > 0;
									const hasSelectedPaymentOption =
										user.type !== null && (user.type !== UserPaymentType.COMPANY || user.pinebucketStatus !== PinebucketStatus.NOT_CONNECTED);
									if (isConnected && hasSelectedPaymentOption && user.addressVerifiedAt) {
										storage.setItem('hadCompletedProfileOnLogin', 'true');
									} else {
										storage.setItem('hadCompletedProfileOnLogin', 'false');
									}
								}

								setUserType(userType);
								// Check if the user has accepted the TOC or not
								const hasAcceptedTOC = !!user.attributes[`${userType}TermsOfConditionsAcceptedAt`];
								setShowTermsAndConditionsModal(!hasAcceptedTOC);

								if (!hasAcceptedTOC) {
									return;
								}
								// dispatch setUser in redux
								dispatch(setUser(user));
								dispatch(setPermissions(user));

								if (userType === 'publisher') {
									const publisher = user.publisher;

									if (user.emptyName || (publisher.invoiceAddress === null && publisher.links.edit)) {
										window.location.href = '/incomplete-registration';
									}
								}

								if (user.attributes.permissions) {
									const userHasRoles: boolean = hasRoles(user);
									let url = '/not-connected';
									if (userHasRoles) {
										url = user.permissions.is_influencer ? '/influencer/dashboard' : '/dashboard';
									}
									ReferralUrlService.redirect(navigate, url);
								}
							} catch (e) {
								if (axios.isAxiosError(e)) {
									if (e.response?.status === 400) {
										setWrongUsernamePasswordMessage();
									} else {
										const errors = (e.response as AxiosResponse<ErrorResponse>).data.errors?.map(({ source }) => source.message) ?? [];
										setErrorMessage(errors.join(', '));
										authService.clearAll();
									}
								}
							}
						}}
						validationSchema={VALIDATION_SCHEMA}
						validateOnBlur={false}
						validateOnChange={false}
					>
						{({ isSubmitting, isValid, errors, touched }) => (
							<Form>
								<InputText
									label='Email'
									className={errors.email && touched.email ? 'error' : ''}
									innerRef={emailRef}
									id='email'
									autoComplete='username'
									name='email'
									type='email'
									placeholder=''
									required
								/>
								<InputText
									action={
										<Styled.IconWrapper onClick={() => setDisplayPassword(!displayPassword)}>
											<Icon name={!displayPassword ? 'hide' : 'unhide'} />
										</Styled.IconWrapper>
									}
									label='Password'
									className={errors.password && touched.password ? 'error' : ''}
									id='password'
									autoComplete='current-password'
									name='password'
									type={displayPassword ? 'text' : 'password'}
									required
								/>
								<PrimaryButton isLoading={isSubmitting} type='submit' disabled={isSubmitting || !isValid} data-testid='submit-btn'>
									Login
								</PrimaryButton>
							</Form>
						)}
					</Formik>
				</Styled.FormWrapper>
				<Styled.BottomLinks>
					<Link to={'/login/forgot'}>Forgot your password?</Link>
				</Styled.BottomLinks>
			</Styled.FormCard>
			<ConfirmModal
				isModalOpen={showTermsAndConditionsModal}
				title='Terms and conditions'
				toggleModal={closeTermsAndConditionModalHandler}
				action={agreeTermsAndConditionHandler}
				buttonText='Agree'
			>
				<>
					<div className='agree-checkbox'>
						<Checkbox
							checked={isTermsAndConditionsAgreed}
							onChange={() => {
								setIsTermsAndConditionsAgreed((prev) => !prev);
							}}
						/>
						<span>
							I have read and agreed to the&nbsp;
							<a href='https://www.collabs.se/terms-of-service' target='_blank' rel='noreferrer'>
								terms and conditions
							</a>
							&nbsp; and the&nbsp;
							<a href='https://www.collabs.se/privacy-policy' target='_blank' rel='noreferrer'>
								privacy policy
							</a>
							&nbsp; of the Collabs Agreement
						</span>
					</div>
				</>
			</ConfirmModal>
		</>
	);
};

export default LoginForm;
