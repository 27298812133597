import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useRef } from 'react';

import { BlueButton } from 'components/Button/Button';
import Icon from 'components/Icon';
import usePermissions from 'hooks/usePermissions';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { breakpoints } from 'styles/variables/media-queries';

import Styled from './Topbar.style';
import { SubmitSectionProps } from './types';

const SubmitSection = (props: SubmitSectionProps) => {
	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];
	const { isInfluencer } = usePermissions();

	const firstErrorRef = useRef<HTMLDivElement | null>(null);

	const displayErrors = () => {
		if (!props.errors) return null;

		return Object.entries(props.errors).map(([key, value], i) => {
			const message = typeof value === 'string' ? value : value[0]?.text ?? 'Missing required fields';

			const handleErrorFocus = () => {
				const element = document.querySelector(`[name="${key}"]`) || document.querySelector('.error');
				element?.focus({ focusVisible: true });
				element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
			};

			return (
				<Styled.ErrorMessage key={i} ref={i === 0 ? firstErrorRef : null} onClick={handleErrorFocus}>
					<Icon name='info-circle' size='12' />
					<span>{message}</span>
				</Styled.ErrorMessage>
			);
		});
	};

	useEffect(() => {
		if (firstErrorRef.current) {
			firstErrorRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
			firstErrorRef.current.focus();
		}
	}, [props.errors]);

	const renderFileInfo = () => {
		if (props.errors && Object.entries(props.errors).length > 0) return displayErrors();
		if (props.files) return `${props.files} file${props.files > 1 ? 's' : ''} will be submitted`;
		if (props.storyFrames) return `${props.storyFrames} frame${props.storyFrames > 1 ? 's' : ''} selected`;
		return '';
	};

	const createdAt = moment(props.statsCreatedAt);
	const daysLeft = 15 - moment().diff(createdAt, 'days');

	return isMobileView ? (
		<Styled.SubmitSection data-testid={props.testId}>
			<Styled.SubmitSectionContent>
				<div>
					<Styled.AssignmentName>{props.title}</Styled.AssignmentName>
					<Styled.FileText>{renderFileInfo()}</Styled.FileText>
				</div>
				{!props.isManualEditMode && (
					<>
						<BlueButton
							isLoading={props.isLoading}
							type='submit'
							data-testid='submit-button'
							onClick={props.submitAction}
							disabled={props.buttonDisabled}
							aria-disabled={props.buttonDisabled}
							aria-label={props.buttonDisabled ? 'Submit button disabled due to form errors or missing data' : 'Submit'}
							className={classNames({ turnBlue: props.isValid }, props.className)}
						>
							{props.buttonText}
						</BlueButton>
					</>
				)}
			</Styled.SubmitSectionContent>
		</Styled.SubmitSection>
	) : (
		<Styled.DFlexColumn className={props.onStatistics ? 'onStats' : ''} data-testid={props.testId}>
			<Styled.AssignmentName className={props.onStatistics ? 'onStats' : ''}>{props.assignmentName}</Styled.AssignmentName>
			{!props.isManualEditMode && (
				<BlueButton
					isLoading={props.isLoading}
					icon={props.buttonIcon}
					onClick={props.submitAction}
					data-testid='submit-button'
					type='submit'
					disabled={props.buttonDisabled}
					aria-disabled={props.buttonDisabled}
					aria-label={props.buttonDisabled ? 'Submit button disabled due to form errors or missing data' : 'Submit'}
				>
					{props.buttonText}
				</BlueButton>
			)}

			{!isInfluencer() && props.onStatistics && !props.assignmentApproved && (
				<Styled.AutoApproveText>
					<Styled.SpanLink onClick={() => props.goToSummary && props.goToSummary()}>Click here to approve this assignment for payment now. </Styled.SpanLink>
					{`If no action is taken, this assignment will be auto-approved ${daysLeft} days after stats are added unless declined.`}
				</Styled.AutoApproveText>
			)}
		</Styled.DFlexColumn>
	);
};

export default SubmitSection;
