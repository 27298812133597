import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Store } from 'json-api-models';
import { useEffect, useState, useRef, useCallback } from 'react';

import ImpersonateBar from 'components/ImpersonateBar';
import MainNavigation from 'components/MainNavigation/MainNavigation';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setNotifications } from 'reducers/UserReducers/UserNotificationsSlice';
import { getHomePath } from 'reducers/UserReducers/helpers';
import NotificationsService from 'services/Notifications/Notifications.service';
import { isImpersonating } from 'services/auth-service';

import NotificationsMenu from './Components/NotificationsMenu/NotificationsMenu';
import UserMenu from './Components/UserMenu/UserMenu';
import Styled from './Header.style';
import { IHeaderProps } from './types';

const POLL_INTERVAL = 45000;

/**
 * Header
 * @param {IHeaderProps} props
 * @returns {JSX.Element}
 */
const Header = ({ menuItems, location }: IHeaderProps): JSX.Element => {
	const [scroll, setScroll] = useState(false);
	const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);

	const dispatch = useAppDispatch();
	const notificationIntervalRef = useRef<NodeJS.Timeout | null>(null);

	const homePath = useAppSelector(getHomePath);

	const getTheNotifications = useCallback(async () => {
		setIsLoadingNotifications(true);
		try {
			const res = await NotificationsService.getNotifications();
			if (res?.data) {
				const models = new Store();
				models.sync(res.data);
				const notifications = models.findAll('notification');
				dispatch(setNotifications(notifications));
			}
		} catch (error) {
			console.error(`Notification poll: ${(error as AxiosError).message}`);
			if (notificationIntervalRef.current) {
				clearInterval(notificationIntervalRef.current);
			}
		} finally {
			setIsLoadingNotifications(false);
		}
	}, [dispatch]);

	useEffect(() => {
		getTheNotifications();
		notificationIntervalRef.current = setInterval(getTheNotifications, POLL_INTERVAL);

		return () => {
			if (notificationIntervalRef.current) {
				clearInterval(notificationIntervalRef.current);
			}
		};
	}, [getTheNotifications]);

	useEffect(() => {
		const handleScroll = () => setScroll(window.pageYOffset > 0);
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<Styled.Wrapper className={classNames('header-bar', { 'fixed-header': scroll })}>
			<Styled.HeaderTop>
				<Styled.Logotype to={homePath}>
					<span>Collabs</span>
				</Styled.Logotype>
				<Styled.Navigation data-testid='main-nav-icon'>
					<MainNavigation menuItems={menuItems} location={location} />
					{isImpersonating() && <ImpersonateBar />}
				</Styled.Navigation>
				<Styled.UserList>
					<Styled.UserListItem data-testid='header-notifications-icon'>
						<NotificationsMenu isLoading={isLoadingNotifications} />
					</Styled.UserListItem>
					<Styled.UserListItem className='margin-left' data-testid='header-profile-icon'>
						<UserMenu />
					</Styled.UserListItem>
					<Styled.UserListItem className='margin-left'>{/* <ScaleToggle /> */}</Styled.UserListItem>
				</Styled.UserList>
			</Styled.HeaderTop>
		</Styled.Wrapper>
	);
};

export default Header;
