import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/Icon';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const wrapperPadding = `${guttersWithRem.xs} ${guttersWithRem.s}`;

const Table = styled.table`
	width: 100%;
`;

const TableRow = styled.tr``;

const Th = styled.th<CSSProperties>`
	font-family: ${typography.list.accessory.fontFamilies};
	font-size: ${typography.list.medium.fontSize};
	font-weight: ${typography.list.accessory.fontWeight};
	text-align: ${(props) => props.textAlign || 'left'};
	padding: ${wrapperPadding};
	white-space: nowrap;
`;

const CheckboxTh = styled(Th)`
	position: relative;
	padding-left: 0.75rem;

	> div {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
`;

const borderStyle = css`
	background-image: linear-gradient(to right, ${colors.TableV2.borderDotsColor} 13%, rgba(136, 136, 136, 0) 0%);
	background-position: bottom;
	background-size: 8px 1px;
	background-repeat: repeat-x;
`;

const TableHeader = styled.thead`
	width: 100%;
	position: sticky;
	top: 0;
	background-color: ${newColors.mist} !important;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	z-index: 20; /* Keeps header above the body while scrolling */
	td,
	th {
		background-color: ${newColors.mist} !important;
	}
	&,
	tr {
		background-color: ${({ theme }) => theme.table.thHeadBackground} !important;
	}
`;

const TableBody = styled.tbody`
	overflow-y: auto;
	max-height: 500px;
	tr {
		${borderStyle};
		border: transparent;
	}
`;

const IconWrapper = styled.span`
	cursor: pointer;
	&:hover {
		border-radius: 2px;
		background-color: #e2e2e2;
	}
`;

const CustomIcon = styled(Icon)`
	line-height: 0;
`;

const CheckboxWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: -5px;
	.dropdown-button {
		padding: 0.125rem;
	}
`;

const FlexDiv = styled.div<CSSProperties>`
	display: flex;
	height: 24px;
	position: ${(props) => props.position};
	column-gap: ${(props) => props.columnGap};
	align-items: ${(props) => props.alignItems};
	top: ${(props) => props.top};
	left: ${(props) => props.left};
	right: ${(props) => props.right};
	bottom: ${(props) => props.bottom};
`;

const CheckboxInnerWapper = styled(FlexDiv)`
	padding: 0 5px;
	&:hover {
		border-radius: 2px;
		background-color: transparent;
	}
`;

const TableHeaderInnerWrapper = styled.div`
	display: inline-flex;
	align-items: center;

	&.followers {
		padding-left: 56px;
	}
`;

const SortIconWrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	row-gap: 0.1rem;

	& > div {
		&.arrow-up {
			width: 0;
			height: 0;
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
			border-bottom: 5px solid ${colors.discovery.gray};
		}

		&.arrow-down {
			width: 0;
			height: 0;
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
			border-top: 5px solid ${colors.discovery.gray};
		}

		&.isActive {
			border-color: ${colors.discovery.black};
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
		}
	}
`;

const LoadMoreWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	margin-top: 32px;
	padding-bottom: 10vh;
`;

const LoadingSpinnerWrapper = styled.div`
	margin-top: 40px;
`;

const EmptyResultWrapper = styled.div`
	max-width: 500px;
	margin: 0 auto;

	p strong {
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}
`;

const TableContainer = styled.div`
	height: 70vh;
	position: relative;
`;

const Styled = {
	TableContainer,
	TableRow,
	TableHeader,
	TableBody,
	Table,
	Th,
	IconWrapper,
	CustomIcon,
	CheckboxWrapper,
	FlexDiv,
	CheckboxInnerWapper,
	TableHeaderInnerWrapper,
	SortIconWrapper,
	LoadMoreWrapper,
	CheckboxTh,
	LoadingSpinnerWrapper,
	EmptyResultWrapper,
};

export default Styled;
