import { Model } from 'json-api-models';

import { CampaignInstagramOwnerModel, CampaignModel } from 'api-models';
import { CREATE_INSTAGRAM_POST, CREATE_INSTAGRAM_REEL, CREATE_INSTAGRAM_STORY, CREATE_TIKTOK } from 'constants/hateoas-keys';
import { AssignmentType } from 'shared/helpers/Assigment/types';
import IRepository from 'utils/Repository/IRepository';

type userCanFn = (action: string) => boolean;
export function getUploadStatisticsLink(userCan: userCanFn, links: Record<string, string>): string {
	for (const action of [CREATE_TIKTOK, CREATE_INSTAGRAM_STORY, CREATE_INSTAGRAM_REEL]) {
		if (userCan(action)) {
			return links[action];
		}
	}

	return links[CREATE_INSTAGRAM_POST];
}

export type StatisticsProps = {
	active: boolean;
	selectedCampaign: CampaignModel;
	selectedCIO?: CampaignInstagramOwnerModel;
	CIOAssignment: Model;
	review: Model;
	assignment: Model;
	assignmentType: AssignmentType;
	influencer: Model;
	refresh: () => Promise<void>;
	getUnsavedChanges: (unsavedChanges: boolean) => void;
	goToSummary: () => void;
	repository: IRepository;
};

export type StatisticsFormValues = {
	postedAt: string;
	url?: string;
	reach: number;
	reachLastFrame?: number;
	impressions: number;
	comments?: number;
	likes?: number;
	saves?: number;
	shares?: number;
	stickerLinkClicks?: number;
	stickerTaps?: number;
	otherInteractions?: number;
	screenshot?: string;
	frames?: string[];
};

export const defaultValues = {
	postedAt: '',
	impressions: 0,
	reach: 0,
	comments: 0,
	likes: 0,
	saves: 0,
	url: '',
	shares: 0,
	screenshot: '',
	frames: [],
};

export enum Scenario {
	STORY_SELECTOR = 'story-selector',
	MANUAL_UPLOAD_SAVE = 'manual-upload-save',
	MANUAL_UPLOAD_UPDATE = 'manual-upload-update',

	INFLUENCER_READS_FETCHED_STATS = 'influencer-reads-fetched-stats',
	MANAGER_READS_FETCHED_STATS = 'manager-reads-fetched-stats',

	INFLUENCER_NOT_ALLOWED = 'influencer-not-allowed',
	ASSIGNMENT_APPROVED = 'assignment-approved',

	READ_ONLY = 'read-only',
}

export const getButtonText = (scenario: Scenario | undefined) => {
	switch (scenario) {
		case Scenario.STORY_SELECTOR:
			return 'Confirm';
		case Scenario.INFLUENCER_READS_FETCHED_STATS:
		case Scenario.MANUAL_UPLOAD_SAVE:
			return 'Save';
		default:
			return 'Save';
	}
};

export const getByTestId = (scenario: Scenario | undefined) => {
	switch (scenario) {
		case Scenario.STORY_SELECTOR:
			return 'save-edit';
		case Scenario.INFLUENCER_READS_FETCHED_STATS:
		case Scenario.MANUAL_UPLOAD_SAVE:
			return 'cm-stats-save';
		default:
			return 'submit-section';
	}
};

export const getTitle = (scenario: Scenario | undefined) => {
	switch (scenario) {
		case Scenario.STORY_SELECTOR:
			return 'Select story';
		case Scenario.INFLUENCER_READS_FETCHED_STATS:
		case Scenario.MANUAL_UPLOAD_SAVE:
			return 'Save';
		default:
			return 'Save';
	}
};
