import classNames from 'classnames';
import { Formik, FormikProps, useFormikContext } from 'formik';
import { Model, Store } from 'json-api-models';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import { InfluencerModel } from 'api-models';
import SubmitSection from 'components/ContentManagement/Components/Topbar/SubmitSection';
import AddContentUrl from 'components/ContentManagement/Components/Views/Statistics/Components/AddContentUrl/AddContentUrl';
import AddScreenShots from 'components/ContentManagement/Components/Views/Statistics/Components/AddScreenShots/AddScreenShots';
import EmptyStateAutoText from 'components/ContentManagement/Components/Views/Statistics/Components/EmptyStateAutoStats/EmptyStateAutoStats';
import Heading from 'components/ContentManagement/Components/Views/Statistics/Components/Heading/Heading';
import InfluencerConnected from 'components/ContentManagement/Components/Views/Statistics/Components/InfluencerConnected/InfluencerConnected';
import InfluencerNotConnected from 'components/ContentManagement/Components/Views/Statistics/Components/InfluencerNotConnected/InfluencerNotConnected';
import ListScreenshots from 'components/ContentManagement/Components/Views/Statistics/Components/ListScreenshots/ListScreenshots';
import ReviewStatus from 'components/ContentManagement/Components/Views/Statistics/Components/ReviewStatus/ReviewStatus';
import SaveStatisticsModal from 'components/ContentManagement/Components/Views/Statistics/Components/SaveStatisticsModal/SaveStatisticsModal';
import Screenshots from 'components/ContentManagement/Components/Views/Statistics/Components/Screenshots';
import SelectDate from 'components/ContentManagement/Components/Views/Statistics/Components/SelectDate/SelectDate';
import StatisticFields from 'components/ContentManagement/Components/Views/Statistics/Components/StatisticFields/StatisticFields';
import StatisticsOverview from 'components/ContentManagement/Components/Views/Statistics/Components/StatisticsOverview/StatisticsOverview';
import StoryFrameList from 'components/ContentManagement/Components/Views/Statistics/Components/StoryFrameList/StoryFrameList';
import useContentManagementData, { StatisticsPayloadForSelectorType } from 'components/ContentManagement/hooks';
import { InstagramUserStory } from 'components/ContentManagement/types';
import LoadingSpinner from 'components/LoadingSpinner';
import { EDIT, CREATE_INSTAGRAM_POST, CREATE_INSTAGRAM_STORY, CREATE_INSTAGRAM_REEL, CREATE_TIKTOK } from 'constants/hateoas-keys';
import { ApiStatus } from 'constants/socialMedia';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnFetch, getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { useAppSelector } from 'hooks/useUserAppSelector';
import toast from 'services/Toast';
import { AssignmentStatus } from 'shared/Types/Assignment';
import { AssignmentType } from 'shared/helpers/Assigment/types';

import Styled from './Statistics.style';
import { validateStatsForm } from './Validations';
import { Scenario, StatisticsFormValues, StatisticsProps, defaultValues, getButtonText, getByTestId, getTitle, getUploadStatisticsLink } from './helpers';

/**
 * Statistics
 * Component to hold the stats form
 * @param {StatisticsProps} props
 * @returns {JSX.Element}
 */
const StatisticsForm = (props: StatisticsProps): JSX.Element => {
	const { postStatistics, patchEditStatistics, uploadStatisticsScreenshot, getStories, patchStorySelectorStatistics } = useContentManagementData();

	const [scenario, setScenario] = useState<Scenario | undefined>();
	const [files, setFiles] = useState<File[]>([]);
	const [isManualEditMode, setIsManualEditMode] = useState<boolean>(false);

	const [userStoryFrames, setUserStoryFrames] = useState<Array<InstagramUserStory>>([]);
	const [isUserStoriesLoading, setIsUserStoriesLoading] = useState(false);
	const [fetchedScreenShots, setFetchedScreenShots] = useState<Array<InstagramUserStory>>([]);
	const [framesConnectedToOtherAssignments, setFramesConnectedToOtherAssignments] = useState<Array<InstagramUserStory>>([]);
	const [selectedStoryItems, setSelectedStoryItems] = useState<Array<InstagramUserStory>>([]);

	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState(false);

	const [selectedDate, setSelectedDate] = useState<Date[]>();
	const [urlAdded, setUrlAdded] = useState(false);
	const [noResult, setNoResult] = useState(false);

	const [formValues, setFormValues] = useState<StatisticsFormValues>(defaultValues);
	const [LastInsightDataUpdateAt, setLastInsightDataUpdateAt] = useState<string>();
	const [isManagedByApi, setIsManagedByApi] = useState<boolean>(false);

	const CONTENT_TYPE = props.assignment.assignment.attributes.kind;
	const ASSIGNMENT_NETWORK = props.assignment.assignment.attributes.network;
	const STATUS = props.assignment.newFancyStatus;
	const IN_REVIEW = STATUS === AssignmentStatus.IN_REVIEW;
	const IS_STORY = CONTENT_TYPE === AssignmentType.INSTAGRAM_STORY;
	const IS_TIKTOK = CONTENT_TYPE === AssignmentType.TIKTOK_VIDEO;
	const ASSIGNENT_APPROVED = STATUS === AssignmentStatus.APPROVED;

	const instagramPosts = props.assignment.instagramPosts && props.assignment.instagramPosts[props.assignment.instagramPosts?.length - 1];
	const instagramStories = props.assignment.instagramStories && props.assignment.instagramStories[props.assignment.instagramStories?.length - 1];

	const statsEntity: Model | undefined = instagramPosts || instagramStories;
	const links = statsEntity ? { ...props.assignment.links, ...statsEntity.links } : { ...props.assignment.links };
	const user = useAppSelector((state) => state.user);
	const isInfluencer = user.permissions.isInfluencer;
	const { userCan } = useFeaturePermissions(links);

	const influencer = props.selectedCIO && (props.repository?.findOneByRelation(props.selectedCIO, 'influencer') as InfluencerModel);

	const INFLUENCER_NETWORK = influencer?.attributes?.network;
	const API_STATUS = influencer?.attributes?.apiStatus;

	// Check if the user is on the same network as the content type and if the user is connected to an social media API.
	const INFLUENCER_PROFILE_CONNECTED = API_STATUS === ApiStatus.CONNECTED && ASSIGNMENT_NETWORK === INFLUENCER_NETWORK;

	const MEDIA_UPLOAD_URL: string | undefined = links.uploadFile ?? links.uploadStatisticsScreenshot;
	const FETCH_STORIES_URL = influencer?.links?.instagramStories;
	const CAN_CREATE_STATS = userCan(CREATE_INSTAGRAM_POST) || userCan(CREATE_INSTAGRAM_STORY) || userCan(CREATE_INSTAGRAM_REEL) || userCan(CREATE_TIKTOK);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const unloadCallback = (event: any) => {
			event.preventDefault();
			event.returnValue = '';
			return '';
		};

		window.addEventListener('beforeunload', unloadCallback);

		handleScenario();

		return () => {
			setNoResult(false);

			window.removeEventListener('beforeunload', unloadCallback);
		};
	}, []);

	const renderCalendar = () => {
		switch (scenario) {
			case Scenario.READ_ONLY: {
				return false;
			}
			default:
				return true;
		}
	};

	const renderUrlInput = () => {
		switch (scenario) {
			case Scenario.MANUAL_UPLOAD_SAVE: {
				if (selectedDate && !IS_STORY) {
					return true;
				}
				break;
			}
			case Scenario.MANUAL_UPLOAD_UPDATE: {
				if (!IS_STORY) {
					return true;
				}
				break;
			}
			default:
				return false;
		}
	};

	const renderFrameSelector = () => {
		switch (scenario) {
			case Scenario.STORY_SELECTOR: {
				if (selectedDate) {
					return true;
				}
				break;
			}
			default:
				return false;
		}
	};

	const renderScreenshotsToUpload = () => {
		switch (scenario) {
			case Scenario.MANUAL_UPLOAD_SAVE: {
				if (!IS_STORY && urlAdded && selectedDate) return true;
				if (IS_STORY && selectedDate) return true;
				break;
			}
			case Scenario.MANUAL_UPLOAD_UPDATE: {
				return true;
			}
			default:
				return false;
		}
	};

	const renderInputFields = () => {
		if (statsEntity) {
			return true;
		} else {
			switch (scenario) {
				case Scenario.MANUAL_UPLOAD_SAVE: {
					if (IS_STORY && selectedDate && !isInfluencer) {
						return true;
					}
					if (IS_STORY && selectedDate && isInfluencer && files?.length > 0) {
						return true;
					}
					if (!IS_STORY && selectedDate && urlAdded && isInfluencer && files?.length > 0) {
						return true;
					}
					if (!IS_STORY && selectedDate && urlAdded && !isInfluencer) {
						return true;
					}
					break;
				}
				case Scenario.STORY_SELECTOR: {
					if (selectedStoryItems?.length > 0 && selectedDate) return true;
					break;
				}
				default:
					return false;
			}
		}
	};

	const renderApprovedMessage = () => {
		return isInfluencer && !statsEntity && ASSIGNENT_APPROVED;
	};

	const handleManualEdit = () => {
		let thisScenario;
		const CAN_UPDATE_STATS = links[EDIT];
		if (INFLUENCER_PROFILE_CONNECTED) {
			if (CAN_UPDATE_STATS) {
				thisScenario = Scenario.MANUAL_UPLOAD_UPDATE;
			}
		}

		setScenario(thisScenario);
		setIsManualEditMode(true);
	};

	const handleScenario = () => {
		let thisScenario;
		const HAS_UPLOADED_STATS = statsEntity;
		const CAN_UPDATE_STATS = links[EDIT];
		if (INFLUENCER_PROFILE_CONNECTED) {
			if (HAS_UPLOADED_STATS) {
				if (IS_STORY) {
					thisScenario = Scenario.STORY_SELECTOR;
				} else if (IS_TIKTOK) {
					thisScenario = CAN_UPDATE_STATS ? Scenario.MANUAL_UPLOAD_UPDATE : Scenario.READ_ONLY;
				} else {
					thisScenario = Scenario.READ_ONLY;
				}
			} else if (CAN_CREATE_STATS) {
				if (IS_STORY) {
					thisScenario = Scenario.STORY_SELECTOR;
				} else if (IS_TIKTOK) {
					thisScenario = Scenario.MANUAL_UPLOAD_SAVE;
				} else {
					thisScenario = Scenario.READ_ONLY;
				}
			} else {
				thisScenario = Scenario.READ_ONLY;
			}
		} else {
			if (HAS_UPLOADED_STATS) {
				thisScenario = CAN_UPDATE_STATS ? Scenario.MANUAL_UPLOAD_UPDATE : Scenario.READ_ONLY;
			} else {
				thisScenario = CAN_CREATE_STATS ? Scenario.MANUAL_UPLOAD_SAVE : Scenario.READ_ONLY;
			}
		}

		setScenario(thisScenario);
	};

	const mapFormValues = (): StatisticsFormValues | StatisticsPayloadForSelectorType => {
		const storySelectorValues = {
			stickerLinkClicks: Number.isInteger(formValues.stickerLinkClicks) ? formValues.stickerLinkClicks : 0,
			stickerTaps: Number.isInteger(formValues.stickerTaps) ? formValues.stickerTaps : 0,
			otherInteractions: Number.isInteger(formValues.otherInteractions) ? formValues.otherInteractions : 0,
			frames: selectedStoryItems.map((item) => item.id),
		};

		const statisticsValues = {
			postedAt: formValues.postedAt,
			url: formValues.url,
			reach: Number.isInteger(formValues.reach) ? formValues.reach : 0,
			reachLastFrame: Number.isInteger(formValues.reachLastFrame) ? formValues.reachLastFrame : 0,
			impressions: Number.isInteger(formValues.impressions) ? formValues.impressions : 0,
			comments: Number.isInteger(formValues.comments) ? formValues.comments : 0,
			likes: Number.isInteger(formValues.likes) ? formValues.likes : 0,
			saves: Number.isInteger(formValues.saves) ? formValues.saves : 0,
			shares: Number.isInteger(formValues.shares) ? formValues.shares : 0,
			screenshot: formValues.screenshot,
			stickerLinkClicks: Number.isInteger(formValues.stickerLinkClicks) ? formValues.stickerLinkClicks : 0,
			stickerTaps: Number.isInteger(formValues.stickerTaps) ? formValues.stickerTaps : 0,
			otherInteractions: Number.isInteger(formValues.otherInteractions) ? formValues.otherInteractions : 0,
		};

		return renderFrameSelector() ? storySelectorValues : statisticsValues;
	};

	const patchStats = () => {
		setIsSaveLoading(true);
		if (IS_STORY) {
			formValues.url = '';
		}
		if (renderFrameSelector()) {
			// This endpoint is a Patch but it creates a new Story object. Sum of reach and impressions and postedAt is saved in backend
			const createInstagramStoryEndpoint = props.assignment.links.edit;
			patchStorySelectorStatistics(createInstagramStoryEndpoint, mapFormValues() as StatisticsPayloadForSelectorType)
				.then(async () => {
					if (files && files.length > 0 && MEDIA_UPLOAD_URL) {
						await postMedia(MEDIA_UPLOAD_URL, files);
					} else {
						props.refresh().finally(() => {
							toast.success('The statistics updated successfully');
							setIsManualEditMode(false);
							setIsSaveLoading(false);
							setIsSaveModalOpen(false);
						});
					}
				})
				.catch((e) => {
					toast.error(getErrorMessageOnPost('updating the statistics'));
					console.error(e);
					setIsManualEditMode(false);
					setIsSaveLoading(false);
					setIsSaveModalOpen(false);
				});
		} else {
			patchEditStatistics(links[EDIT], mapFormValues() as StatisticsFormValues, CONTENT_TYPE)
				.then(async () => {
					if (files && files.length > 0 && MEDIA_UPLOAD_URL) {
						await postMedia(MEDIA_UPLOAD_URL, files);
					} else {
						props.refresh().finally(() => {
							toast.success('The statistics updated successfully');
							setIsManualEditMode(false);
							setIsSaveLoading(false);
							setIsSaveModalOpen(false);
						});
					}
				})
				.catch((e) => {
					toast.error(getErrorMessageOnPost('updating the statistics'));
					console.error(e);
					setIsManualEditMode(false);
					setIsSaveLoading(false);
					setIsSaveModalOpen(false);
				});
		}
	};

	const postStats = () => {
		setIsSaveLoading(true);
		postStatistics(getUploadStatisticsLink(userCan, links), formValues, CONTENT_TYPE)
			.then(async (data) => {
				const model = new Store();
				model.sync(data.data);
				const obj = model.findAll(IS_STORY ? 'instagramStory' : 'instagramPost')[0];
				const url = IS_STORY ? obj.links.uploadFile : obj.links.uploadStatisticsScreenshot;

				if (files && files.length > 0 && url) {
					await postMedia(url, files);
				} else {
					props.refresh().then(() => {
						toast.success('Statistics saved successfully');
						setIsManualEditMode(false);
						setIsSaveLoading(false);
						setIsSaveModalOpen(false);
						setFiles([]);
					});
				}
			})
			.catch((e) => {
				toast.error(getErrorMessageOnPost('saving the statistics'));
				console.error(e);
				setIsManualEditMode(false);
				setIsSaveModalOpen(false);
				setIsSaveLoading(false);
			});
	};

	const postMedia = (url: string, medias: Array<File>) => {
		return Promise.all(
			medias.map((media) => {
				uploadStatisticsScreenshot(url, media);
			}),
		)
			.then(() => {
				props.refresh().then(() => {
					toast.success('Statistics saved successfully');
					setIsManualEditMode(false);
					setIsSaveModalOpen(false);
					setIsSaveLoading(false);
					setFiles([]);
				});
			})
			.catch((e) => {
				toast.error(getErrorMessageOnFetch('Statistics'));
				console.error(e);
				setIsManualEditMode(false);
				setIsSaveModalOpen(false);
				setIsSaveLoading(false);
			});
	};

	const getInstagramStories = async () => {
		if (FETCH_STORIES_URL) {
			setIsUserStoriesLoading(true);
			setNoResult(false);

			const from = selectedDate && moment(selectedDate[0]).format('YYYY-MM-DD');
			const to = selectedDate && moment(selectedDate[1]).format('YYYY-MM-DD');

			if (from && to) {
				await getStories(FETCH_STORIES_URL, from, to)
					.then((response) => {
						const store = new Store();
						const fetchedStories = store.sync(response.data);

						const storiesManagedByApi: Model[] = [];
						const manualSavedStory: Model[] = [];

						// Never show manually uploaded stories as options in selector
						fetchedStories.map((story: Model) => {
							if (story.managedByApi === true) {
								storiesManagedByApi.push(story);
							} else {
								manualSavedStory.push(story);
							}
						});

						if (storiesManagedByApi?.length === 0 && INFLUENCER_PROFILE_CONNECTED) {
							setNoResult(true);
						}

						if (manualSavedStory?.length > 0 && userCan(EDIT)) {
							setScenario(Scenario.MANUAL_UPLOAD_UPDATE);
						}

						// Set frames connected to other stories as disabled
						const storiesConnectedToOtherAssignments = storiesManagedByApi?.filter(
							(story: Model) => story.campaignInstagramOwnerAssignment && story.campaignInstagramOwnerAssignment?.id !== props.CIOAssignment?.id,
						);
						const disableFrames = storiesConnectedToOtherAssignments.map((story: Model) => {
							if (story.frames?.length > 0) return story.frames;
						});

						setFramesConnectedToOtherAssignments(disableFrames.flat());

						const frames = store.findAll('instagramStoryFrame') as unknown as Array<InstagramUserStory>;
						setUserStoryFrames(frames);

						// Filter screenshots connected to this assignments
						const ThisStory = fetchedStories.filter((story: Model) => story.campaignInstagramOwnerAssignment?.id === props.CIOAssignment?.id);
						setFetchedScreenShots(ThisStory[0]?.files);

						// Pre select frames and stats if edit mode.
						if (statsEntity) {
							setSelectedStoryItems(statsEntity.frames);
							setFormValues({ ...formValues, frames: statsEntity.frames });
						}
						setIsUserStoriesLoading(false);
					})
					.finally(() => {
						setIsUserStoriesLoading(false);
					});
			}
		}
	};

	useEffect(() => {
		// Set form values if assignment has stats uploaded.
		if (statsEntity) {
			setFormValues({
				postedAt: moment(statsEntity.attributes.postedAt).format('yyyy-MM-DD') || '',
				impressions: statsEntity.attributes.impressions || 0,
				reach: statsEntity.attributes.reach || 0,
				comments: statsEntity.attributes.commentsCount || 0,
				likes: statsEntity.attributes.likeCount || 0,
				saves: statsEntity.attributes.saves || 0,
				url: statsEntity.attributes.url || '',
				shares: statsEntity.attributes.shares || 0,
				reachLastFrame: statsEntity.attributes.reachLastFrame || 0,
				stickerLinkClicks: statsEntity.attributes.stickerLinkClicks || 0,
				stickerTaps: statsEntity.attributes.stickerTaps || 0,
				otherInteractions: statsEntity.attributes.otherInteractions || 0,
				screenshot: statsEntity.links.statisticsScreenshot || '', // Note: This is just a check for the validation, this url is not used
			});
			const dateRange = [new Date(statsEntity.attributes.postedAt), new Date(statsEntity.attributes.postedAt)];
			setSelectedDate(dateRange);
			setLastInsightDataUpdateAt(statsEntity.attributes.insightDataUpdatedAt);
			setIsManagedByApi(statsEntity.attributes.managedByApi);
			if (statsEntity.attributes.url?.length > 0) setUrlAdded(true);

			if (!IS_STORY) {
				setFetchedScreenShots(props.assignment.instagramPosts[0].screenshots);
			}
		} else {
			setFormValues({
				postedAt: '',
				impressions: 0,
				reach: 0,
				comments: 0,
				likes: 0,
				saves: 0,
				url: '',
				shares: 0,
				reachLastFrame: 0,
				stickerLinkClicks: 0,
				stickerTaps: 0,
				otherInteractions: 0,
				screenshot: '',
			});
		}
		handleScenario();
	}, [props.assignment]);

	useEffect(() => {
		setSelectedStoryItems([]);
		if (selectedDate !== null && IS_STORY) {
			getInstagramStories();
		}
	}, [selectedDate]);

	if (!props.active) {
		return <></>;
	}

	const formRef = useRef<FormikProps<StatisticsFormValues>>(null);
	const FormObserver: React.FC = () => {
		const { dirty } = useFormikContext();

		useEffect(() => {
			if (dirty) {
				setUnsavedChanges(true);
			} else {
				setUnsavedChanges(false);
			}
		}, [dirty]);

		return null;
	};

	useEffect(() => {
		props.getUnsavedChanges(unsavedChanges);
	}, [unsavedChanges]);

	useEffect(() => {
		setNoResult(false);
	}, [scenario]);

	return (
		<Styled.Wrapper data-testid='statistics-form'>
			<Formik
				innerRef={formRef}
				enableReinitialize
				initialValues={formValues}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={validateStatsForm(CONTENT_TYPE, false, false)}
				onSubmit={(values) => {
					setFormValues(values);
					setIsSaveModalOpen(true);
				}}
			>
				{({ handleSubmit, setFieldValue, errors, setErrors, values, resetForm }) => {
					const handleCancelManualEdit = () => {
						handleScenario();
						resetForm();
						setIsManualEditMode(false);
					};
					return (
						<>
							<FormObserver />
							{<Heading links={links} postedAt={statsEntity?.postedAt} />}
							{IN_REVIEW && <ReviewStatus />}
							{INFLUENCER_PROFILE_CONNECTED ? <InfluencerConnected /> : !IS_TIKTOK && isInfluencer && !renderApprovedMessage() && <InfluencerNotConnected />}
							{scenario === Scenario.READ_ONLY && !statsEntity && CAN_CREATE_STATS && <EmptyStateAutoText setScenario={setScenario} />}
							{scenario === undefined ? (
								<LoadingSpinner />
							) : (
								<>
									{renderApprovedMessage() && (
										<Styled.ApprovedWithNoStatsWrapper>
											<p className='text-center'>
												Assignment approved!
												<br /> No statistics needed now, well done! 👏
											</p>
										</Styled.ApprovedWithNoStatsWrapper>
									)}
									{!isInfluencer &&
										links[EDIT] &&
										statsEntity &&
										(isManualEditMode || scenario === Scenario.READ_ONLY || scenario === Scenario.STORY_SELECTOR) && (
											<Styled.ManualEditButtonWrapper>
												<Styled.ManualEditButton
													className={classNames('edit')}
													editMode={isManualEditMode}
													onClick={handleManualEdit}
													disabled={isManualEditMode}
												>
													Edit
												</Styled.ManualEditButton>
												<Styled.ManualEditButton className={classNames('cancel')} editMode={isManualEditMode} onClick={handleCancelManualEdit}>
													Cancel
												</Styled.ManualEditButton>
												<Styled.ManualEditButton
													className={classNames('done')}
													editMode={isManualEditMode}
													onClick={() => {
														handleSubmit();
													}}
												>
													Done
												</Styled.ManualEditButton>
											</Styled.ManualEditButtonWrapper>
										)}

									{renderCalendar() && (
										<SelectDate
											campaignStartDate={props.selectedCampaign.attributes.firstAssignmentGroupDate || ''}
											scenario={scenario}
											selectedDate={selectedDate}
											profileConnected={INFLUENCER_PROFILE_CONNECTED}
											setSelectedDate={setSelectedDate}
											setFieldValue={setFieldValue}
											isStory={IS_STORY}
										/>
									)}
									<Styled.Form>
										{renderUrlInput() && (
											<AddContentUrl
												instagramLinkCheck={props.assignment.influencer.links.testInstagramPostInsightAccess}
												setUrlAdded={setUrlAdded}
												profileConnected={INFLUENCER_PROFILE_CONNECTED}
												contentType={CONTENT_TYPE}
												values={values}
												onInput={() => {
													const { url: _, ...err } = errors;
													setErrors(err);
												}}
											/>
										)}
										{renderFrameSelector() && (
											<StoryFrameList
												noResult={noResult}
												scenario={scenario}
												frames={userStoryFrames}
												framesConnectedToOtherAssignments={framesConnectedToOtherAssignments}
												selectedStoryItems={selectedStoryItems}
												setSelectedStoryItems={setSelectedStoryItems}
												isLoading={isUserStoriesLoading}
												setFormValues={setFormValues}
												values={values}
												setScenario={setScenario}
											/>
										)}
										{renderScreenshotsToUpload() && (
											<Screenshots onFrameSelector={renderFrameSelector()} isInfluencer={isInfluencer} contentType={CONTENT_TYPE}>
												<>
													<ListScreenshots
														assignmentApproved={ASSIGNENT_APPROVED}
														refresh={props.refresh}
														files={files}
														fetchedScreenShots={fetchedScreenShots}
														setFiles={setFiles}
													/>
													<AddScreenShots
														url={
															IS_STORY
																? props.assignment?.instagramStories[0]?.links.uploadFile // NEED TO CHANGES THIS
																: props.assignment?.instagramPosts[0]?.links.uploadStatisticsScreenshot
														}
														setFiles={setFiles}
														files={files}
														refresh={props.refresh}
														setFieldValue={setFieldValue}
													/>
												</>
											</Screenshots>
										)}

										{scenario === Scenario.READ_ONLY && statsEntity ? (
											<StatisticsOverview
												LastInsightDataUpdateAt={LastInsightDataUpdateAt}
												values={values}
												contentType={CONTENT_TYPE}
												frames={selectedStoryItems}
												screenshots={fetchedScreenShots}
												isManagedByApi={isManagedByApi}
											/>
										) : (
											renderInputFields() && (
												<>
													<StatisticFields scenario={scenario} values={values} contentType={CONTENT_TYPE} profileConnected={INFLUENCER_PROFILE_CONNECTED} />
													<SubmitSection
														onStatistics
														statsCreatedAt={statsEntity?.attributes.createdAt}
														scenario={scenario}
														assignmentName={props.CIOAssignment.assignment.attributes.name}
														files={files?.length}
														storyFrames={selectedStoryItems?.length}
														submitAction={handleSubmit}
														isLoading={isSaveLoading}
														errors={errors}
														buttonText={getButtonText(scenario)}
														testId={getByTestId(scenario)}
														className='fit-content'
														title={getTitle(scenario)}
														isManualEditMode={isManualEditMode}
														goToSummary={props.goToSummary}
														assignmentApproved={ASSIGNENT_APPROVED}
													/>
												</>
											)
										)}
									</Styled.Form>
								</>
							)}
						</>
					);
				}}
			</Formik>
			<SaveStatisticsModal
				isManagedByApi={isManagedByApi}
				isManualEditMode={isManualEditMode}
				statsEntity={statsEntity !== undefined}
				isOpen={isSaveModalOpen}
				setIsModalOpen={setIsSaveModalOpen}
				isLoading={isSaveLoading}
				onClickSave={statsEntity || (IS_STORY && scenario === Scenario.STORY_SELECTOR) ? patchStats : postStats}
			/>
		</Styled.Wrapper>
	);
};

export default StatisticsForm;
