import { CampaignSection, InvitationSection } from './Components';
import Styled from './InfluencerDashboardContainer.style';

/**
 * Influencer dashboard
 * @returns {JSX.Element}
 */
const InfluencerDashboardContainer = (): JSX.Element => {
	return (
		<Styled.Wrapper>
			<InvitationSection />
			<CampaignSection />
		</Styled.Wrapper>
	);
};

export default InfluencerDashboardContainer;
