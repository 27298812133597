import { AssignmentFormProps } from 'components/ContentManagement/Components/Views/Assignment/types';
import Post from 'components/ContentManagement/Components/Views/Post';
import Story from 'components/ContentManagement/Components/Views/Story';
import { CIOAType, ReviewType } from 'components/ContentManagement/types';
import { getLatestReview } from 'shared/utils/getLatestReview';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';

const AssignmentForm = ({ assignmentType, CIOAssignment, selectedCampaign, CIOArefresh, goToStats, campaignRefresh }: AssignmentFormProps) => {
	const review = getLatestReview(CIOAssignment.reviews);

	const selectedCampaignData = {
		hashtags: selectedCampaign?.attributes?.hashtags ?? [],
		mentions: selectedCampaign?.attributes?.mentions ?? [],
	};

	const renderStoryForm = (
		<Story
			id={CIOAssignment.id}
			selectedCampaign={selectedCampaignData}
			selectedCIOA={CIOAssignment as unknown as CIOAType}
			CIOArefresh={CIOArefresh}
			campaignRefresh={campaignRefresh}
			review={review as unknown as ReviewType}
			goToStats={goToStats}
		/>
	);

	const renderPostForm = (
		<Post
			id={CIOAssignment.id}
			selectedCampaign={selectedCampaignData}
			selectedCIOA={CIOAssignment as unknown as CIOAType}
			CIOArefresh={CIOArefresh}
			review={review as unknown as ReviewType}
			goToStats={goToStats}
			campaignRefresh={campaignRefresh}
			assignmentType={assignmentType}
		/>
	);

	return assignmentType === ASSIGNMENT_TYPE.INSTAGRAM_STORY ? renderStoryForm : renderPostForm;
};

export default AssignmentForm;
