import { FormikErrors } from 'formik/dist/types';
import moment from 'moment';
import { useState } from 'react';

import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { Scenario } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Grid from 'styles/grid/grid';

type SelectDateProps = {
	selectedDate?: [Date, Date];
	setSelectedDate: (date?: [Date, Date]) => void;
	profileConnected: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>;
	scenario: Scenario | undefined;
	disabled?: boolean;
	campaignStartDate: string;
	isStory?: boolean;
};

const SelectDate = ({
	setSelectedDate,
	profileConnected,
	setFieldValue,
	selectedDate,
	scenario,
	disabled,
	campaignStartDate,
	isStory,
}: SelectDateProps): JSX.Element => {
	const [startDate, setStartDate] = useState<string>(selectedDate ? moment(selectedDate[0]).format('YYYY-MM-DD') : '');
	const [endDate, setEndDate] = useState<string>(selectedDate ? moment(selectedDate[1]).format('YYYY-MM-DD') : '');

	const renderHelpText = () => {
		switch (scenario) {
			case Scenario.MANUAL_UPLOAD_UPDATE:
			case Scenario.MANUAL_UPLOAD_SAVE: {
				return isStory ? 'Add the date or date range of posting.' : 'Add the date of posting.';
			}
			case Scenario.STORY_SELECTOR: {
				return 'Find and select your Story frames by adding the date or date range of posting.';
			}
			default:
				return '';
		}
	};

	const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const date = e.target.value;
		setStartDate(date);
		setSelectedDate([new Date(date), endDate ? new Date(endDate) : new Date()]);
		setFieldValue('postedAt', date);
	};

	const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const date = e.target.value;
		setEndDate(date);
		setSelectedDate([new Date(startDate), new Date(date)]);
	};

	return (
		<Styled.DateSection>
			{!disabled && (
				<Styled.DateTextWrapper>
					<Styled.Heading>
						<h5>{renderHelpText()}</h5>
					</Styled.Heading>
				</Styled.DateTextWrapper>
			)}
			<Grid.Container>
				<Grid.Column lg={5} md={6} sm={12}>
					<Field label={profileConnected ? 'Start Date' : 'Date posted'} disabled={disabled}>
						<Input
							type='date'
							disabled={disabled}
							value={startDate}
							onChange={handleStartDateChange}
							min={moment(campaignStartDate).format('YYYY-MM-DD')}
							max={moment().format('YYYY-MM-DD')}
						/>
					</Field>
				</Grid.Column>
				{isStory && (
					<Grid.Column lg={5} md={6} sm={12}>
						<Field label='End Date' disabled={disabled}>
							<Input
								type='date'
								disabled={disabled}
								value={endDate}
								onChange={handleEndDateChange}
								min={startDate || moment(campaignStartDate).format('YYYY-MM-DD')}
								max={moment().format('YYYY-MM-DD')}
							/>
						</Field>
					</Grid.Column>
				)}
			</Grid.Container>
			<hr />
		</Styled.DateSection>
	);
};

export default SelectDate;
