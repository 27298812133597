import EmptyContent from 'assets/img/app/empty-state-dead-invitation.svg';
import LinkAsSecondaryButton from 'components/LinkAsSecondaryButton';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './NewBriefPage.style';

/**
 * Invitation link that has expired/been used
 * @returns {JSX.Element}
 */
const LinkAlreadyUsed = (): JSX.Element => {
	const user = useAppSelector((state) => state.user);

	return (
		<Styled.EmptyStateWrapper>
			<Styled.LinkAlreadyUsedWrapper>
				<Styled.AlignCenter>
					<Styled.TextContainer>
						<Styled.Title>This invitation is no longer alive</Styled.Title>
						<Styled.Text>
							It seems the invitation link is no longer active. This could be due to the campaign invitation expiring or the campaign reaching its end.{' '}
						</Styled.Text>
						<Styled.Text>Thank you for your interest, please explore other available opportunities.</Styled.Text>
						{user?.id ? (
							<LinkAsSecondaryButton to='/influencer/dashboard'>Go to my dashboard</LinkAsSecondaryButton>
						) : (
							<LinkAsSecondaryButton to='/login'>Login</LinkAsSecondaryButton>
						)}
						<Styled.Text>
							If you have any questions or need further assistance, please don&apos;t hesitate to contact our{' '}
							<a href={'https://help.collabs.se/hc/en-us/sections/12575769923345-Campaign-Management'}>support pages</a>.
						</Styled.Text>
					</Styled.TextContainer>
					<Styled.ImgContainer>
						<Styled.EmptyStateImage src={EmptyContent} alt='ghost' />
					</Styled.ImgContainer>
				</Styled.AlignCenter>
			</Styled.LinkAlreadyUsedWrapper>
		</Styled.EmptyStateWrapper>
	);
};

export default LinkAlreadyUsed;
