import DOMPurify from 'dompurify';
import _ from 'lodash';

import Copy from 'components/Copy/Copy';
import { DetailItem } from 'components/CreateCampaign/types';
import EmptyStateAssignments from 'components/NewBriefPage/Components/EmptyStateAssignments/EmptyStateAssignments';
import { Assignment } from 'components/NewBriefPage/types';
import { assignmentTypeToIcon, assignmentTypeToText } from 'shared/helpers/Assigment/Assignment';
import { apiToMoment } from 'utils';
import { newLinesToParagraphs } from 'utils/formatters';

import Styled from './CampaignAssignments.style';

type CampaignAssignmentsProps = {
	id: string;
	assignments: Assignment[];
	campaignId: string;
	onPreview: boolean;
	canEdit: boolean;
};
/**
 * @returns JSX.Element
 */
const CampaignAssignments = ({ id, campaignId, assignments, onPreview, canEdit }: CampaignAssignmentsProps): JSX.Element => {
	const formatDate = (dateValue: string) => {
		const targetDate = apiToMoment(dateValue);
		return targetDate.format('D MMM');
	};

	return (
		<Styled.Wrapper id={id}>
			<Styled.HeaderText>Assignments</Styled.HeaderText>
			<Styled.ContentWrapper>
				<Styled.AssignmentCards>
					{assignments.length > 0
						? _.sortBy(assignments, ['startTime'])?.map((assignment: Assignment) => {
								const hashtags = assignment?.hashtags?.map((h) => (h.indexOf('#') !== -1 ? h : `#${h}`)) ?? [];
								const mentions = assignment?.mentions?.map((h) => (h.indexOf('@') !== -1 ? h : `@${h}`)) ?? [];

								return (
									<Styled.AssignmentCard key={assignment.id} id={`${assignment.id}`}>
										<div className='header'>
											<span>
												{assignmentTypeToIcon(assignment.type)}
												<div className='assignment-type'>{assignmentTypeToText(assignment.type)}</div>
											</span>
											<Styled.Dates>
												<Styled.CustomIcon name='calendar' />
												{`${formatDate(assignment.startTime)} - ${formatDate(assignment.endTime)}`}
											</Styled.Dates>
										</div>
										<div className='title'>{assignment.name}</div>
										{assignment.details ? (
											<>
												{assignment.details.map((detail: DetailItem) => {
													return (
														<Styled.DetailItem key={detail.title}>
															<Styled.DetailTitle>{detail.title}</Styled.DetailTitle>
															<Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.content) }} />
														</Styled.DetailItem>
													);
												})}
											</>
										) : (
											<Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assignment.description) }} />
										)}

										{!assignment.details || hashtags?.length > 0 || mentions.length > 0 || assignment.campaignCode || assignment.deadlines.length > 0 ? (
											<div className='details'>
												<Styled.CustomAccordion className='brief-assignment' hideChevron title='Read the full assignment details'>
													<Styled.AccordionContent>
														{!assignment.details && (
															<>
																{assignment.dos && (
																	<Styled.DetailItem>
																		<Styled.DetailTitle>Do&apos;s</Styled.DetailTitle>
																		<Styled.DetailText dangerouslySetInnerHTML={{ __html: newLinesToParagraphs(assignment.dos ? assignment.dos : '') }} />
																	</Styled.DetailItem>
																)}
																{assignment.donts && (
																	<Styled.DetailItem>
																		<Styled.DetailTitle>Don&apos;ts</Styled.DetailTitle>
																		<Styled.DetailText dangerouslySetInnerHTML={{ __html: newLinesToParagraphs(assignment.donts ? assignment.donts : '') }} />
																	</Styled.DetailItem>
																)}
																{assignment.cta && (
																	<Styled.DetailItem>
																		<Styled.DetailTitle>CTA</Styled.DetailTitle>
																		<Styled.DetailText dangerouslySetInnerHTML={{ __html: newLinesToParagraphs(assignment.cta ? assignment.cta : '') }} />
																	</Styled.DetailItem>
																)}
															</>
														)}
														{hashtags?.length > 0 ? (
															<div className='detail'>
																<div className='title'>Hashtags</div>
																<div className='content'>
																	<div className='copy'>
																		{hashtags.map((hashtag: string, index: number) => (
																			<span key={`${hashtag}-${index}`}>{hashtag}</span>
																		))}
																		<Copy description='hashtags' value={hashtags.join(' ')} />
																	</div>
																</div>
															</div>
														) : null}
														{mentions.length > 0 ? (
															<div className='detail'>
																<div className='title'>Mentions</div>
																<div className='content'>
																	<div className='copy'>
																		{mentions.map((mention: string, index: number) => {
																			return <span key={`${mention}-${index}`}>{mention}</span>;
																		})}

																		<Copy description='mentions' value={mentions.join(' ')} />
																	</div>
																</div>
															</div>
														) : null}
														{assignment.deadlines.length > 0 && (
															<div className='detail'>
																{assignment.deadlines.map((deadline: { id: string; date: string; name: string }, index: number) => {
																	return (
																		<Styled.DeadlineItem key={deadline.id}>
																			<div>
																				<div className='title'>{`Deadline ${index + 1}`}</div>
																				<div className='date'>
																					<span>{formatDate(deadline.date)}</span>
																				</div>
																			</div>
																			<div>{deadline.name}</div>
																		</Styled.DeadlineItem>
																	);
																})}
															</div>
														)}
														{assignment.campaignCode && (
															<Styled.CustomAccordion className='brief-assignment' toggleIconPosition='left' title='Campaign code' open>
																<Styled.AccordionContent>
																	<div className='detail'>{assignment.campaignCode}</div>
																</Styled.AccordionContent>
															</Styled.CustomAccordion>
														)}
													</Styled.AccordionContent>
												</Styled.CustomAccordion>
											</div>
										) : null}
									</Styled.AssignmentCard>
								);
							})
						: onPreview && canEdit && <EmptyStateAssignments campaignId={campaignId} />}
				</Styled.AssignmentCards>
			</Styled.ContentWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignAssignments;
