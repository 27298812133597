import _ from 'lodash';

import Icon from 'components/Icon';
import { Assignment, Product } from 'components/NewBriefPage/types';
import { assignmentTypeToIcon } from 'shared/helpers/Assigment/Assignment';
import { formatAmount } from 'utils/formatters';

import Styled from './CampaignCompensation.style';
import { CampaignCompensationProps, ProductItem } from './types';

/**
 * CampaignCompensation
 * @returns {JSX.Element}
 */
const CampaignCompensation = ({ id, campaignInstagramOwner, campaign }: CampaignCompensationProps) => {
	const hasCompensation = campaignInstagramOwner.campaign?.invoices?.length > 0 || campaignInstagramOwner.campaign?.products?.length > 0;

	const totalValues: Array<{ value: number; currency: string }> = [];
	const productsByCurrency = _.groupBy(campaignInstagramOwner.campaign?.products, 'product.value.currency');
	Object.keys(productsByCurrency).forEach(function (key) {
		const totalValue = productsByCurrency[key].reduce((prev: number, current: Product) => prev + parseInt(`${current.product.value.amount}`), 0);
		const currency = key;
		totalValues.push({ value: totalValue, currency: currency });
	});

	if (!hasCompensation) {
		return null;
	}

	const scrollToId = (id: string) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	};

	return (
		<Styled.Wrapper id={id}>
			<Styled.HeaderText>Summary</Styled.HeaderText>
			<Styled.ContentWrapper>
				<Styled.CommissionCards data-testid='compensation-card'>
					{campaignInstagramOwner.campaign?.invoices?.length > 0 &&
						campaignInstagramOwner.campaign.invoices.map((invoice: { id: string; invoice: { value: number; currency: string } }) => {
							return (
								<Styled.CommissionCard key={invoice.id}>
									{campaign?.affiliate ? (
										<Styled.AffiliateCommissionMessage>It is based on your performance.</Styled.AffiliateCommissionMessage>
									) : (
										<div className='value'>
											<div>
												<h5>Total commission:</h5>
												{invoice.invoice?.value && formatAmount(invoice.invoice.value, invoice.invoice.currency)?.replace(',', '\xa0').replace('.00', '')}
											</div>
											<div className='tax-info'>VAT not included (25%)</div>
										</div>
									)}
									<div className='invoice-info'>
										<hr />
										{campaign.affiliate ? (
											<div className='extra-info'>See payment conditions in the Terms & Conditions section above.</div>
										) : (
											<div className='extra-info'>
												Payment is made via invoice <strong>45 days</strong> after you complete the campaign.
											</div>
										)}
										<hr />
									</div>
								</Styled.CommissionCard>
							);
						})}
					{campaignInstagramOwner?.campaign?.assignments.length > 0 && (
						<>
							<Styled.AssignmentsWrapper>
								<h5>Number of assignments: {campaignInstagramOwner?.campaign?.assignments.length} </h5>
								{_.sortBy(campaignInstagramOwner?.campaign?.assignments, ['startTime'])?.map((assignment: Assignment) => {
									return (
										<Styled.Assignment key={assignment.id} onClick={() => scrollToId(assignment.id.toString())}>
											<div className='header'>
												{assignmentTypeToIcon(assignment.type)}
												<div className='title'>{assignment.name}</div>
											</div>
										</Styled.Assignment>
									);
								})}
							</Styled.AssignmentsWrapper>
						</>
					)}
					{campaignInstagramOwner.campaign?.products?.length > 0 ? (
						<Styled.AssignmentsWrapper>
							<Styled.Hr />
							{totalValues.map((value, index) => {
								return value.value > 0 && <h5 key={index}>Product value : {formatAmount(value.value, value.currency)}</h5>;
							})}
							{campaignInstagramOwner.campaign.products.map((product: ProductItem) => {
								return (
									<Styled.Assignment key={product.id} onClick={() => scrollToId(`product-${product.id}`)}>
										<div className='header'>
											<Icon name='product' />
											<div className='title'>{product.product.name}</div>
										</div>
									</Styled.Assignment>
								);
							})}
						</Styled.AssignmentsWrapper>
					) : null}
				</Styled.CommissionCards>
			</Styled.ContentWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignCompensation;
