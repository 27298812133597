import { AxiosError } from 'axios';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { ViewDashboardQuery } from 'api-queries';
import { PrimaryButton } from 'components/Button';
import ContentBodyPublicDashboard from 'components/DataLibrary/ContentBody/ContentBodyPublicDashboard';
import convertDashboardModelToDashboardType from 'components/DataLibrary/ConvertDashboardModelToDashboardType';
import LinkAsSecondaryButton from 'components/LinkAsSecondaryButton';
import LoadingSpinner from 'components/LoadingSpinner';
import PageHeader from 'components/PageHeader';
import useInjection from 'hooks/useInjection';
import DashboardApiClientInterface from 'services/ApiClient/DashboardApiClientInterface';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import toast from 'services/Toast';
import Grid from 'styles/grid/grid';
import ResourceManager from 'utils/Repository/ResourceManager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import DLStyled from 'views/DataLibrary/DataLibrary.style';
import { setDashboardFilter } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';
import { store } from 'views/DataLibrary/store';

import Styled from './PublicDashboard.style';

/**
 * This component is the Dashboard Preview where Managers can copy the link to send it to users outside of the Collabs platform.
 *
 * @returns {JSX.Element}
 */
const Dashboard = (): JSX.Element => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [preview, setPreview] = useState<DashboardType | undefined>(undefined); // Called preview to avoid mix with dashboard in redux state
	const [link, setLink] = useState<string | null>(null);

	const { dashboardId, token } = useParams();
	const { pathname } = useLocation();
	const isPublic = token && token?.length > 0;
	const URL = process.env.VITE_APP_COLLABS_BASE_URL;
	const apiClient = useInjection<DashboardApiClientInterface>(ApiClientService.DashboardApiClientInterface);

	const getOneDashboard = async (id: string): Promise<DashboardType> => {
		const qb = RequestQueryBuilder.create<ViewDashboardQuery>().withInclude('dashboardUsers').withInclude('paidMedia');

		const response = await apiClient.view(id, qb.toQuery());
		const repository = new ResourceManager();
		repository.mergeWith(response);
		const { dashboard } = convertDashboardModelToDashboardType(repository, response.data);

		return dashboard;
	};

	const getDashboardWithDLToken = () => {
		dashboardId &&
			getOneDashboard(dashboardId)
				.then((res) => {
					store.dispatch(setDashboard(res));
					if (res.metaData.dashboardFilter) {
						store.dispatch(setDashboardFilter(res.metaData.dashboardFilter));
					}
					setPreview(res);
				})
				.catch((error: AxiosError) => {
					toast.error(`${error.message}`);
				})
				.finally(() => {
					setIsLoading(false);
				});
	};

	const getDashboardWithAuthToken = () => {
		dashboardId &&
			getOneDashboard(dashboardId)
				.then((res) => {
					store.dispatch(setDashboard(res));
					if (res.metaData.dashboardFilter) {
						store.dispatch(setDashboardFilter(res.metaData.dashboardFilter));
					}
					setPreview(res);

					const sharedLinks = res.dashboardUsers ?? [];

					if (sharedLinks?.length > 0) {
						const linksByDate = _.orderBy(sharedLinks, 'attributes.createdAt', 'desc');
						const token = linksByDate[0]?.attributes?.token;
						authService.setDataLibraryTokenInSession(token);
						const dataLibraryTokenFromStorage = authService.getDataLibraryToken();
						if (dataLibraryTokenFromStorage) {
							getDashboardWithDLToken();
							setLink(`${URL}/data-library/dashboard/${dashboardId}/invite/${token}`);
						}
					}
				})
				.catch((error: AxiosError) => {
					toast.error(`${error.message}`);
				})
				.finally(() => {
					setIsLoading(false);
				});
	};

	useEffect(() => {
		if (token) {
			// Public dashboard url
			authService.setDataLibraryTokenInSession(token);
			const dataLibraryTokenFromStorage = authService.getDataLibraryToken();
			dataLibraryTokenFromStorage && getDashboardWithDLToken();
		} else {
			getDashboardWithAuthToken();
		}
		return () => {
			authService.removeDataLibraryToken();
		};
	}, []);

	const getLinkToShare = () => {
		return link ?? authService.getLink();
	};

	const copy = () => {
		if (preview) {
			navigator.clipboard.writeText(`${getLinkToShare()}`);
		}
		toast.success(`Copied. Get ready to paste =)`);
	};

	useEffect(() => {
		link && authService.setLinkInInSession(link);
	}, [link]);

	return (
		<Provider store={store}>
			<DLStyled.Wrapper>
				{isLoading ? (
					<DLStyled.SpinnerWrapper>
						<LoadingSpinner />
					</DLStyled.SpinnerWrapper>
				) : (
					<PageHeader headline={preview && preview?.name} showBreadcrumb={false} />
				)}
				<DLStyled.Content>
					<Grid.Container>
						<Styled.StyledColumn lg={12}>
							<Styled.DashboardContainer>
								<Styled.DashboardNav />
								{pathname.includes('preview') && (
									<Styled.ButtonContainer>
										<LinkAsSecondaryButton to='/data-library'>Back to Data Library</LinkAsSecondaryButton>
									</Styled.ButtonContainer>
								)}
								<Styled.ContentContainer>
									{preview && !isPublic && (
										<Styled.Invitation>
											<Grid.Container>
												<Grid.Column xl={4} md={12} sm={12}>
													<Styled.InvitationTitle>Share dashboard</Styled.InvitationTitle>
													<div>Anyone with the link can view.</div>
												</Grid.Column>
												<Grid.Column xl={5} md={6} sm={12}>
													<Styled.LinkTitle>Link:</Styled.LinkTitle>
													<div>{getLinkToShare()}</div>
												</Grid.Column>
												<Grid.Column xl={3} md={6} sm={12}>
													<PrimaryButton icon='copy' onClick={() => copy()}>
														Copy link
													</PrimaryButton>
												</Grid.Column>
											</Grid.Container>
										</Styled.Invitation>
									)}
									<ContentBodyPublicDashboard />
								</Styled.ContentContainer>
							</Styled.DashboardContainer>
						</Styled.StyledColumn>
					</Grid.Container>
				</DLStyled.Content>
			</DLStyled.Wrapper>
		</Provider>
	);
};
export default Dashboard;
