import { AxiosError } from 'axios';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CreateDashboardItemQuery, UpdateDashboardQuery } from 'api-queries';
import { TertiaryButton } from 'components/Button';
import Card from 'components/Card';
import convertDashboardModelToDashboardType from 'components/DataLibrary/ConvertDashboardModelToDashboardType';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import toast from 'services/Toast';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './ChartCard.style';
import { BaseChartCardProps } from './types';

/**
 * This card is displayed both in the dashboard body and in the graph search modal. If chartOnDashboard => remove
 * else add chart.
 */
const ChartCard = ({
	title,
	children,
	isLoading,
	chartOnDashboard,
	chartType,
	chartId,
	somethingWentWrong,
	dropdownMenuItems,
	onRemove,
}: BaseChartCardProps) => {
	const [graphExistsOnDashboard, setGraphExistsOnDashboard] = useState<boolean>(false);
	const [graphAddedText, setGraphAddedText] = useState<boolean>(false);

	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);

	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const removeItemHandler = () => {
		if (onRemove) {
			onRemove();
		}

		const newChartItems = dashboard.items.filter((item) => item.id !== chartId);

		const qb = RequestQueryBuilder.create<UpdateDashboardQuery>(['createPaidMediaPresignUrl', 'createDashboardUser', 'createPaidMedia'])
			.withInclude('users')
			.withInclude('dashboardUsers')
			.withInclude('paidMedia');
		manager
			.update(dashboard.id, { items: newChartItems }, qb)
			.then((result) => {
				const { dashboard } = convertDashboardModelToDashboardType(manager.getRepository(), result);
				dispatch(setDashboard(dashboard));
			})
			.catch((err: AxiosError) => {
				toast.error(getErrorMessageOnPost('removing the graph'));
				console.error(err.message);
			})
			.finally(() => {
				toast.success(`Graph removed from dashboard`);
			});
	};

	const addToDashboardHandler = () => {
		setGraphAddedText(true);
		setTimeout(() => {
			setGraphAddedText(false);
		}, 1000);

		const qb = RequestQueryBuilder.create<CreateDashboardItemQuery>(['createPaidMediaPresignUrl', 'createDashboardUser', 'createPaidMedia'])
			.withInclude('users')
			.withInclude('dashboardUsers')
			.withInclude('paidMedia');
		manager
			.addDashboardItem(
				dashboard.id,
				{
					type: chartType,
					size: 'sm',
					metaData: {},
				},
				qb,
			)
			.then((result) => {
				const { dashboard } = convertDashboardModelToDashboardType(manager.getRepository(), result);
				dispatch(setDashboard(dashboard));
				toast.success(`Added to dashboard`);
			})
			.catch((err: AxiosError) => {
				toast.error(getErrorMessageOnPost('adding the graph'));
				console.error(err.message);
			});
	};

	const checkIsPreviewOrInvite = () => {
		if (pathname.includes('invite') || pathname.includes('preview')) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		return () => {
			setGraphExistsOnDashboard(false);
		};
	}, []);

	useEffect(() => {
		const exists = dashboard.items.filter((item) => item.kind === chartType);
		if (exists?.length > 0) {
			setGraphExistsOnDashboard(true);
		}
	}, [dashboard]);

	return (
		<Styled.Card className={classNames({ inList: !chartOnDashboard, existsOnDashboard: graphExistsOnDashboard, somethingWentWrong: somethingWentWrong })}>
			<Styled.Header>
				<Styled.Title>{title}</Styled.Title>
				{!checkIsPreviewOrInvite() && dashboard?.id && (
					<Styled.DropdownContainer>
						<Dropdown icon='options' size='16' keepInDOM>
							<Styled.CustomDropDownMenu>
								<Styled.CustomDropDownMenuItem onClick={addToDashboardHandler}>
									<Icon name='expand' size='20' />
									<Styled.IconMargin>Copy graph</Styled.IconMargin>
								</Styled.CustomDropDownMenuItem>

								{!isNil(dropdownMenuItems) ? dropdownMenuItems : null}

								<Styled.CustomDropDownMenuItem data-testid='remove-graph-option-item' className='danger' onClick={removeItemHandler}>
									<Icon name='trash-bin' size='20' />
									<Styled.IconMargin>Remove</Styled.IconMargin>
								</Styled.CustomDropDownMenuItem>
							</Styled.CustomDropDownMenu>
						</Dropdown>
					</Styled.DropdownContainer>
				)}
			</Styled.Header>
			<Card.Body>
				<Styled.Container>
					{isLoading ? (
						<Styled.InnerCenteredWrapper>
							<LoadingSpinner size='md' />
						</Styled.InnerCenteredWrapper>
					) : (
						children
					)}
				</Styled.Container>
			</Card.Body>
			<Styled.ButtonContainer>
				{chartOnDashboard
					? null
					: dashboard?.id && (
							<TertiaryButton success={graphAddedText} onClick={addToDashboardHandler}>
								Add
							</TertiaryButton>
						)}
			</Styled.ButtonContainer>
		</Styled.Card>
	);
};

export default ChartCard;
