import { useState, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { CampaignModel } from 'api-models';
import { ListCampaignsQuery } from 'api-queries';
import Campaigns from 'components/InfluencerDashboard/Components/Campaigns/Campaigns';
import NoValueState from 'components/InfluencerDashboard/Components/NoValueState/NoValueState';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import CampaignManager from 'services/ApiManager/Campaign.manager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Styled from './CampaignSection.style';

/**
 * @returns {JSX.Element}
 */
const CampaignSection = (): JSX.Element => {
	const queryBuilder = useMemo(() => {
		return RequestQueryBuilder.create<ListCampaignsQuery>(['smallCoverPhoto'])
			.withInclude('brand')
			.withInclude('assignments.reviews')
			.withInclude('commissions')
			.withInclude('campaignInstagramOwnerAssignments.assignment')
			.withInclude('campaignInstagramOwners.campaignInstagramOwnerAssignments.assignment')
			.withInclude('campaignInstagramOwners.campaignInstagramOwnerCommission')
			.withInclude('campaignInstagramOwners.influencer');
	}, []);

	const manager = useInjection<CampaignManager>(CampaignManager);
	const { repository, isLoading } = manager.listCampaigns(queryBuilder);

	const [displaySkeletonLoader, setDisplaySkeletonLoader] = useState<boolean>(false);
	const user = useAppSelector((state) => state.user);

	const campaigns = repository.findAll<CampaignModel>('campaign') ?? [];

	const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>(user.influencer.id);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout | null = null;
		if (user.influencer.id !== selectedInfluencerId) {
			setDisplaySkeletonLoader(true);
			timeoutId = setTimeout(() => {
				setDisplaySkeletonLoader(false);
			}, 2000);
			setSelectedInfluencerId(user.influencer.id);
		}
		return () => {
			if (timeoutId) clearTimeout(timeoutId);
		};
	}, [user.influencer.id, selectedInfluencerId]);

	return (
		<Styled.Wrapper>
			<Styled.HeadingWrapper>
				<div>{displaySkeletonLoader || isLoading ? <Skeleton width='180px' height='30px' borderRadius='4px' /> : 'Active campaigns'}</div>
			</Styled.HeadingWrapper>
			<Styled.CampaignsWrapper>
				{campaigns.length > 0 && !isLoading ? (
					<Campaigns displaySkeletonLoader={displaySkeletonLoader || isLoading} campaigns={campaigns} repository={repository} />
				) : (
					<NoValueState type='no-campaign' />
				)}
			</Styled.CampaignsWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignSection;
