import { Formik } from 'formik';
import { Store } from 'json-api-models';
import { uniqueId, isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import ApproveMessage from 'components/ContentManagement/Components/ApprovedMessage';
import { selectedTabType } from 'components/ContentManagement/Components/Comments/types';
import FileUploadProgress from 'components/ContentManagement/Components/FileUploadProgress';
import ManagerTopbar from 'components/ContentManagement/Components/Topbar/ManagerHeader';
import TrackingLink from 'components/ContentManagement/Components/TrackingLink';
import UploadMedia from 'components/ContentManagement/Components/Views/UploadMedia';
import { customValidator, reorderArray } from 'components/ContentManagement/Utils';
import { useCampaignInstagramOwnerAssignments } from 'components/ContentManagement/hooks';
import { PostMedia, CIOAType, MediaStatus, ReviewType } from 'components/ContentManagement/types';
import { InputText } from 'components/Form/FormikElements';
import Field from 'components/Forms/Field';
import {
	CREATE_INSTAGRAM_POST,
	EDIT,
	CREATE_ASSIGNMENT_REVIEW,
	DELETE,
	CLIENT_APPROVE,
	CREATE_COMMENT,
	APPROVE_REVIEW,
	REQUEST_CHANGE,
	CREATE_INTERNAL_COMMENT,
	REQUEST_REVIEW,
	EDIT_URL,
} from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost, getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import usePermissions from 'hooks/usePermissions';
import useUnsavedChangesWarning from 'hooks/useUnsavedChangesWarning';
import { useAppSelector, useAppDispatch } from 'hooks/useUserAppSelector';
import { setIsProgressVisible } from 'reducers/ContentManagementReducers/ContentManagementSlice';
import { ICollabsResponse, StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import {
	AssignmentReviewMediaPresignUrlType,
	AssignmentReviewMediaType,
	AssignmentStatus,
	isAssignmentApproved,
	isContentApproved,
	isStatsUploaded,
	UserUploadStatus,
} from 'shared/Types/Assignment';
import { CampaignType } from 'shared/Types/Campaign/Campaign.type';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';

import Styled from './Post.style';
import PostItem from './PostItem';
import AddItem from './components/AddItem';
import CommentsSection from './components/CommentSection';
import Modals from './components/Modals';
import { useCommentsHandler } from './hooks/useCommentsHandler';

type PostProps = {
	id: string;
	selectedCampaign: Pick<CampaignType, 'hashtags' | 'mentions'>;
	selectedCIOA: CIOAType;
	assignmentType: ASSIGNMENT_TYPE;
	review: ReviewType;
	CIOArefresh: () => Promise<void>;
	campaignRefresh: () => void;
	goToStats: () => void;
};

/**
 * Post view
 * Post view to upload, and manage posts and reels.
 * Note: useSWR do the polling.
 * @param {PostProps}
 * @returns {JSX.Element}
 */
const Post = ({ id, selectedCIOA, review, CIOArefresh, goToStats, selectedCampaign, campaignRefresh, assignmentType }: PostProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const ap = useFeaturePermissions({ ...selectedCIOA.links });
	const { userCan } = useFeaturePermissions({ ...review?.links });

	const {
		postReview,
		postStatus,
		patchReview,
		postPresignUrl,
		putMediaInBucket,
		patchMedia,
		setAsUploadedMediaItem,
		deleteMedia,
		postApproveAsClient,
		progress,
		postReviewRequest,
		editTrackinglink,
	} = useCampaignInstagramOwnerAssignments();

	const CAN_CREATE_POST = userCan(CREATE_INSTAGRAM_POST);
	const CAN_APPROVE = userCan(APPROVE_REVIEW);
	const CAN_REQUEST_CHANGES = userCan(REQUEST_CHANGE);
	const CAN_REQUEST_REVIEW = userCan(REQUEST_REVIEW);

	const CAN_EDIT_POST = userCan(EDIT);
	const CAN_COMMENT = userCan(CREATE_COMMENT);
	const CAN_COMMENT_INTERNAL = userCan(CREATE_INTERNAL_COMMENT);
	const CAN_CREATE_ASSIGNMENT_REVIEW = ap.userCan(CREATE_ASSIGNMENT_REVIEW);
	const CAN_CLIENT_APPROVE = userCan(CLIENT_APPROVE);
	const CAN_EDIT_URL = userCan(EDIT_URL);

	const hasBeenAssignmentReviewCreated = isNil(review);

	const [medias, setMedias] = useState<PostMedia[]>([]);
	const [isUploadingContent, setIsUploadingContent] = useState<boolean>(false);
	const [isClientApproveLoading, setIsClientApproveLoading] = useState<boolean>(false);

	const [isSendForReviewModalOpen, setIsSendForReviewModalOpen] = useState<boolean>(false);
	const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
	const [isRequestChangeModalOpen, setIsRequestChangeModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [selectedMediaId, setSelectedMediaId] = useState<string>('');

	const [isPosting, setIsPosting] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isUploadingFiles, setIsUploadingFiles] = useState(false);
	const [completed, setIsCompleted] = useState(false);

	const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);
	const [trackingUrl, setTrackingUrl] = useState<string | null>(null);

	const IN_REVIEW = review !== null;

	const user = useAppSelector((state) => state.user);
	const contentMenagement = useAppSelector((state) => state.contentManagement);

	useUnsavedChangesWarning(isUploadingFiles);

	const { comments, internalComments, postCommentHandler, activeCommentTab, setActiveCommentTab } = useCommentsHandler({
		id: id,
		reviewLinks: {
			comments: review?.links?.comments,
			internalComments: review?.links?.internalComments,
		},
		getComments: useCampaignInstagramOwnerAssignments().getComments,
		postComment: useCampaignInstagramOwnerAssignments().postComment,
		canComment: CAN_COMMENT,
		canCommentInternal: CAN_COMMENT_INTERNAL,
	});

	// Caption preparation
	const tags = selectedCIOA.assignment.hashtags.length > 0 ? selectedCIOA.assignment.hashtags.map((hashtag) => `#${hashtag}`) : selectedCampaign.hashtags;
	const mentions = selectedCIOA.assignment.mentions.length > 0 ? selectedCIOA.assignment.mentions.map((mention) => `@${mention}`) : selectedCampaign.mentions;

	const { isInfluencer } = usePermissions();
	const showTrackingLink = (review && !isInfluencer()) || (isInfluencer() && review?.trackingUrl);

	// Upload media post to presign, and put a file
	const uploadMediaToAssignment = async (assignmentReview: ReviewType, mediaItems: PostMedia[]) => {
		setIsEditing(false);
		let result = false;
		try {
			const promises = mediaItems.map((media) =>
				postPresignUrl(assignmentReview.links.createPresignUrl, {
					filename: media.file!.name,
					sort: medias.findIndex((x) => x.id === media.id),
					productUrl: null,
				}).then(async (res: ICollabsResponse | undefined) => {
					if (!res) {
						return;
					}

					const { setAsUploaded } = res.data.links;
					const response = await putMediaInBucket(res.data.attributes.url, media.file!);
					const element = document.getElementById('completed-assignment');
					if (element) {
						element.scrollIntoView({
							block: 'start',
							behavior: 'smooth',
						});
					}

					if (response && response.status === StatusCode.OK && setAsUploaded) {
						try {
							await setAsUploadedMediaItem(setAsUploaded);
						} catch (e) {
							console.error(e);
							toast.error(getErrorMessageOnPost('uploading the images'));
						}
					}
				}),
			);
			await Promise.all(promises);
			result = true;
			setIsUploadingContent(false);
		} catch (err) {
			console.error(err);
			result = false;
			dispatch(setIsProgressVisible(false));
		}
		return result;
	};

	// Post review
	const sendForReviewHandler = (medias: PostMedia[], data: { text: string; url?: string }) => {
		if (medias.length === 0) {
			toast.error('You need to upload at least one image');
			return false;
		}
		setIsUploadingContent(true);

		postReview(selectedCIOA.links[CREATE_ASSIGNMENT_REVIEW], { ...data, url: data.url?.trim() || null })
			.then((response) => {
				const reviewModel = new Store();
				reviewModel.sync(response.data);
				const assignmentReview = reviewModel.findAll('assignmentReview')[0];
				return { assignmentReview: assignmentReview as unknown as ReviewType };
			})
			.then(async ({ assignmentReview }) => {
				await uploadMediaToAssignment(assignmentReview, medias.length > 0 ? medias : []);
			})
			.catch((error) => {
				setIsUploadingContent(false);
				toast.error(getSomethingWentWrongMessage());
				console.error(error);
			});
		return true;
	};

	// Patch review
	const patchReviewHandler = async (medias: PostMedia[], data: { text: string; url?: string }) => {
		let result = false;

		await patchReview(review.links[EDIT], { ...data, url: data.url?.trim() || null }).then(async () => {
			const orderChangeImages = medias!.filter((image) => image.status !== MediaStatus.PENDING);
			const orderChangePresignImages = medias!.filter((image) => image.status === MediaStatus.PENDING);
			const newImages = medias!.filter((image) => image.status === MediaStatus.DRAFT);

			if (newImages.length > 0) {
				await uploadMediaToAssignment(review, newImages);
			}

			await Promise.all([
				orderChangeImages.map((media) => {
					return patchMedia({ sort: medias.findIndex((x) => x.id === media.id) }, media?.links?.edit || '');
				}),
			])
				.then(async () => {
					if (CAN_REQUEST_REVIEW) {
						await postReviewRequest(review.links[REQUEST_REVIEW]);
					}
					result = true;
				})
				.catch((e) => {
					console.error(e);
					toast.error(getErrorMessageOnPost('updating your media'));
				});

			await Promise.all([
				orderChangePresignImages.map((media) => {
					if (media.links) {
						return patchMedia(
							{
								sort: medias.findIndex((x) => x.id === media.id),
							},
							media?.links?.edit || '',
						);
					}
				}),
			])
				.then(() => {
					result = true;
				})
				.catch((e) => {
					console.error(e);
					toast.error('Something went wrong when updating your post. Reload the page and try again');
				});
		});

		return result;
	};

	// Post Status
	const postStatusHandler = (type: 'approve' | 'requestChange' | 'reject') => {
		if (!CAN_APPROVE && !review) {
			return;
		}
		setIsPosting(true);
		postStatus(review.links[type])
			.then(() => {
				return Promise.all([campaignRefresh(), CIOArefresh()]);
			})
			.then(() => {
				if (type === APPROVE_REVIEW) {
					toast.success('Approved for posting');
				} else if (type === REQUEST_CHANGE) {
					toast.success('Request sent');
				} else {
					toast.success('Content declined');
				}

				setIsApproveModalOpen(false);
				setIsRequestChangeModalOpen(false);
			})
			.catch((e) => {
				setIsPosting(false);
				toast.error('There was an error when updating the content. Please try again later.');
				console.error(e);
			})
			.finally(() => {
				setIsPosting(false);
			});
	};

	// Get medias and store them in the state
	const getMedia = () => {
		const uploadedMedias: Array<PostMedia> = review?.medias?.map((media: AssignmentReviewMediaType) => {
			return {
				id: media.id,
				sort: media.sort,
				fileUrl: media.links.screenshot,
				originalFilename: media.originalFilename,
				links: media.links,
				type: media.vimeoPlaybackUrl || media.vimeoId ? 'video' : 'image',
				vimeoId: media.vimeoId,
				vimeoPlaybackUrl: media.vimeoPlaybackUrl,
				status: MediaStatus.UPLOADED,
				uploadStatus: media.uploadStatus,
			} as PostMedia;
		});

		const pendingMedias: Array<PostMedia> = review?.pendingUploads?.map((media: AssignmentReviewMediaPresignUrlType) => {
			return {
				id: media.id,
				sort: media.sort !== null ? media.sort : 0,
				fileUrl: media.links.screenshot,
				originalFilename: media.filename,
				links: media.links,
				type: media.type?.includes('image') ? 'image' : media.type?.includes('video') ? 'video' : 'unknown',
				vimeoId: '',
				vimeoPlaybackUrl: '',
				status: MediaStatus.PENDING,
				uploadStatus: UserUploadStatus.INIT,
			} as PostMedia;
		});

		if (uploadedMedias) {
			const mediaArr = uploadedMedias.concat(pendingMedias);
			setMedias(mediaArr.sort((a: { sort: number }, b: { sort: number }) => a.sort - b.sort));
		}
	};

	// Click event to change order on medias
	const onClickMove = (direction: 'up' | 'down', index: number) => {
		const reorderedArray = reorderArray({ oldIndex: index, newIndex: index + (direction === 'up' ? -1 : 1) }, medias);
		setMedias(reorderedArray);
	};

	// Click event to approve content as a client
	const onClickClientApprove = () => {
		setIsClientApproveLoading(true);
		postApproveAsClient(review.links[CLIENT_APPROVE])
			.then(() => {
				return Promise.all([campaignRefresh(), CIOArefresh()]);
			})
			.then(() => {
				toast.success('Approved for posting');
			})
			.finally(() => setIsClientApproveLoading(false))
			.catch((e) => {
				toast.error('Something went wrong when updating the content. Please try again later.');
				console.error(e);
				setIsClientApproveLoading(false);
			});
	};

	// Click event to delete medias
	const onClickDelete = (mediaId: string) => {
		const targetMedia = medias.find((m) => m.id === mediaId);
		if (!IN_REVIEW || targetMedia?.status === MediaStatus.DRAFT) {
			setMedias(medias.filter((m) => m.id !== mediaId));
		}

		if (targetMedia && targetMedia.status !== MediaStatus.DRAFT) {
			setIsPosting(true);

			deleteMedia(targetMedia.links[DELETE])
				.then(() => {
					setMedias(medias.filter((m) => m.id !== mediaId));
					return Promise.all([campaignRefresh(), CIOArefresh()]).finally(() => {
						toast.success('Item deleted');
					});
				})
				.finally(() => {
					setIsPosting(false);
					setIsDeleteModalOpen(false);
					dispatch(setIsProgressVisible(false));
					setSelectedMediaId('');
				})
				.catch(() => {
					toast.error('Cannot delete your item. Please try again later.');
				});
		}
	};

	// Click event to cancel edit mode
	const cancelEditHandler = () => {
		setMedias((prev) => {
			if (prev !== null) {
				return prev.filter((media) => !media.id.includes('media'));
			} else {
				return prev;
			}
		});
		setIsEditing(false);
	};

	const AddMedias = (files: Array<File>) => {
		if (files.length > 0) {
			const availableFiles = files.filter((file) => {
				const isAvailable = customValidator(file);

				if (isAvailable === null) {
					return file;
				} else {
					toast.error(isAvailable.message);
				}
			});
			setMedias((prevState) => {
				const newMedias = availableFiles.map((file: File, index: number) => {
					return {
						id: uniqueId(),
						sort: index,
						file: file,
						originalFilename: file.name,
						status: MediaStatus.DRAFT,
						uploadStatus: UserUploadStatus.SUCCESS,
						links: {},
					} as PostMedia;
				});
				return [...prevState, ...newMedias];
			});
		}
	};

	// OnChange event for the files
	const onChangeFiles = (e: React.FormEvent<HTMLInputElement>) => {
		const fileList: FileList | null = (e.target as HTMLInputElement).files;
		const filesArray = fileList ? Array.from(fileList) : [];
		AddMedias(filesArray);
	};

	// Click on comment tabs
	const onClickCommentTab = (selectedTab: selectedTabType) => {
		setActiveCommentTab(selectedTab);
	};

	const onGenerateLink = async (value: string) => {
		try {
			setIsGeneratingUrl(true);
			const { data } = await editTrackinglink(review?.links[EDIT_URL], { url: value.trim() || null });
			setTrackingUrl(data.attributes.trackingUrl ?? value);
			toast.success('Generated a tracking link successfully');
		} catch (error) {
			toast.error(getErrorMessageOnPost('generating a tracking link'));
			console.error(error);
		} finally {
			setIsGeneratingUrl(false);
		}
	};

	useEffect(() => {
		getMedia();

		if (userCan(CREATE_INTERNAL_COMMENT) && !userCan(CREATE_COMMENT)) {
			setActiveCommentTab(selectedTabType.BrandManager);
		} else {
			setActiveCommentTab(selectedTabType.Influencer);
		}

		dispatch(setIsProgressVisible(false));

		return () => {
			setMedias([]);
			setIsUploadingContent(false);
			setIsEditing(false);
		};
	}, [id]);

	useEffect(() => {
		if (!isEditing) {
			getMedia();
		}
	}, [review]);

	useEffect(() => {
		const values = Object.values(progress);
		const hasValueGreaterThanZero = values.some((value) => value > 0);
		dispatch(setIsProgressVisible(hasValueGreaterThanZero));
	}, [progress]);

	useEffect(() => {
		if (completed) {
			dispatch(setIsProgressVisible(false));
			toast.success('File uploaded');
		}
	}, [completed]);

	return (
		<Styled.Wrapper data-testid='assignment-form'>
			<Formik
				enableReinitialize
				validateOnChange={false}
				initialValues={{
					text: review?.text,
					url: review?.url || '',
				}}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						if (!isEditing) {
							try {
								sendForReviewHandler(medias, values);
							} catch (e) {
								toast.error(getSomethingWentWrongMessage());
								console.error(e);
							} finally {
								setSubmitting(false);
								Promise.all([campaignRefresh(), CIOArefresh()]);
							}
						} else {
							const element = document.getElementById('heading');
							if (element) {
								element.scrollIntoView({
									block: 'start',
									behavior: 'smooth',
								});
							}
							await patchReviewHandler(medias, values)
								.then((result) => {
									if (result) {
										return Promise.all([campaignRefresh(), CIOArefresh()]).then(() => {
											toast.success('Your assignment is updated');
											setSubmitting(false);
											setIsEditing(false);
										});
									}
								})
								.catch((e) => {
									if (e.response.data && e.response.data.errors) {
										const errors: Array<{ status: number; source: { message: string } }> = e.response.data.errors;
										if (errors.length > 0) {
											const validationErrors = errors.filter((x) => +x.status === StatusCode.BAD_REQUEST);
											const serverErrors = errors.filter((x) => +x.status >= StatusCode.INTERNAL_SERVER_ERROR);

											validationErrors.forEach((item) => {
												const { status, source } = item;
												if (+status === StatusCode.BAD_REQUEST) {
													toast.error(source.message);
												}
											});

											if (serverErrors.length > 0) {
												toast.error(getSomethingWentWrongMessage());
											}
										}
									}
								});
						}
					} catch (error) {
						console.error(error);
						setSubmitting(false);
					}
				}}
				validationSchema={Yup.object({
					text: Yup.string()
						.required('Please add a caption')
						.test('', function (val: string | undefined) {
							const hashtagsAndMentions: string[] = [];
							const missingWords: string[] = [];
							selectedCIOA.assignment.hashtags.forEach((hashtag: string) => hashtagsAndMentions.push('#' + hashtag));
							selectedCIOA.assignment.mentions.forEach((mention: string) => hashtagsAndMentions.push('@' + mention));

							if (val) {
								for (const word of hashtagsAndMentions) {
									if (!val.includes(word)) {
										missingWords.push(word);
									}
								}
							}

							return missingWords.length === 0
								? true
								: this.createError({
										message: `Please use ${missingWords.join(', ')}`,
									});
						}),
					url: Yup.string().trim().url('Link is not valid'),
				})}
				validateOnBlur={false}
			>
				{({ values, handleSubmit, isSubmitting, errors, resetForm, setFieldValue, validateForm }) => (
					<>
						<ManagerTopbar
							medias={medias}
							heading={selectedCIOA.assignment.name}
							status={{
								reviewStatus: review?.newStatus,
								newFancyStatus: selectedCIOA.newFancyStatus,
							}}
							permissions={{
								canApprove: CAN_APPROVE,
								canRequestChanges: CAN_REQUEST_CHANGES,
								canEdit: !isAssignmentApproved(selectedCIOA.newFancyStatus) && CAN_EDIT_POST,
								canComment: CAN_COMMENT,
								canCreateReview: CAN_CREATE_ASSIGNMENT_REVIEW,
								canClientApprove: CAN_CLIENT_APPROVE,
							}}
							isEditing={isEditing}
							loading={{
								isClientApproveLoading: isClientApproveLoading,
								isSaving: isSubmitting,
								isUploadingFiles: isUploadingFiles,
							}}
							handlers={{
								onClickOpenSendForReviewModal: () => {
									validateForm().then((res) => {
										if (isEmpty(res)) {
											setIsSendForReviewModalOpen(true);
										}
									});
								},
								onClickApprove: () => setIsApproveModalOpen(true),
								onClickClientApprove: onClickClientApprove,
								onClickEdit: () => setIsEditing(!isEditing),
								onClickCancel: () => {
									cancelEditHandler();
									resetForm();
								},
								onClickUploadStats: goToStats,
								onClickSaveChanges: handleSubmit,
								onClickRequestChanges: () => setIsRequestChangeModalOpen(true),
							}}
							errors={errors}
							files={medias.length}
							CIOAssignment={selectedCIOA}
							latestReview={review}
						/>

						{(isContentApproved(selectedCIOA.newFancyStatus) || isStatsUploaded(selectedCIOA.newFancyStatus)) && (
							<ApproveMessage contentType={ASSIGNMENT_TYPE.INSTAGRAM_POST} campaignCode={selectedCIOA.campaignCode} />
						)}

						{!isEditing && contentMenagement.isProgressVisible && (
							<FileUploadProgress
								isCompleted={completed}
								setIsCompleted={setIsCompleted}
								getIsUploadingFiles={(isLoading) => {
									setIsUploadingFiles(isLoading);
									if (!isLoading) {
										return Promise.all([campaignRefresh(), CIOArefresh()]);
									}
								}}
								files={progress}
							/>
						)}

						{selectedCIOA.newFancyStatus !== AssignmentStatus.DECLINED && (
							<>
								{isEmpty(medias) && hasBeenAssignmentReviewCreated && !isEditing && <UploadMedia onChange={(files) => AddMedias(files)} />}
								<Styled.MediaListItems>
									{medias &&
										medias.map((item, index) => {
											return (
												<PostItem
													key={index}
													index={index}
													media={item}
													onClickDelete={(item: Pick<PostMedia, 'id' | 'status'>) => {
														setSelectedMediaId(item.id);

														if (item.status !== MediaStatus.DRAFT) {
															setIsDeleteModalOpen(!isDeleteModalOpen);
														} else {
															onClickDelete(item.id);
														}
													}}
													canDelete={Boolean(item.links[DELETE]) || item.status === MediaStatus.DRAFT}
													canEdit={CAN_CREATE_POST || CAN_EDIT_POST}
													isUploading={isUploadingContent}
													isEditing={isEditing || !IN_REVIEW}
													isMoveEnabled={(review !== null && isEditing && medias.length > 1) || (review === null && medias.length > 1)}
													onClickMove={(direction: string) => {
														if (direction === 'up' && index !== 0) {
															onClickMove(direction, index);
														}
														if (direction === 'down' && index !== medias.length - 1) {
															onClickMove(direction, index);
														}
													}}
												/>
											);
										})}
									{(((CAN_CREATE_ASSIGNMENT_REVIEW || isEditing) && !isEmpty(medias)) || (isEmpty(medias) && !hasBeenAssignmentReviewCreated && isEditing)) &&
										!isContentApproved(selectedCIOA.newFancyStatus) && <AddItem onChangeFiles={onChangeFiles} />}
								</Styled.MediaListItems>

								<Styled.Form>
									<Styled.InputGroup>
										<Styled.TitleWrapper>
											<div>Post caption</div>
										</Styled.TitleWrapper>
										<Field label='Caption' disabled={!isEditing && !CAN_CREATE_ASSIGNMENT_REVIEW}>
											<InputText
												placeholder='Write your caption here'
												size='md'
												id='text'
												name='text'
												rows={8}
												as='textarea'
												disabled={!isEditing && !CAN_CREATE_ASSIGNMENT_REVIEW}
												required
											/>
										</Field>
										<Styled.TagWrapper>
											{tags
												?.filter((tag) => !values.text?.includes(`${tag}`))
												.map((tag, index) => {
													return (
														<Styled.Tag key={index} onClick={() => setFieldValue(`text`, `${values.text ?? ''} ${tag}`)}>
															{tag}
														</Styled.Tag>
													);
												})}
											{mentions
												?.filter((mention) => !values.text?.includes(`${mention}`))
												.map((mention, index) => {
													return (
														<Styled.Tag key={index} onClick={() => setFieldValue(`text`, `${values.text ?? ''} ${mention}`)}>
															{mention}
														</Styled.Tag>
													);
												})}
										</Styled.TagWrapper>
									</Styled.InputGroup>
									{showTrackingLink && (
										<Styled.InputGroup>
											<Styled.ProductLinkBlockWrapper>
												<TrackingLink
													canEdit={!isAssignmentApproved(selectedCIOA.newFancyStatus) && CAN_EDIT_URL}
													trackingUrl={trackingUrl ?? review?.trackingUrl}
													originalUrl={values.url}
													className='frame__tacking-link'
													onClick={(value) => onGenerateLink(value)}
													isLoading={isGeneratingUrl}
													assignmentType={assignmentType}
												/>
											</Styled.ProductLinkBlockWrapper>
										</Styled.InputGroup>
									)}
								</Styled.Form>
							</>
						)}

						<Modals
							state={{
								isSendForReviewModalOpen,
								isApproveModalOpen,
								isRequestChangeModalOpen,
								isDeleteModalOpen,
								selectedMediaId,
								isPosting,
								isUploadingContent,
							}}
							handlers={{
								onApprove: () => postStatusHandler(APPROVE_REVIEW),
								onRequestChanges: () => postStatusHandler(REQUEST_CHANGE),
								onSendForReview: async () => {
									try {
										setIsUploadingContent(true);
										// eslint-disable-next-line @typescript-eslint/await-thenable
										await handleSubmit(); // Submit form logic and wait until finished
									} finally {
										setIsSendForReviewModalOpen(false); // Close modal after upload completes
										const element = document.getElementById('heading');
										if (element) {
											element.scrollIntoView({
												block: 'start',
												behavior: 'smooth',
											});
										}
									}
								},
								onDeleteMedia: () => onClickDelete(selectedMediaId),
								setIsApproveModalOpen: () => setIsApproveModalOpen(!isApproveModalOpen),
								setIsRequestChangeModalOpen: () => setIsRequestChangeModalOpen(!isRequestChangeModalOpen),
								setIsSendForReviewModalOpen: () => setIsSendForReviewModalOpen(!isSendForReviewModalOpen),
								setIsDeleteModalOpen: () => setIsDeleteModalOpen(!isDeleteModalOpen),
							}}
						/>
					</>
				)}
			</Formik>
			{(CAN_COMMENT || CAN_COMMENT_INTERNAL) && (
				<CommentsSection
					user={user}
					canUseTabs={CAN_COMMENT && CAN_COMMENT_INTERNAL}
					comments={comments}
					internalComments={internalComments}
					onClickTab={onClickCommentTab}
					activeCommentTab={activeCommentTab}
					onSend={postCommentHandler}
					reviewStatus={review?.newStatus || ''}
					isNotSharedWithClient={!review?.approvedByClientAt}
					isReviewActive={!!review}
				/>
			)}
		</Styled.Wrapper>
	);
};

export default Post;
