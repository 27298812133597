import { useEffect, useState, useMemo, useCallback, useRef } from 'react';

import { PrimaryButton } from 'components/Button';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { AssignmentStatus } from 'shared/Types/Assignment';
import { breakpoints } from 'styles/variables/media-queries';

import Styled from './Topbar.style';
import { ReviewButtonProps, ReviewSectionProps } from './types';

const parsePx = (px: string) => parseInt(px.replace('px', ''), 10);

const ReviewButton = ({ label, onClick, isLoading, disabled, dataTestId, className }: ReviewButtonProps) => (
	<PrimaryButton onClick={onClick} disabled={disabled} data-testid={dataTestId} type='submit' className={className} isLoading={isLoading}>
		{label}
	</PrimaryButton>
);

const ReviewSection = ({
	errors,
	onClickRequestChanges,
	onClickApprove,
	onClickClientApprove,
	isEditing,
	canRequestChanges,
	canApprove,
	canClientApprove,
	isClientApproveLoading,
	title,
	status,
}: ReviewSectionProps): JSX.Element => {
	const { width } = useWindowDimensions();
	const isMobileView = width <= parsePx(breakpoints.lg);
	const [displayAllButtons, setDisplayAllButtons] = useState(false);
	const errorRefs = useRef<Record<string, HTMLElement | null>>({});

	const displayErrors = useMemo(
		() =>
			errors
				? Object.entries(errors).map(([key, value], i) => {
						if (typeof value === 'string' || (Array.isArray(value) && value.length > 0)) {
							return (
								<Styled.ErrorMessage
									key={i}
									onClick={() => {
										const element = errorRefs.current[key];
										element?.focus();
										element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
									}}
								>
									{typeof value === 'string' ? value : value[0]?.text ?? 'Missing required fields'}
								</Styled.ErrorMessage>
							);
						}
						return null;
					})
				: null,
		[errors],
	);

	useEffect(() => {
		if (errors && Object.keys(errors).length > 0) {
			const firstErrorKey = Object.keys(errors)[0];
			const element = errorRefs.current[firstErrorKey];
			if (element) {
				element.focus();
				element.scrollIntoView({ block: 'start', behavior: 'smooth' });
			}
		}
	}, [errors]);

	const handleRequestChanges = useCallback(() => onClickRequestChanges(), [onClickRequestChanges]);
	const handleApprove = useCallback(() => onClickApprove(), [onClickApprove]);
	const handleClientApprove = useCallback(() => onClickClientApprove(), [onClickClientApprove]);

	const renderButtons = () => (
		<>
			{canRequestChanges && !isEditing && (
				<ReviewButton className='white' label='Request changes' onClick={handleRequestChanges} dataTestId='cm-request-changes' />
			)}
			{canApprove && !isEditing && <ReviewButton label='Approve for posting' onClick={handleApprove} dataTestId='cm-approve' />}
			{canClientApprove && (
				<ReviewButton label='Approve for posting' onClick={handleClientApprove} isLoading={isClientApproveLoading} dataTestId='cm-client-approve' />
			)}
		</>
	);

	const renderMobileView = () => (
		<Styled.SubmitSection>
			<Styled.DFlexW100 className='min-height'>
				<Styled.DFlexColumn className='w-100'>
					<Styled.AssignmentName>{title}</Styled.AssignmentName>
					<Styled.FileText>{displayErrors}</Styled.FileText>
					<Styled.InfoText>{status === AssignmentStatus.APPROVED ? status : displayAllButtons ? 'Add your review' : 'Content review'}</Styled.InfoText>
				</Styled.DFlexColumn>
				{!displayAllButtons && (
					<PrimaryButton onClick={() => setDisplayAllButtons(true)} data-testid='cm-show-buttons' role='button'>
						Review
					</PrimaryButton>
				)}
			</Styled.DFlexW100>
			{displayAllButtons && (
				<>
					<hr />
					<Styled.ButtonContainer>
						{renderButtons()}
						<Styled.CloseButton onClick={() => setDisplayAllButtons(false)}>Close</Styled.CloseButton>
					</Styled.ButtonContainer>
				</>
			)}
		</Styled.SubmitSection>
	);

	const renderDesktopView = () => (
		<Styled.DFlexColumn>
			<div className='buttons-wrapper'>{renderButtons()}</div>
		</Styled.DFlexColumn>
	);

	return isMobileView ? renderMobileView() : renderDesktopView();
};

export default ReviewSection;
