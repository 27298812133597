import classNames from 'classnames';
import { useRef } from 'react';

import { PrimaryButton, TertiaryButton } from 'components/Button';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';

import Styled from './ConfirmModal.style';
import { ConfirmModalProps } from './types';

/**
 * Confirm modal component
 * @returns {JSX.Element}
 */
const ConfirmModal = ({
	iconReplacement,
	IconBackgroundColor,
	icon,
	buttonText,
	isModalOpen,
	toggleModal,
	title,
	isFetching,
	action,
	children,
	confirmButtonAsDanger,
	isOnlyCancel,
	cancelButtonText,
	iconSize,
	disabled,
	testId,
}: ConfirmModalProps): JSX.Element => {
	const cancelRef = useRef<HTMLButtonElement>(null);

	const onShown = () => {
		setTimeout(() => {
			cancelRef.current && cancelRef.current.focus();
		}, 0);
	};

	return (
		<Styled.CustomModal
			dataTestId={testId}
			size='xs'
			open={isModalOpen}
			handleClose={() => {
				!isFetching && toggleModal();
			}}
			onShown={() => onShown()}
		>
			<Styled.ModalContainer>
				<Styled.Title>
					{iconReplacement
						? iconReplacement
						: icon && (
								<Styled.ModalIconContainer backgroundColor={IconBackgroundColor}>
									<Icon name={icon} size={iconSize || '20'} />
								</Styled.ModalIconContainer>
							)}
					<Heading as='h5'>{title}</Heading>
				</Styled.Title>
				<Styled.ModalChildren>{children}</Styled.ModalChildren>
				<Styled.ModalButtonsContainer className={classNames({ center: isOnlyCancel })}>
					<TertiaryButton data-testid='modal-cancel-button' btnRef={cancelRef} className='cancel' onClick={() => toggleModal()} disabled={isFetching}>
						<div>{cancelButtonText ?? 'Cancel'}</div>
					</TertiaryButton>
					{!isOnlyCancel && (
						<PrimaryButton
							isLoading={isFetching}
							type='button'
							onClick={action}
							className={classNames({ isDangerButton: confirmButtonAsDanger })}
							disabled={isFetching || disabled}
							data-testid='custom-modal-confirm-button'
						>
							{buttonText}
						</PrimaryButton>
					)}
				</Styled.ModalButtonsContainer>
			</Styled.ModalContainer>
		</Styled.CustomModal>
	);
};

export default ConfirmModal;
