import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssignmentModel, BrandModel, CampaignInstagramOwnerModel, CampaignModel } from 'api-models';
import Fallback from 'assets/icons/influencer-no-image-fallback.svg';
import { SecondaryButton } from 'components/Button';
import { CampaignInstagramOwnerStatusText } from 'components/ContentManagement/Utils';
import Icon from 'components/Icon';
import Pill from 'components/Pill';
import { pathInfluencerCampaignView } from 'routing/PathLookup';
import { CampaignInstagramOwnerAssignment } from 'shared/Types/Assignment';
import IRepository from 'utils/Repository/IRepository';

import Styled from './CampaignItem.style';

/**
 * @returns {JSX.Element}
 */
const CampaignItem = ({
	campaign,
	repository,
	setModalIsOpen,
}: {
	campaign: CampaignModel;
	repository: IRepository;
	setModalIsOpen: () => void;
}): JSX.Element => {
	const navigate = useNavigate();

	const CIO = useMemo(
		() => repository.findByRelation<CampaignInstagramOwnerModel, CampaignModel>(campaign, 'campaignInstagramOwners')[0] ?? null,
		[campaign, repository],
	);
	const brand = useMemo(
		() => repository.findOneByRelation<BrandModel, CampaignModel>(campaign, 'brand') ?? { attributes: { name: 'Unknown Brand' } },
		[campaign, repository],
	);

	const ownerAssignments = CIO
		? repository.findByRelation<CampaignInstagramOwnerAssignment, CampaignInstagramOwnerModel>(CIO, 'campaignInstagramOwnerAssignments')
		: [];

	const handleOpenModal = (id: string) => {
		setModalIsOpen();
		navigate(`/influencer/campaigns/${campaign.id}/${CIO.id}/assignments/${id}`);
	};

	return (
		<Styled.Wrapper lg={6} md={12} data-testid={`${campaign.id}-campaign-item`}>
			<Styled.InnerWrapper>
				<Styled.CampaignInfoWrapper>
					{campaign.links?.smallCoverPhoto && (
						<Styled.CampaignImage>
							<img src={campaign.links?.smallCoverPhoto ?? Fallback} alt='campaign-cover-image' />
						</Styled.CampaignImage>
					)}
					<Styled.FlexDiv>
						<SecondaryButton
							data-testid='go-to-brief'
							onClick={() => {
								navigate(pathInfluencerCampaignView(campaign.attributes.shortId), {
									state: { previewUrl: CIO?.links?.invitePreview ?? '' },
								});
							}}
						>
							View brief
						</SecondaryButton>
						<Styled.CampaignInfo>
							<div className='title'>{campaign.attributes.name}</div>
							<div className='brand'>{brand?.attributes.name}</div>
						</Styled.CampaignInfo>
					</Styled.FlexDiv>
				</Styled.CampaignInfoWrapper>
				<Styled.Text className='commission-value'>Assignments</Styled.Text>
				<Styled.CampaignInfo>
					{ownerAssignments.map((assignment: CampaignInstagramOwnerAssignment, index: number) => {
						const campaignAssignment = repository.findOneByRelation<AssignmentModel, CampaignInstagramOwnerAssignment>(assignment, 'assignment');
						return (
							<Styled.AssignmentLink data-testid={`assignment-link-${index}`} key={assignment.id} onClick={() => handleOpenModal(assignment.id)}>
								<Styled.AssignmentTitle>
									{campaignAssignment?.attributes.name} <Pill title={CampaignInstagramOwnerStatusText(assignment.attributes.newFancyStatus)} />
								</Styled.AssignmentTitle>{' '}
								<Icon name='chevron-right' />
							</Styled.AssignmentLink>
						);
					})}
				</Styled.CampaignInfo>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignItem;
