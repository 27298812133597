import styled from 'styled-components';

import Icon from 'components/Icon';

const CustomIcon = styled(Icon)``;

const Wrapper = styled.div``;

const ErrorModalInnerWrapper = styled.div`
	line-height: 1.5;

	& > div > strong > span {
		text-transform: capitalize;
	}
`;

const Styled = {
	Wrapper,
	CustomIcon,

	ErrorModalInnerWrapper,
};

export default Styled;
